import React from 'react'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import { Loader, Button, Table, Form, Select, Input, Modal, TextArea, Icon } from "semantic-ui-react"
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import Requests from '../shared/Requests'

import Cookies from 'js-cookie'

import AuthContext from "../context/authContext"


import moment from "moment";

import { toast } from 'react-toastify';

import _ from "lodash";



const optionsStatusAcoes = [
    { key: '1', text: 'TODOS', value: 'TODOS' },
    { key: '2', text: 'CONCLUIDO', value: 'CONCLUIDO' },
    { key: '3', text: 'NAO_INICIADA', value: 'NAO_INICIADA' },
    { key: '4', text: 'CANCELADO', value: 'CANCELADO' },
    { key: '5', text: 'EM_ANDAMENTO', value: 'EM_ANDAMENTO' },
    { key: '6', text: 'ATRASADO', value: 'ATRASADO' },
]




export default class DemoApp extends React.Component {

    static contextType = AuthContext

    constructor(props) {
        super(props);
    }

    state = {
        listaAcoes: [],
        listaMunicipios: [],
        loadingSelectMunicipio: false,
        loading: false,
        header: {
            left: '',
            center: '',
            right: ''
        },
        status: '',
        municipioId: ''


    }




    getAllMunicipios = async () => {

        const { user } = this.context





        if (user.initial) return window.location.href = `/`

        const acessoRoout = user.data.isRoot

        const listaMunicipioIds = user.data.municipioIds

        this.setState({ loadingSelectMunicipio: true })

        const listaMunicipios = []

        try {
            Requests
                .getAllMunicipios()
                .then(response => {

                    const lista = response.data

                    if (acessoRoout) {

                        for (let i = 0; i < lista.length; i++) {
                            const municipio = lista[i];

                            const obj = {
                                key: municipio.municipioId,
                                text: `${municipio.nome}/${municipio.uf}`,
                                value: municipio.municipioId,

                            }


                            listaMunicipios.push(obj)

                        }

                    } else {
                        for (let j = 0; j < listaMunicipioIds.length; j++) {
                            const municipioId = listaMunicipioIds[j];

                            const municipio = _.find(lista, { municipioId: municipioId })

                            if (municipio) {

                                const obj = {
                                    key: municipio.municipioId,
                                    text: `${municipio.nome}/${municipio.uf}`,
                                    value: municipio.municipioId,

                                }


                                listaMunicipios.push(obj)

                            }



                        }
                    }



                    this.setState({ listaMunicipios: listaMunicipios, loadingSelectMunicipio: false })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }




    }


    goBack() {

        this.props.history.push(`/plano`)

    }


    filterStatusAcoes = async (status) => {



        const { municipioId } = this.state

        const data = new Date()

        const mes = moment(data).format('MM')
        const ano = moment(data).format('YYYY')


        if (municipioId === null || municipioId === '') {
            return toast.warning('Selecionar Municipio')

        }

        this.setState({ status: status })




        await this.getAllPlanoAcaoByMunicipio(municipioId, new Date())





    }

    getAllAcoesByMes = async (data = new Date()) => {

        const mes = moment(data).format('MM')
        const ano = moment(data).format('YYYY')

        this.setState({ loading: true })

        const result = await Requests.getAllMunicipios()

        const lista = []

        try {

            Requests
                .getAllPlanoAcoesByMes(mes, ano)
                .then(response => {

                    

                    const listaAcoes = response.data
                    const listaMunicipios = result.data





                    for (let i = 0; i < listaAcoes.length; i++) {
                        const acao = listaAcoes[i];

                        const municipio = _.find(listaMunicipios, { municipioId: acao.municipioId })

                        

                        switch (acao.status) {


                            case 'ATRASADO':

                                 const primeiroNome = acao.nome.split(' ')

                                const objAtrasado =
                                {
                                    title: `${primeiroNome[0]} => ${municipio.nome}/${municipio.uf} ==> ${acao.acaoTipo}`,
                                    backgroundColor: '#FF0000',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),
                                    end: moment(acao.prazoFim).format('YYYY-MM-DD')
                                }

                                lista.push(objAtrasado)

                                break;

                            default:
                                break;
                        }
                    }


                    this.setState({ listaAcoes: lista, loading: false })



                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }



    }


    getAllAcoesByConsultorId = async (data = new Date()) => {

        const { user } = this.context

        const result = await Requests.getAllMunicipios()

        const mes = moment(data).format('MM')
        const ano = moment(data).format('YYYY')

        this.setState({ loading: true })



        const consultorId = user.data.consultorLogado.consultorId


        let lista = []

        try {

            Requests
                .getAllPlanoAcaoByConsultorId(consultorId, ano, mes)
                .then(response => {

                    const listaAcoes = response.data
                    const listaMunicipios = result.data

                    this.setState({ loading: true })



                    for (let i = 0; i < listaAcoes.length; i++) {
                        const acao = listaAcoes[i];

                        const municipio = _.find(listaMunicipios, { municipioId: acao.municipioId })



                        switch (acao.status) {

                            case 'NAO_INICIADA':
                                const objNaoIniciada =
                                {
                                    title: `${municipio.nome}/${municipio.uf} ==> ${acao.acaoTipo}`,
                                    backgroundColor: '#DCDCDC',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),
                                    end: moment(acao.prazoFim).format('YYYY-MM-DD')
                                }

                                lista.push(objNaoIniciada)

                                break;
                            case 'EM_ANDAMENTO':
                                const obj =
                                {
                                    title: `${municipio.nome}/${municipio.uf} ==> ${acao.acaoTipo}`,
                                    backgroundColor: '#FFFF00',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),
                                    end: moment(acao.prazoFim).format('YYYY-MM-DD')
                                }

                                lista.push(obj)

                                break;

                            case 'CONCLUIDO':

                                const objConcluido =
                                {
                                    title: `${municipio.nome}/${municipio.uf} ==> ${acao.acaoTipo}`,
                                    backgroundColor: '#008000',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),
                                    end: moment(acao.prazoFim).format('YYYY-MM-DD')
                                }

                                lista.push(objConcluido)

                                break;

                            case 'ATRASADO':

                                const objAtrasado =
                                {
                                    title: `${municipio.nome}/${municipio.uf} ==> ${acao.acaoTipo}`,
                                    backgroundColor: '#FF0000',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),
                                    end: moment(acao.prazoFim).format('YYYY-MM-DD')
                                }

                                lista.push(objAtrasado)

                                break;


                            case 'CANCELADO':

                                const objCancelada =
                                {
                                    title: `${municipio.nome}/${municipio.uf} ==> ${acao.acaoTipo}`,
                                    backgroundColor: '#778899',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),
                                    end: moment(acao.prazoFim).format('YYYY-MM-DD')
                                }

                                lista.push(objCancelada)

                                break;

                            default:
                                break;
                        }
                    }
                    this.setState({ listaAcoes: lista, loading: false })



                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {

        }

    }

    getAllPlanoAcaoByMunicipio = async (municipioId, data = new Date()) => {

        const { user, setUser } = this.context

        const mes = moment(data).format('MM')
        const ano = moment(data).format('YYYY')

        let listaAcoes = []



        this.setState({ loading: true })



        Requests
            .getAllPlanoAcaoByMunicipio(municipioId, mes, ano)
            .then(response => {

                listaAcoes = response.data

                let lista = []

                const usuarioConsultorLogado = user.data.consultorLogado

                

                const userAuth = usuarioConsultorLogado.admin

                if (this.state.status !== '') {
                    if (this.state.status === 'TODOS') {

                        listaAcoes = response.data

                    } else {
                        const lista = response.data

                        const filtroAcoes = _.filter(lista, { status: this.state.status })

                        listaAcoes = filtroAcoes
                    }

                }

                if (userAuth) {

                    for (let i = 0; i < listaAcoes.length; i++) {
                        const acao = listaAcoes[i];

                        switch (acao.status) {

                            case 'NAO_INICIADA':
                                const objNaoIniciada =
                                {
                                    title: acao.acaoTipo,
                                    backgroundColor: '#DCDCDC',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),
                                    end: moment(acao.prazoFim).format('YYYY-MM-DD')
                                }

                                lista.push(objNaoIniciada)

                                break;
                            case 'EM_ANDAMENTO':
                                const obj =
                                {
                                    title: acao.acaoTipo,
                                    backgroundColor: '#FFFF00',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),
                                    end: moment(acao.prazoFim).format('YYYY-MM-DD')
                                }

                                lista.push(obj)

                                break;

                            case 'CONCLUIDO':

                                const objConcluido =
                                {
                                    title: acao.acaoTipo,
                                    backgroundColor: '#008000',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),
                                    end: moment(acao.prazoFim).format('YYYY-MM-DD')
                                }

                                lista.push(objConcluido)

                                break;

                            case 'ATRASADO':

                                const objAtrasado =
                                {
                                    title: acao.acaoTipo,
                                    backgroundColor: '#FF0000',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),
                                    end: moment(acao.prazoFim).format('YYYY-MM-DD')
                                }

                                lista.push(objAtrasado)

                                break;


                            case 'CANCELADO':

                                const objCancelada =
                                {
                                    title: acao.acaoTipo,
                                    backgroundColor: '#778899',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),
                                    end: moment(acao.prazoFim).format('YYYY-MM-DD')
                                }

                                lista.push(objCancelada)

                                break;

                            default:
                                break;
                        }
                    }
                    this.setState({ listaAcoes: lista, loading: false })

                } else {

                    

                    const consultorId = usuarioConsultorLogado.consultorId
                    const listaAcoesFiltrada = _.filter(listaAcoes, { 'consultorExecucaoId': consultorId })

                    let listaFiltro = []

                    for (let i = 0; i < listaAcoesFiltrada.length; i++) {
                        const acao = listaAcoesFiltrada[i];

                        switch (acao.status) {

                            case 'NAO_INICIADA':
                                const objNaoIniciada =
                                {
                                    title: acao.acaoTipo,
                                    backgroundColor: '#DCDCDC',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),

                                }

                                listaFiltro.push(objNaoIniciada)

                                break;
                            case 'EM_ANDAMENTO':
                                const obj =
                                {
                                    title: acao.acaoTipo,
                                    backgroundColor: '#FFFF00',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),

                                }

                                listaFiltro.push(obj)

                                break;

                            case 'CONCLUIDO':

                                const objConcluido =
                                {
                                    title: acao.acaoTipo,
                                    backgroundColor: '#008000',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),

                                }

                                listaFiltro.push(objConcluido)

                                break;

                            case 'ATRASADO':

                                const objAtrasado =
                                {
                                    title: acao.acaoTipo,
                                    backgroundColor: '#FF0000',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),

                                }

                                listaFiltro.push(objAtrasado)

                                break;


                            case 'CANCELADO':

                                const objCancelada =
                                {
                                    title: acao.acaoTipo,
                                    backgroundColor: '#778899',
                                    textColor: '#000000',
                                    start: moment(acao.prazoInicio).format('YYYY-MM-DD'),

                                }

                                listaFiltro.push(objCancelada)

                                break;

                            default:
                                break;
                        }
                    }
                    this.setState({ listaAcoes: listaFiltro, loading: false })





                }
            })
            .catch(err => {
                console.log(err)

            })

    }


    userLogado = () => {

        const { user } = this.context

        if (user.data === undefined) window.location.href = `/`
    }


    componentDidMount() {

        const { user, setUser } = this.context


        const cookies = Cookies.get('qd-token')

        this.userLogado()

        if (cookies === undefined) window.location.href = `/`

        const usuarioConsultorLogado = user.data.consultorLogado

        this.getAllMunicipios()

        const userAuth = usuarioConsultorLogado.admin

        if (userAuth) {
            this.getAllAcoesByMes()

        } else {
            this.getAllAcoesByConsultorId()

        }





    }


    render() {

        const { listaAcoes, listaMunicipios, loadingSelectMunicipio, loading, municipioId, status } = this.state

        return (
            <div style={{ margin: '20px 20px 20px 20px' }}>
                <div>

                    <Form.Field style={{ margin: '0px 0px 20px 0px' }} basic color='black' control={Button} onClick={() => this.goBack()} >Voltar</Form.Field>

                </div>
                <div>
                    <Form >

                        <Form.Group widths='equal'>
                            <Form.Field
                                control={Select}

                                label='Cidade'
                                options={listaMunicipios}
                                loading={loadingSelectMunicipio}
                                search
                                placeholder='Cidade'
                                value={municipioId}
                                onChange={(event, data) => this.setState({ municipioId: data.value }, () => this.getAllPlanoAcaoByMunicipio(data.value))}
                            />

                            <Form.Field

                                control={Select}
                                label='Status'
                                width={3}
                                options={optionsStatusAcoes}
                                search
                                placeholder='Status'
                                value={status}
                                onChange={(event, data) => this.filterStatusAcoes(data.value)}
                            />

                            <Form.Field label='Minhas Ações' basic color='black' control={Button} onClick={() => this.getAllAcoesByConsultorId()} >
                                <Icon name='sync'></Icon>
                            </Form.Field>


                        </Form.Group>
                    </Form>
                </div>
                <div style={{ width: '100%', display: 'flex' }}>

                    <div style={{ width: '60%' }}>


                    </div>
                    <div style={{ width: '40%' }}>
                        <Table basic>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell textAlign='center' style={{ backgroundColor: '#DCDCDC', height: '25px', width: '150px' }}    >Não Iniciada</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' style={{ backgroundColor: '#FFFF00', height: '25px', width: '150px' }}>Em Andamento</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' style={{ backgroundColor: '#008000', height: '25px', width: '150px' }}>Concluido</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' style={{ backgroundColor: '#FF0000', height: '25px', width: '150px' }}>Atrasado</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' style={{ backgroundColor: '#778899', height: '25px', width: '150px' }}>Cancelado</Table.HeaderCell>


                                </Table.Row>
                            </Table.Header>
                        </Table>
                    </div>
                </div>


                {
                    loading ? <Loader active={loading} size='big'>Loading</Loader> : <div style={{ marginTop: '20px', height: '100%' }}>
                        <FullCalendar
                            height='auto'
                            plugins={[dayGridPlugin]}
                            initialView="dayGridMonth"
                            events={listaAcoes}
                            headerToolbar={{
                                left: 'title',
                                right: ''
                            }}
                            locale='pt-br'
                            timezone='America/Bahia'

                        />
                    </div>
                }



            </div>
        )
    }
}
import numeral from 'numeral'
import _ from 'lodash'

import VMask from 'vanilla-masker'
import moment from 'moment'
import queryString from 'query-string'


export const getQueryStringValue = (name) => {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
export const defineLength = size => value =>
    value && value.length != size ? `Deve conter ${size} caracteres` : undefined

export const moneyLabel = (value) => {
    return numeral(value).format('$0,0.00');
}

export const cpfLabel = (value) => {
    return VMask.toPattern(value, '999.999.999-99')
}

export const cepLabel = value => {
    if (!value) return null
    return VMask.toPattern(value, '99999-999')
}
export const celularLabel = (value) => {
    if (!value) return null;
    value = value.replace(/^55/, '')
    value = removeNoNumeric(value)
    if (value.length == 11) return VMask.toPattern(value, '(99) 9 9999-9999')
    return VMask.toPattern(value, '(99) 9999-9999')
}

export const cnpjLabel = (value) => {
    return VMask.toPattern(value, '99.999.999/9999-99')
}



export const goToOrigin = () => {
    const {
        protocol,
        host
    } = window.location;
    window.location.replace(`${protocol}//${host}`);
}

export const goToIndex = () => {
    const {
        protocol,
        host
    } = window.location;
    window.location.replace(`${protocol}//${host}/#/`);
}

export const requiredValidate = (value = null) => {
    if (value === null || value === "" || value === undefined) return 'Obrigatório'
    if (value.toString().trim() === "") return 'Obrigatório'
    return undefined
}

export const emailValidate = (value = null) => value &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Email inválido' : undefined


    export const validateCpf = (cpf) => {
    if (cpf === null || cpf === undefined || cpf === NaN) return false
    cpf = removeNoNumeric(cpf)
    var numeros, digitos, soma, i, resultado, digitos_iguais;
    digitos_iguais = 1;
    if (cpf.length < 11)
        return "INVALIDO"
    for (i = 0; i < cpf.length - 1; i++)
        if (cpf.charAt(i) != cpf.charAt(i + 1)) {
            digitos_iguais = 0;
            break;
        }
    if (!digitos_iguais) {
        numeros = cpf.substring(0, 9);
        digitos = cpf.substring(9);
        soma = 0;
        for (i = 10; i > 1; i--)
            soma += numeros.charAt(10 - i) * i;
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return "INVALIDO"
        numeros = cpf.substring(0, 10);
        soma = 0;
        for (i = 11; i > 1; i--)
            soma += numeros.charAt(11 - i) * i;
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
            return "INVALIDO"
        return undefined
    } else
        return undefined
}

export const validateCnpj = (cnpj) => {
    if (cnpj === null || cnpj === undefined || cnpj === NaN) return "INVALIDO"
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return "INVALIDO"

    if (cnpj.length != 14)
        return "INVALIDO"

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return "INVALIDO"

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return "INVALIDO"

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return "INVALIDO"

    return undefined
}

export const validateCep = (cep) => {
    cep = removeNoNumeric(cep)
    return /^\d{5}[-]?\d{3}$/.test(cep) ? undefined : "CEP inválido"
}

export const validateEmail = (email) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) ? undefined : "E-mail inválido"

export const validatePhoneBr = (phone) => {
    phone = removeNoNumeric(phone)
    return /(^|\()?\s*(\d{2})\s*(\s|\))*(9?\d{4})(\s|-)?(\d{4})($|\n)/.test(phone) ? undefined : "Número inválido" // obrigatorio ter ddd
}

export const validateTimeSpan = (val) => {
    if (!/^[0-2][0-9]:[0-5][0-9]$/.test(val)) return false
    val = val.toString()

    let hora = parseInt(val.split(':')[0])
    let minuto = parseInt(val.split(':')[1])

    if (hora > 23 || minuto > 59) return "Valor de tempo inválido"
    return undefined
}

export const removeNoNumeric = (val) => {
    if (val === undefined || val === null) return val
    return val.toString().replace(/[^\d]+/g, '')
}

export function bytesToHuman(bytes) {
    const kb = bytes / 1000;
    if (kb < 1000) return kb.toFixed(2) + ' kb';
    const mb = kb / 1000;
    return mb.toFixed(2) + ' mb';
}

export const replaceOrAdd = (array, value, predicate) => {
    var index = _.findIndex(array, predicate);
    if (index != -1) {
        array[index] = value;
    } else {
        array.push(value)
    }

    return array;
}

export const currencyValue = (value) => {
    try {
        return parseFloat(parseFloat(value).toFixed(2))
    } catch (ex) {
        console.log(ex)
        return 0;
    }
}

export const randomCode = (size, {
    onlyLetter
}) => {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const number = "0123456789"
    if (!onlyLetter) possible = `${possible}${number}`


    for (let i = 0; i < size; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

export const toType = (obj) => {
    return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}
export const isObjectId = (string) => {
    try {
        const checkForHexRegExp = /^[a-f\d]{24}$/i
        return checkForHexRegExp.test(string)
    } catch (error) {
        return false;
    }

}

export const fromNow = (date) => {
    if (!date) return null;
    return duration(date, moment())
}

export const duration = (date01, date02) => {
    const diff = moment.preciseDiff(
        moment.isMoment(date01) ? date01 : moment(date01),
        moment.isMoment(date02) ? date02 : moment(date02),
        true
    )

    let retorno = ''
    if (diff.years) {


        retorno = `${retorno} ${diff.years}ano`
        if (diff.months) retorno = `${retorno} ${diff.months}m`

        return retorno;
    }

    if (diff.months) {


        retorno = `${retorno} ${diff.months}m`
        if (diff.days) retorno = `${retorno} ${diff.days}d`

        return retorno;
    }

    if (diff.days) {


        retorno = `${retorno} ${diff.days}d`
        if (diff.hours) retorno = `${retorno} ${diff.hours}hr`

        return retorno;
    }

    if (diff.hours) {


        retorno = `${retorno} ${diff.hours}hr`
        if (diff.minutes) retorno = `${retorno} ${diff.minutes}min`

        return retorno;
    }

    if (diff.minutes) {

        return `${diff.minutes}min`
    }

    return `agr`
}

export const numberLabel = (number, round = 1) => {
    if (number == undefined || number == NaN) return null

    return parseFloat(number).toFixed(round).replace('.', ',')
}

export const decimalRound = (number, round = 2) => {
    if (number == undefined || number == NaN) return null

    return parseFloat(numberLabel(number, round))
}

export const toggleMap = (S, x) => {
    S[x] = 1 - (S[x] | 0);
    return S;
}
export const stringifyQueryString = (obj) => {
    const keys = Object.keys(obj).filter(key => obj[key] != '')
    const retorno = {}
    keys.forEach(key => retorno[key] = obj[key])
    return queryString.stringify(retorno);

}
export const combineValidate = (value, fns) => {
    let resp = undefined
    fns.some(fn => {
        let test = fn(value)
        if (test != undefined) {
            resp = test
            return true
        }
    })
    return resp
}

import React, { Component } from 'react';


import Requests from "../shared/Requests";

import { Loader, Button, Table, Form, Select, Input, TextArea } from "semantic-ui-react";


import Cookies from 'js-cookie'

import { validateCpf, validateCnpj } from "../helpers/FnUtil";

import { mask, unMask } from "remask";
import numeral from "numeral";
import moment from "moment";

import ReactJson from 'react-json-view'



import bancos from "../baseJson/bancos.json";
import tipoDeContas from "../baseJson/tiposDeConta.json";
import { toast } from 'react-toastify';
import _ from "lodash";



const tiposChaves = [
    {
        key: 1,
        text: `CPF`,
        value: `CPF`
    },
    {
        key: 2,
        text: `CNPJ`,
        value: `CNPJ`
    },
    {
        key: 3,
        text: `CELULAR`,
        value: `CELULAR`
    },
    {
        key: 4,
        text: `EMAIL`,
        value: `EMAIL`
    },
    {
        key: 5,
        text: `CHAVE ALEATÓRIA`,
        value: `CHAVE_ALEATÓRIA`
    }
]







class CadastroFornecedor extends Component {

    // static contextType = AuthContext

    constructor(props) {
        super(props);

        this.goBack = this.goBack.bind(this);



    }

    state = {
        municipios: [],
        listaFornecedores: [],
        municipioId: '',
        nome: '',
        cpfCnpj: '',
        banco: '',
        agencia: '',
        conta: '',
        tipoConta: '',
        loadingTable: false,
        loadingSelectMunicipio: false,
        telefone: '',
        listaBancos: [],
        tipoProdutoServico: '',
        pix: '',
        tipoChave: '',
        pagamentoViaPix: true
    }


    getAllBanco = () => {

        const banks = bancos

        let listBanks = []

        for (let i = 0; i < banks.length; i++) {
            const bank = banks[i];

            const obj = {
                key: bank.key,
                text: `${bank.key} - ${bank.text}`,
                value: `${bank.key} - ${bank.text}`
            }

            listBanks.push(obj)

        }

        this.setState({ listaBancos: listBanks })

    }


    getAllMunicipios = async () => {

        const listaMunicipios = []



        try {
            Requests
                .getAllMunicipios()
                .then(response => {

                    const lista = response.data

                    for (let i = 0; i < lista.length; i++) {
                        const municipio = lista[i];
                        const obj = {
                            key: municipio.municipioId,
                            text: `${municipio.nome}/${municipio.uf}`,
                            value: municipio.municipioId,

                        }
                        listaMunicipios.push(obj)

                    }

                    this.setState({ municipios: listaMunicipios })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }
    }



    getAllFornecedor = () => {

        this.setState({ loadingTable: true, loadingSelectMunicipio: true })
        try {

            Requests
                .getAllFornecedor()
                .then(response => {

                    if (response.r) {

                        debugger

                        const listaFornecedor = response.data

                        this.setState({ listaFornecedores: listaFornecedor, loadingTable: false, loadingSelectMunicipio: false })



                    }
                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {

            console.log(error)

        }



    }


    cadastrarFornecedor = async () => {

        const { municipioId, nome, cpfCnpj, banco, agencia, conta, tipoConta, telefone, tipoProdutoServico, pix, tipoChave, pagamentoViaPix } = this.state


        const fornecedor = []


        const obj = {
            municipioId: municipioId,
            nome: nome,
            tipoProdutoServico: tipoProdutoServico,
            cpfCnpj: cpfCnpj,
            banco: banco,
            agencia: agencia,
            conta: conta,
            tipoConta: tipoConta,
            telefone: telefone,
            createdAt: moment(new Date()).format('YYYY-MM-DD hh:mm'),
            tipoChavePix: tipoChave,
            chavePix: pix,
            pagamentoViaPix: pagamentoViaPix

        }

        if (pagamentoViaPix === true) {

            this.setState({
                agencia: '',
                conta: '',
                tipoConta: ''
            })

            if (pix === '') {

                toast.warning('Iinformar chave pix')


            }

        }


        if ((pagamentoViaPix !== true) && (tipoConta === '')) return toast.warning('Informar tipo de conta')

        if (validateCpf(cpfCnpj) === 'INVALIDO' && (validateCnpj(cpfCnpj) === 'INVALIDO')) return toast.warning('CPF Ou CNPJ Inválido.')



        if ((pagamentoViaPix !== true) && (conta.indexOf('-') > -1) === false) return toast.warning('Conta Sem Digito Verificador')


        // const result = await Requests.getAllFornecedor(municipioId)


        // if (result.data.length > 0) {

        //     const listInfluencers = result.data

        //     const userInfluencer = _.find(listInfluencers, { contaInstagram: contaInstagram })

        //     if (userInfluencer) return toast.warning('Influencer já cadastrado.')

        // }


        fornecedor.push(obj)




        try {
            Requests
                .createdFornecedor(fornecedor)
                .then(response => {



                    if (response.r) {


                        this.getAllFornecedor()

                        this.setState(
                            {
                                municipioId: '',
                                nome: '',
                                cpfCnpj: '',
                                banco: '',
                                agencia: '',
                                conta: '',
                                tipoConta: '',
                                telefone: '',
                                tipoProdutoServico: '',
                                tipoChavePix: '',
                                chavePix: '',
                                pagamentoViaPix: true

                            })
                        return toast.success('Fornecedore Cadastrado')

                    }
                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {
            console.log(error)

        }





    }




    goBack() {

        this.props.history.push(`/plano`)

    }






    componentDidMount() {

        const cookies = Cookies.get('qd-token')

        if (cookies === undefined) window.location.href = `/`

        this.getAllBanco()

        this.getAllMunicipios()
        this.getAllFornecedor()


    }

    onChangeMaskCpf = (ev) => {

        const originalValue = unMask(ev.target.value)
        const maskedValue = mask(originalValue, ['999.999.999-99', '99.999.999/9999-99'])
        this.setState({ cpfCnpj: maskedValue })
    }

    onChangeMaskTelefone = (ev) => {

        const originalValue = unMask(ev.target.value)
        const maskedValue = mask(originalValue, ['(99) 99999-9999'])
        this.setState({ telefone: maskedValue })
    }

    onChangeMaskAgencia = (ev) => {

        const originalValue = unMask(ev.target.value)
        const maskedValue = mask(originalValue, ['9999-9'])
        this.setState({ agencia: maskedValue })

    }


    onChangeChavePix = (ev) => {

        const { tipoChave } = this.state

        const originalValue = unMask(ev.target.value)

        if (tipoChave == 'CPF' || tipoChave == 'CNPJ') {

            const maskedValue = mask(originalValue, ['999.999.999-99', '99.999.999/9999-99'])
            this.setState({ pix: maskedValue })
        }

        if (tipoChave == 'CELULAR') {
            const maskedValue = mask(originalValue, ['(99) 99999-9999'])
            this.setState({ pix: maskedValue })

        }







    }


    onChangeMaskConta = (ev) => {

        const originalValue = unMask(ev.target.value)
        const maskedValue = mask(originalValue, ['9999-9', '99999-9', '999999-9', '9999999-9', '99999999-9'])
        this.setState({ conta: maskedValue })

    }



    render() {

        const {
            municipios,
            municipioId,
            nome,
            cpfCnpj,
            banco,
            agencia,
            conta,
            tipoConta,
            listaFornecedores,
            loadingTable,
            loadingSelectMunicipio,
            telefone,
            listaBancos,
            tipoProdutoServico,
            pix,
            tipoChave,
            pagamentoViaPix


        } = this.state




        return (

            <div style={{ margin: '20px 20px 20px 20px' }}>

                <div style={{ width: '100%' }}>
                    <Form.Field style={{ margin: '0px 0px 20px 0px' }} basic color='black' control={Button} onClick={() => this.goBack()} >Voltar</Form.Field>
                </div>

                <Form size='small'>

                    <Form.Group widths='equal'>

                        <Form.Field
                            // style={{ backgroundColor: 'plano6f42c1', color: 'white' }}

                            control={Select}
                            label='Cidade'
                            options={municipios}
                            loading={loadingSelectMunicipio}
                            search
                            placeholder='Cidade Do Fornecedor'
                            value={municipioId}
                            onChange={(event, data) => this.setState({ municipioId: data.value })}
                        />

                        <Form.Field
                            required
                            control={TextArea}
                            label='Tipo do produto / Serviço a Fornecer'
                            value={tipoProdutoServico}
                            onChange={(event, data) => this.setState({ tipoProdutoServico: data.value })}
                        />

                    </Form.Group>



                    <Form.Group widths='equal'>
                        <Form.Field

                            control={Input}
                            label='Nome/Razão Social'
                            value={nome}
                            onChange={(event, data) => this.setState({ nome: data.value })}
                        />
                        <Form.Field
                            disabled={false}
                            control={Input}
                            label='CPF/CNPJ'
                            placeholder="000.000.000-00 ou 00.000.000/0000-00"
                            value={cpfCnpj}
                            onChange={this.onChangeMaskCpf}


                        />

                        <Form.Field
                            disabled={false}
                            control={Input}
                            label='Telefone'
                            placeholder="(00) 00000-0000"
                            value={telefone}
                            onChange={this.onChangeMaskTelefone}


                        />


                        <Form.Field

                            control={Select}
                            label='Banco'
                            options={listaBancos}
                            search
                            value={banco}
                            onChange={(event, data) => this.setState({ banco: data.value })}
                        />
                    </Form.Group>

                    {
                        pagamentoViaPix ? <Form.Field

                            style={{ borderRadius: '20px', backgroundColor: '#008000' }}
                            control={Button}
                            label='Pagamento Por Conta Bancária'
                            onClick={() => this.setState({ pagamentoViaPix: false })}

                        /> : <Form.Field

                            style={{ borderRadius: '20px', backgroundColor: '#A9A9A9' }}
                            control={Button}
                            label='Pagamento via Pix'
                            onClick={() => this.setState({ pagamentoViaPix: true })}

                        />
                    }



                    <Form.Group widths='equal'>




                        {pagamentoViaPix ? <Form.Group widths='equal'>
                            <Form.Field

                                control={Select}
                                label='Tipo da Chave'
                                options={tiposChaves}
                                search
                                value={tipoChave}
                                onChange={(event, data) => this.setState({ tipoChave: data.value, pix: '' })}
                            />

                            <Form.Field

                                control={Input}
                                label='Chave Pix'
                                value={pix}
                                onChange={this.onChangeChavePix}
                            />

                        </Form.Group> : <Form.Group widths='equal'>


                            <Form.Field

                                control={Input}
                                label='Agência'
                                value={agencia}
                                onChange={this.onChangeMaskAgencia}
                            />

                            <Form.Field

                                control={Input}
                                label='Conta - Se o Digito for X, use 0'
                                value={conta}
                                onChange={this.onChangeMaskConta}
                            />


                            <Form.Field

                                control={Select}
                                label='Tipo De Conta'
                                options={tipoDeContas}
                                search
                                value={tipoConta}
                                onChange={(event, data) => this.setState({ tipoConta: data.value })}
                            />

                        </Form.Group>}




                        <Form.Field positive label='.' control={Button} onClick={() => this.cadastrarFornecedor()}>Cadastrar</Form.Field>

                    </Form.Group>

                </Form>

                <div style={{ width: '100%', height: '500px', overflowY: 'scroll' }}>

                    <Loader active={loadingTable} size='big'>Loading</Loader>

                    {
                        listaFornecedores.length > 0 ? <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>id</Table.HeaderCell>
                                    <Table.HeaderCell>nome</Table.HeaderCell>
                                    <Table.HeaderCell>CPF/CNPJ</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body >
                                {


                                    listaFornecedores.length > 0 ? listaFornecedores.map((fornecedor, index) =>
                                        <Table.Row key={index}>
                                            <Table.Cell><ReactJson src={fornecedor} collapsed={true} /></Table.Cell>
                                            <Table.Cell>{fornecedor.nome} </Table.Cell>
                                            <Table.Cell>{fornecedor.cpfCnpj}</Table.Cell>

                                        </Table.Row>

                                    ) : ''
                                }


                            </Table.Body>
                        </Table> : ''
                    }

                </div>

            </div>
        )
    }
}


export default CadastroFornecedor
export const API_URL = 'https://69p49iiw43.execute-api.us-east-2.amazonaws.com'

export const TOKEN_BOT = '710152775:AAGhtmE2i2S4JwhAcapxXWI1N9amOoa6CRE'
export const TOKEN_PAINEL_PARCEIRO = ''
export const API_DEV = 'locallocalhost:7000/'

// export const ACESSO_ACRIACAO = [
//     'danilo@querodelivery.com',
//     'miguel@querodelivery.com',
//     'deyvson.bezerra@querodelivery.com',
//     'luiza.santos@querodelivery.com',
//     'gardenia.rocha@querodelivery.com',
//     'alexandre.miranda@querodelivery.com',
//     'lucimara.nascimento@querodelivery.com',
//     'rejane.carvalho@querodelivery.com',
//     'daniel.barros@querodelivery.com',
//     'senivaldo.gonzaga@querodelivery.com',
//     'joicileide.dias@querodelivery.com',
//     'joici.dias@querodelivery.com',
//     'larissa.andrade@querodelivery.com',
//     'acarlagonccalves@gmail.com',
//     'matheus.medeiros@querodelivery.com',
//     'lucas.oliveira@querodelivery.com',
//     'cleane.santos@querodelivery.com',
//     'silverioleticia22@gmail.com',
//     'mariane.nascimento@querodelivery.com',
//     'katia.cristina@querodelivery.com',
//     'jailma.araujo@querodelivery.com',
//     'iara.luna@querodelivery.com',
//     'acarlagoncalvess@gmail.com',
//     'ricardo.dias@lucree.com.br',
//     'dannunesquerodelivery@gmail.com',
//     'anny_dantas.v@hotmail.com',
//     'brenobsm@hotmail.com',
//     'angel.gomiss@gmail.com',
//     'lincoln.junior@lucree.com.br',
//     'klebermartins01@hotmail.com',
//     'jhenatta.oliveira@querodelivery.com',
//     'brenobsm@hotmail.com',
//     'karen.gabriele@querodelivery.com',
//     'danieli.santos@querodelivery.com',
//     'noelingtonjunior@hotmail.com',
//     'keiladianac@gmail.com',
//     'stennyo.b@gmail.com',
//     'bruno_1108@hotmail.com',
//     'prportelaalves@gmail.com',
//     'paty.lima1@hotmail.com',
//     'bsgravacoes@hotmail.com',
//     'ricardo.dias@querodelivery.com',
//     'julia.silva@querodelivery.com',
//     'danielly.silva@querodelivery.com',
//     'laysanallanda15@gmail.com',
//     'francklyn.carmel@hotmail.com',
//     'carlosdazz87@gmail.com',
//     'jualro26@gmail.com',
//     'renata85207086@gmail.com',
//     'querodelivery.mandirituba@gmail.com',
//     'jualro26@gmail.com',
//     'bezerros@querodelivery.city',
//     'trindade.comercial@querodelivery.city',
//     'saojoaodaboavista@querodelivery.city',
//     'russas@querodelivery.city',
//     'iule.carla@querodelivery.com',
//     'jualro26@gmail.com',
//     'iule.carla@querodelivery.com',
//     'qdguarapari@gmail.com',
//     'nick4arm@gmail.com',
//     'lary2609@gmail.com',
//     'cibeleluiza@hotmail.com',
//     'danieleace03@gmail.com',
//     'tiago.daniel@querodelivery.com'
    
    

// ]
import React from 'react'

import AuthContext from "../context/authContext";

import { DeviceUUID } from 'device-uuid'
import Requests from '../shared/Requests'

import Cookies from 'js-cookie'
import { setBearerToken } from '../AxiosApi'

import Inicio from "../componentes/inicio";

import Logo from "../img/logotipo-qd-branco.png";

import { Container, Grid, Form, Message } from 'semantic-ui-react'



import _ from "lodash";
import { toast } from 'react-toastify';








class Login extends React.Component {

    static contextType = AuthContext


    state = {
        loading: false,
        error: null,

        email: '',
        senha: '',
        tentativas: 0,

        novaSenhaRequested: false,

        showModalSenha: false,
        tokenNovaSenha: null,
        manager: null,

        novaSenha: '',
        novaSenhaRepeat: '',
        loadingSalvarSenha: false,
        loginValidate: false,

        user: {},
        listaConsultor: []
    }

    getConsultorByEmail = async (email) => {

        try {


            Requests.getConsultorByEmail(email)
                .then(response => {

                    debugger

                    console.log(response)



                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {

            console.log(error)

        }

    }

    getAllConsultor = async () => {

        debugger


        try {

            Requests
                .getAllConsultor()
                .then(response => {
                    const lista = response

                    if (lista.length > 0) {

                        this.setState({ listaConsultor: lista })


                    }



                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }
    }


    sendAuth = async (ev) => {
        ev.preventDefault()

        const { user, setUser } = this.context

        const { loading, email, senha, tentativas, listaConsultor } = this.state;


        if (loading) return null;

        var uuid = new DeviceUUID().get();

        if (!email || !senha) {

        }

        this.setState({
            loading: true,
            error: null
        })

        var uuid = new DeviceUUID().get();





        Requests
            .authLogin(email, senha, uuid)
            .then(async r => {

                if (r.r) {

                    if (r.isAtivo == false) return toast.error('Login indisponível no momento por motivos de segurança.')


                    const userLogadoEmail = r.data.email

                    Cookies.set('qd-token', r.data.token, { expires: 1 });
                    Cookies.set('user', email, { expires: 1 });
                    sessionStorage.setItem('user', JSON.stringify(r.data));

                    setBearerToken()

                    debugger

                    const consultorResponse = await Requests.getConsultorByEmail(email)

                    const consultor = consultorResponse.data[0]


                    r.data['consultorLogado'] = consultor


                    setUser(r)

                    if (consultor === undefined) {
                        this.setState({ loading: false })
                        return toast.error('Usuário Sem Acesso, entre em contato com administrador do sistema.')
                    }


                    this.setState({ loginValidate: true })
                } else {
                    this.setState({
                        tentativas: tentativas + 1,
                        senha: ''
                    })
                    this.setState({ error: r.errors.join('\n'), loading: false, senha: '' })
                }
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false, error: err.message })
            })
    }

    handleChange = ({ target: { name, value } }) => this.setState({ [name]: value })

    componentDidMount() {
       

    }


    render() {


        const { loading, error, email, senha, loginValidate } = this.state




        return (

            <div  >
                {
                    loginValidate ? <Inicio validation={true} /> : <div className='general-login-container' >

                        <div className='text-center' style={{ marginTop: '30px' }} >
                            <img src={Logo} height="70"></img>

                        </div>

                        <br />


                        <h4 className="text-center no-margin text-white">QD Plan</h4>
                        <div >

                            <Container>

                                <div className="text-center">

                                </div>
                                <br />


                                <Grid>
                                    <Grid.Row centered>
                                        <Grid.Column mobile='16' tablet='6' computer='5' largeScreen='4'>
                                            <br />
                                            <br />

                                            <Form onSubmit={this.sendAuth.bind(this)}>
                                                <Form.Input
                                                    icon='user'
                                                    iconPosition='left'
                                                    placeholder='Email'
                                                    onChange={this.handleChange.bind(this)}
                                                    name='email'
                                                    value={email}
                                                    required
                                                    autoComplete='off'
                                                    size='large'
                                                    autoFocus
                                                />
                                                <Form.Input
                                                    icon='asterisk'
                                                    iconPosition='left'
                                                    placeholder='Senha'
                                                    onChange={this.handleChange.bind(this)}
                                                    name='senha'
                                                    value={senha}
                                                    type='password'
                                                    size='large'
                                                    required
                                                />
                                                <Form.Button
                                                    icon='sign in'
                                                    labelPosition='right'
                                                    // onClick={this.sendLogin.bind(this)}
                                                    disabled={loading}
                                                    loading={loading}
                                                    animated='fade'
                                                    content='Entrar'
                                                    fluid
                                                />
                                            </Form>
                                            {
                                                error ?
                                                    <Message error>{error}</Message>
                                                    :
                                                    null
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                            </Container>




                        </div>

                    </div>
                }
            </div>

            // </UserContext.Provider>


        )
    }
}


//Login.contextType = AuthContext

export default Login

//export { UserContext }



import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Loader, Button, Table, Form, Select, Input, Modal, TextArea, Icon, Progress, Dimmer, Segment, Accordion } from "semantic-ui-react";
import numeral from "numeral";

import Cookies from 'js-cookie'


import { toast } from 'react-toastify';

import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';





import moment from "moment";


import Requests, { getAllAcoes } from '../shared/Requests'


import _, { findLastKey, forIn } from "lodash";

import AuthContext from "../context/authContext"

import ModalVoucher from "./formVaucher";






const optionsStatusAcoes = [
    { key: '1', text: 'TODOS', value: 'TODOS' },
    { key: '2', text: 'CONCLUIDO', value: 'CONCLUIDO' },
    { key: '3', text: 'NAO_INICIADA', value: 'NAO_INICIADA' },
    { key: '4', text: 'CANCELADO', value: 'CANCELADO' },
    { key: '5', text: 'EM_ANDAMENTO', value: 'EM_ANDAMENTO' },
    { key: '6', text: 'ATRASADO', value: 'ATRASADO' },


]




class PlanoAcao extends Component {

    static contextType = AuthContext

    constructor(props) {
        super(props);
    }

    state = {
        listaPlanoAcoes: [],
        listaMunicipios: [],
        dataAtual: null,
        loadingTable: false,
        municipioId: null,
        listaTemporariaPlanoAcao: [],
        modalEdicao: false,
        acao: {},
        municipioModal: [],
        nomeResponsavelPeloPlano: '',
        descricao: '',
        participantes: [],
        selectedConsultorId: '',
        listaConsultor: [],
        selectedParticipantes: [],
        status: '',

        causa: '',
        onde: '',
        prazoInicio: '',
        como: '',
        quantoCusta: 0,
        observacao: '',
        prazoFim: '',
        userAuthCriacao: false,
        desibleEdicao: true,
        loadingSelectMunicipio: false,
        selectedPlaces: [],
        selectedInfluecer: [],
        listaPlace: [],
        dataPagamentoAcao: '',
        buttonAcaoParceiro: false,

        comentario: '',
        isCreditoFaturaPlace: false,
        loadingButtonEditarPlanoAcao: false,
        quadroResumo: [],
        acaoComInfluencer: false,
        listaInfluencers: [],
        influencerId: '',
        openModalMotivoCancelamento: false,
        acaoIdMotivoCancelamento: null,
        motivoCancelamentoAcao: '',
        acaoComFornecedor: null,
        fornecedorId: null,
        listaFornecedores: [],
        metaCidade: [],
        isModalVoucher: false,
        acaoVaucher: false,
        listaTipoAcao: [],
        tipoAcao: '',
        tpvProgress: 0,
        loadgindTpvProgress: false,
        modalValorRealizado: false,
        acaoIdValorRealizado: null,
        valorRealizado: 0,
        objAcaoFormalizarValorAcao: {},
        valorCreditoParceiro: 0,
        lancamentosCreditoPlace: [],
        loadLancamento: false,
        tpvRealizado: 0,
        activeIndex: 0,
        dataFiltro: undefined

    }


    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }


    duplicarAcao = async (acao) => {

        const { municipioId } = this.state

        try {

            const acaoDuplicate = []

            const obj = {
                municipioId: municipioId,
                acaoId: parseInt(acao.acaoId),
                consultorCriadorId: parseInt(acao.consultorCriadorId),
                placeIds: acao.placeIds,
                influencerId: acao.influencerId,
                fornecedorId: acao.influencerId,
                participantes: acao.participantes,
                causa: acao.causa,
                consultorExecucaoId: parseInt(acao.consultorExecucaoId),
                onde: acao.onde,
                prazoInicio: moment(acao.prazoInicio).format('YYYY-MM-DD hh:mm'),
                prazoFim: moment(acao.prazoFim).format('YYYY-MM-DD hh:mm'),
                como: acao.como,
                quantoCusta: parseFloat(acao.quantoCusta),
                observacao: acao.observacao,
                creadetAt: moment(new Date()).format('YYYY-MM-DD hh:mm'),
                status: acao.status,
                dataPagamentoAcao: acao.dataPagamentoAcao ? moment(acao.dataPagamentoAcao).format('YYYY-MM-DD hh:mm') : null,
                isCreditoFaturaPlace: acao.isCreditoFaturaPlace,
                comentario: acao.comentario !== '' ? acao.comentario : null
            }

            acaoDuplicate.push(obj)

            const result = await Requests.createAcoes(acaoDuplicate)

            const data = new Date(obj.prazoInicio)


            await this.getAllPlanoAcaoByMunicipio(municipioId, data)

            return toast.success('Ação Duplicada')


        } catch (error) {
            console.log(error)

        }

    }


    filterTipoAcao = async (tipoAcao) => {

        const { listaPlanoAcoes, municipioId } = this.state

        const data = new Date()

        const mes = moment(data).format('MM')
        const ano = moment(data).format('YYYY')

        if (municipioId === null || municipioId === '') {
            return toast.warning('Selecionar Municipio')

        }

        try {

            const result = await Requests.getAllPlanoAcaoByMunicipio(municipioId, mes, ano)

            const lista = result.data


            this.setState({ tipoAcao: tipoAcao })



            const filtroAcoes = _.filter(lista, { acaoTipo: tipoAcao })

            this.setState({ listaPlanoAcoes: filtroAcoes })


        } catch (error) {
            console.log(error)

        }
    }


    getAllAcoes = () => {

        const listaAcoes = []

        try {
            Requests
                .getAllAcoes()
                .then(response => {

                    const lista = response



                    for (let i = 0; i < lista.length; i++) {
                        const acao = lista[i];
                        const obj = {
                            key: acao.acaoId,
                            text: `${acao.tipo}`,
                            value: acao.tipo,

                        }

                        listaAcoes.push(obj)

                    }

                    this.setState({ listaTipoAcao: listaAcoes })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }

    }


    getAllInfluencerByMunicipio = (municipioId) => {

        // this.setState({ loadingTable: true, loadingSelectMunicipio: true })

        let listInfluencers = []
        try {

            Requests
                .getAllInfluencerByMunicipioId(municipioId)
                .then(response => {

                    if (response.r) {

                        const listaInfluencers = response.data


                        for (let i = 0; i < listaInfluencers.length; i++) {
                            const influencer = listaInfluencers[i];

                            const obj = {
                                key: influencer.influencerId,
                                text: `${influencer.contaInstagram}`,
                                value: influencer.influencerId.toString()

                            }

                            listInfluencers.push(obj)
                        }

                        this.setState({ listaInfluencers: listInfluencers })


                    }
                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {

            console.log(error)

        }



    }


    getAllMunicipios = async () => {

        const { user } = this.context



        if (user.initial) return window.location.href = `/`

        const acessoRoout = user.data.isRoot

        const listaMunicipioIds = user.data.municipioIds

        this.setState({ loadingSelectMunicipio: true })

        const listaMunicipios = []

        try {
            Requests
                .getAllMunicipios()
                .then(response => {

                    const lista = response.data

                    if (acessoRoout) {

                        for (let i = 0; i < lista.length; i++) {
                            const municipio = lista[i];

                            const obj = {
                                key: municipio.municipioId,
                                text: `${municipio.nome}/${municipio.uf}`,
                                value: municipio.municipioId,

                            }


                            listaMunicipios.push(obj)

                        }

                    } else {
                        for (let j = 0; j < listaMunicipioIds.length; j++) {
                            const municipioId = listaMunicipioIds[j];

                            const municipio = _.find(lista, { municipioId: municipioId })

                            if (municipio) {

                                const obj = {
                                    key: municipio.municipioId,
                                    text: `${municipio.nome}/${municipio.uf}`,
                                    value: municipio.municipioId,

                                }


                                listaMunicipios.push(obj)

                            }



                        }
                    }



                    this.setState({ listaMunicipios: listaMunicipios, loadingSelectMunicipio: false })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }




    }


    filterStatusAcoes = async (status) => {



        const { municipioId } = this.state

        const data = new Date()

        const mes = moment(data).format('MM')
        const ano = moment(data).format('YYYY')


        if (municipioId === null || municipioId === '') {
            return toast.warning('Selecionar Municipio')

        }

        this.setState({ status: status })




        await this.getAllPlanoAcaoByMunicipio(municipioId, new Date())





    }


    getAllPlanoAcaoByMunicipio = async (municipioId, data = new Date()) => {


        if (municipioId === null) return toast.warning('Selecione cidade')

        const { user, setUser } = this.context

        const mes = moment(data).format('MM')
        const ano = moment(data).format('YYYY')


        const resultMeta = await this.getMetaByMunicipio(municipioId, mes, ano)




        this.setState({ loadingTable: true })

        let listaAcoes = []

        Requests
            .getAllPlanoAcaoByMunicipio(municipioId, mes, ano)
            .then(response => {

                listaAcoes = response.data
                const usuarioConsultorLogado = user.data.consultorLogado

                const userAuth = usuarioConsultorLogado.admin

                if (userAuth || user.data.isAfiliado === true) {

                    if (listaAcoes.length > 0) {

                        const listaQuadroResumo = this.quadroResumo(listaAcoes)



                        this.setState({ quadroResumo: listaQuadroResumo })

                    } else {
                        this.setState({ quadroResumo: [] })

                    }

                    if (this.state.status !== '') {



                        if (this.state.status === 'TODOS') {


                            this.setState({ listaPlanoAcoes: listaAcoes, loadingTable: false })


                        } else {
                            const filtroAcoes = _.filter(listaAcoes, { status: this.state.status })

                            this.setState({ listaPlanoAcoes: filtroAcoes, loadingTable: false })

                        }



                    } else {

                        this.setState({ listaPlanoAcoes: listaAcoes, loadingTable: false })

                    }

                } else {

                    const consultorId = usuarioConsultorLogado.consultorId

                    const listaAcoesFiltrada = _.filter(listaAcoes, { 'consultorExecucaoId': consultorId })





                    if (listaAcoesFiltrada.length > 0) {

                        const listaQuadroResumo = this.quadroResumo(listaAcoesFiltrada)

                        this.setState({ quadroResumo: listaQuadroResumo })

                    } else {
                        this.setState({ quadroResumo: [] })

                    }

                    if (this.state.status !== '') {



                        if (this.state.status === 'TODOS') {

                            this.setState({ listaPlanoAcoes: listaAcoesFiltrada, loadingTable: false })

                        } else {
                            const filtroAcoes = _.filter(listaAcoesFiltrada, { status: this.state.status })


                            this.setState({ listaPlanoAcoes: filtroAcoes, loadingTable: false })

                        }



                    } else {

                        this.setState({ listaPlanoAcoes: listaAcoesFiltrada, loadingTable: false })

                    }
                }





            })
            .catch(err => {
                console.log(err)

            })

    }


    //AQUI

    filterMesAno = (data) => {

        const { municipioId } = this.state


        this.setState({ tpvProgress: 0, quadroResumo: 0, metaCidade: [], dataFiltro: data })





        if (municipioId === null) return toast.warning('Selecionar Municipio.')

        this.getAllPlanoAcaoByMunicipio(municipioId, data)



    }



    updateStatusPlanoAcao = (planoAcaoId, status, municipioId, comentarioAcao, acao) => {

        let listaAcoes = []


        if ((status === 'CONCLUIDO') && ((acao.quantoCusta > 0) && (acao.valorRealizado === 0))) return toast.warning('Para concluir açao, informar o valor realizado.')


        if (status === 'CONCLUIDO' && (comentarioAcao === null || comentarioAcao === 'null')) {

            return toast.warning('Para conclusão da ação, informar comentário!')

        }



        this.setState({ loadingTable: true })

        Requests
            .updateStatusPlanoAcao(planoAcaoId, status)
            .then(response => {

                this.getAllPlanoAcaoByMunicipio(municipioId)
                // this.setState({ listaPlanoAcoes: listaAcoes })


            })
            .catch(err => {
                console.log(err)
                // toast.error(err.message)
                // this.setState({ loadingPlace: false })
            })


    }


    getAllConsultor = () => {



        const listConsultor = []

        try {
            Requests
                .getAllConsultor()
                .then(response => {

                    const lista = response

                    for (let i = 0; i < lista.length; i++) {
                        const consultor = lista[i];

                        const obj = {
                            key: consultor.consultorId,
                            text: `${consultor.nome} - (${consultor.time})`.toUpperCase(),
                            value: `${consultor.consultorId}`,

                        }

                        listConsultor.push(obj)

                    }

                    this.setState({ listaConsultor: listConsultor })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }

    }


    formalizarEntregaPlanoAcao = (municipioId) => {

        const { dataAtual } = this.state

        let hoje = new Date();
        let hojeFormatada = moment(hoje).format('YYYY-MM-DD')

        try {

            Requests
                .getAllPlanoAcaoByMunicipioId(municipioId)
                .then(response => {

                    const planoAcao = response.data

                    if (planoAcao.length > 0) {

                        for (let i = 0; i < planoAcao.length; i++) {
                            const acao = planoAcao[i];

                            if (acao.status !== 'CANCELADO' && acao.status !== 'CONCLUIDO' && acao.status !== 'ATRASADO') {
                                if (moment(acao.prazoFim).format('YYYY-MM-DD') < moment(hojeFormatada).format('YYYY-MM-DD')) {

                                    //this.updateStatusPlanoAcao(acao.planoAcaoId, 'ATRASADO', acao.municipioId)

                                }

                            }
                        }

                    } else {
                        // toast.warn('NÃO EXISTE PLACE CADASTRADO PARA ESSA CIDADE.')
                        //this.setState({ ...this.state, loadingPlace: false })
                    }
                })
                .catch(err => {
                    console.log(err)
                    // toast.error(err.message)
                    // this.setState({ loadingPlace: false })
                })
        } catch (error) {
            console.log(error)

        }
    }


    getAllPlaceByMunicipio = (municipioId) => {

        const listPlace = []

        try {
            Requests
                .getAllPlacesByMunicipio(municipioId)
                .then(response => {

                    const lista = response.data

                    for (let i = 0; i < lista.length; i++) {
                        const place = lista[i];

                        const obj = {
                            key: place.placeId,
                            text: `${place.nomeExibicao}`,
                            value: place.placeId

                        }

                        listPlace.push(obj)

                    }

                    this.setState({ listaPlace: listPlace })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }

    }




    acaoModal = async (acaoObj) => {


        const { listaMunicipios, listaConsultor, listaPlace, selectedPlaces, listaInfluencers } = this.state



        const municipioId = acaoObj.municipioId



        if (acaoObj.influencerId) {



            const idInfluencer = acaoObj.influencerId

            const listInfluencerSelected = idInfluencer.split(',')

            this.getAllInfluencerByMunicipio(municipioId)

            const arrayFilter = []


            this.setState({ selectedInfluecer: listInfluencerSelected, acaoComInfluencer: true })





            // this.setState({ influencerId: idInfluencer, acaoComInfluencer: true })

        }

        if (acaoObj.fornecedorId) {

            const idFornecedor = acaoObj.fornecedorId


            this.setState({ fornecedorId: idFornecedor, acaoComFornecedor: true })

        }



        if (acaoObj.isCreditoFaturaPlace) {
            this.setState({ isCreditoFaturaPlace: true })

        }


        const municipioKey = []
        const listaObjParticipantes = []
        const selectedConsultorId = acaoObj.consultorExecucaoId.toString()


        const listaPlaceIds = acaoObj.placeIds


        this.getAllPlaceByMunicipio(municipioId)


        if (listaPlaceIds !== null) {


            const listPlaceSelected = listaPlaceIds.split(',')

            let listPlaceSelectedFilter = listPlaceSelected.filter(item => item)


            if (listPlaceSelectedFilter.length > 0) {

                this.setState({ buttonAcaoParceiro: true })

                const listaPlaceRaiz = await Requests.getAllPlacesByMunicipio(municipioId)

                const listaLancamentos = await this.mapNomalizado(listaPlaceRaiz.data, listPlaceSelectedFilter, acaoObj.planoAcaoId)


                this.setState({ lancamentosCreditoPlace: listaLancamentos })

            }

            this.setState({ selectedPlaces: listPlaceSelectedFilter })




        } else {
            this.setState({ selectedPlaces: [] })

        }




        const municipio = _.find(listaMunicipios, { value: acaoObj.municipioId })

        acaoObj['municipio'] = municipio.text



        const listParticipantes = acaoObj.participantes.split(',')


        this.setState({
            listaConsultor,
            selectedConsultorId: selectedConsultorId,
            selectedParticipantes: listParticipantes
        });


        this.setState({
            acao: acaoObj,
            modalEdicao: true,

        })

    }



    handleChange = (e, { value }) => {
        this.setState({ selectedConsultorId: value })
    }

    handleAddition = (e, { value }) => {
        this.setState((prevState) => ({
            listaConsultor: [{ text: value, value }, ...prevState.listaConsultor],
        }))
    }

    logout = () => {
        Cookies.remove('qd-token');
        Cookies.remove('user');
        window.location.reload()
    }

    userLogado = () => {

        const { user } = this.context



        const cookiesUser = Cookies.get('user')



        if (user.data === undefined) window.location.href = `/`


        if (user.data.isAfiliado === true) return this.setState({ userAuthCriacao: true, desibleEdicao: false })



        if (user.data.consultorLogado.admin == true) return this.setState({ userAuthCriacao: true, desibleEdicao: false })




    }

    updatePlanoAcao = (acao) => {

        const { user } = this.context




        const {
            selectedPlaces,
            participantes,
            prazoInicio,
            prazoFim,
            quantoCusta,
            dataPagamentoAcao,
            observacao,
            como,
            onde,
            selectedConsultorId,
            selectedParticipantes,
            causa,
            comentario,
            isCreditoFaturaPlace,
            influencerId,
            fornecedorId,
            selectedInfluecer,
            dataFiltro


        } = this.state




     


        if ((acao.status == "CONCLUIDO") && (user.data.consultorLogado.email !== 'rejane.carvalho@querodelivery.com') && (user.data.consultorLogado.email !== 'gardenia.rocha@querodelivery.com')) return toast.error('Ação concluída, não pode ser editada.')






        const planoAcaoId = acao.planoAcaoId


        const municipioId = acao.municipioId


        this.setState({ loadingButtonEditarPlanoAcao: true })



        const objAcao = {

            causa: causa !== '' ? causa : acao.causa,
            como: como !== '' ? como : acao.como,
            consultorExecucaoId: parseInt(selectedConsultorId),
            dataPagamentoAcao: dataPagamentoAcao !== '' ? moment(dataPagamentoAcao).format('YYYY-MM-DD hh:mm') : acao.dataPagamentoAcao,
            observacao: observacao !== '' ? observacao : acao.observacao,
            onde: onde !== '' ? onde : acao.onde,
            participantes: selectedParticipantes.toString(),
            placeIds: selectedPlaces.toString(),
            prazoFim: prazoFim !== '' ? moment(prazoFim).format('YYYY-MM-DD hh:mm') : moment(acao.prazoFim).format('YYYY-MM-DD hh:mm'),
            prazoInicio: prazoInicio !== '' ? moment(prazoInicio).format('YYYY-MM-DD hh:mm') : moment(acao.prazoInicio).format('YYYY-MM-DD hh:mm'),
            quantoCusta: parseFloat(quantoCusta) > 0 ? parseFloat(quantoCusta) : parseFloat(acao.quantoCusta),
            updateAt: moment(new Date()).format('YYYY-MM-DD hh:mm'),
            comentario: comentario !== '' ? comentario : acao.comentario,
            isCreditoFaturaPlace: isCreditoFaturaPlace,
            influencerId: selectedInfluecer.toString(),
            fornecedorId: fornecedorId !== '' ? fornecedorId : acao.fornecedorId
        }


        


        const list = []
        list.push(objAcao)

        debugger

        if ((prazoInicio !== "") || (prazoFim !== "")) {

            this.setState({
                modalEdicao: false, municipioId: municipioId, loadingButtonEditarPlanoAcao: false,
                comentario: '',
                observacao: '',
                dataPagamentoAcao: '',
                como: '',
                causa: '',
                isCreditoFaturaPlace: false,
                prazoFim: '',
                prazoInicio: '',
                quantoCusta: 0,
                loadingButtonEditarPlanoAcao: false,
                influencerId: '',
                selectedInfluecer: [],
                acaoComInfluencer: false
            })




            return toast.warning('Consulte seu líder.')
        }



        try {
            Requests
                .updatePlanoAcao(planoAcaoId, list)
                .then(response => {

                    const data = new Date(acao.prazoInicio)

                    if (prazoInicio !== '' || prazoFim !== '') {

                        let hoje = new Date();
                        let hojeFormatada = moment(hoje).format('YYYY-MM-DD')

                        if (acao.status === 'ATRASADO') {

                            if (moment(prazoFim).format('YYYY-MM-DD') >= moment(hojeFormatada).format('YYYY-MM-DD')) {

                                const planoAcaoId = acao.planoAcaoId
                                const status = 'EM_ANDAMENTO'



                                Requests.updateStatusPlanoAcao(planoAcaoId, status)
                                    .then(response => {

                                        if (response.r) {
                                            this.quadroResumo()


                                            this.getAllPlanoAcaoByMunicipio(municipioId, data)
                                        }

                                    })
                                    .catch(err => {
                                        this.setState({ loadingButtonEditarPlanoAcao: false })
                                        console.log(err)

                                    })
                            }

                        }
                    }


                    this.setState({
                        modalEdicao: false, municipioId: municipioId, loadingButtonEditarPlanoAcao: false,
                        comentario: '',
                        observacao: '',
                        dataPagamentoAcao: '',
                        como: '',
                        causa: '',
                        isCreditoFaturaPlace: false,
                        prazoFim: '',
                        prazoInicio: '',
                        quantoCusta: 0,
                        loadingButtonEditarPlanoAcao: false,
                        influencerId: '',
                        selectedInfluecer: [],
                        acaoComInfluencer: false
                    })



                    this.getAllPlanoAcaoByMunicipio(municipioId, data)

                    if (response.r) return toast.success('Ação Editada')




                })
                .catch(err => {
                    this.setState({ loadingButtonEditarPlanoAcao: false })
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }



    }


    quadroResumo = (listaPlanoAcoes) => {

        if (listaPlanoAcoes.length > 0) {



            const quadroResumo = _(listaPlanoAcoes)
                .groupBy('status')
                .map((objs, key) => ({

                    'count': objs.length,
                    'status': key,
                    'quantoCusta': _.sumBy(objs, 'quantoCusta'),
                    'valorRealizado': _.sumBy(objs, 'valorRealizado')
                }))
                .value()



            return quadroResumo




        }



    }


    getTPV = (municipioId, data = new Date()) => {


        const mes = moment(data).format('MM')
        const ano = moment(data).format('YYYY')

        this.setState({ tpvProgress: 0, loadgindTpvProgress: true })


        try {

            Requests
                .getTpvByMuncipioId(municipioId, mes, ano)
                .then(response => {


                    if (response.r) {

                        const tpv = numeral(response.data[0].totalVendas).format('0')
                        const meta = numeral(response.data[0].meta).format('0')



                        const tpvProgress = ((parseFloat(tpv) / parseFloat(meta)) * 100).toFixed(2)

                        this.setState({ tpvProgress: tpvProgress, loadgindTpvProgress: false, tpvRealizado: response.data[0].totalVendas })



                    }
                })
                .catch(err => {

                    if (err.response.status === 500) {

                        this.setState({ tpvProgress: 0, loadgindTpvProgress: false, tpvRealizado: 0 })

                    } else {

                        console.log(err)


                    }


                })

        } catch (error) {



            console.log(error)

        }
    }

    getMetaByMunicipio = (municipioId, mes, ano) => {


        try {

            Requests
                .getMetaByMunicipioId(municipioId, mes, ano)
                .then(response => {

                    if (response.r) {

                        debugger
                        const meta = response.data

                        this.setState({ metaCidade: meta })



                    }
                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {

            console.log(error)

        }
    }

    updateValorRealizado = (planoAcaoId, obj) => {

        this.setState({ modalValorRealizado: true, acaoIdValorRealizado: planoAcaoId, objAcaoFormalizarValorAcao: obj })




    }



    getAllFornecedor = () => {

        // this.setState({ loadingTable: true, loadingSelectMunicipio: true })
        try {

            Requests
                .getAllFornecedor()
                .then(response => {

                    if (response.r) {

                        const listForncedor = []

                        const listaFornecedor = response.data

                        for (let i = 0; i < listaFornecedor.length; i++) {
                            const fornecedor = listaFornecedor[i];

                            const obj = {
                                key: fornecedor.fornecedorId,
                                text: `${fornecedor.nome}`,
                                value: fornecedor.fornecedorId,

                            }

                            listForncedor.push(obj)



                        }

                        this.setState({ listaFornecedores: listForncedor })



                    }
                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {

            console.log(error)

        }
    }

    salvarMotivoCancelamento = async () => {

        const { acaoIdMotivoCancelamento, motivoCancelamentoAcao, municipioId } = this.state

        const arrayMotivoCancelamento = []

        if (acaoIdMotivoCancelamento === null) return toast.warning('Selecionar Ação')

        if (motivoCancelamentoAcao === '') return toast.warning('Informar Motivo de Cancelamento')

        const obj = {
            motivoCancelamento: motivoCancelamentoAcao

        }

        arrayMotivoCancelamento.push(obj)



        const result = await Requests.cancelarPlanoAcao(acaoIdMotivoCancelamento, arrayMotivoCancelamento)


        this.getAllPlanoAcaoByMunicipio(municipioId)

        toast.success('Ação Cancelada')


        this.setState({
            acaoIdMotivoCancelamento: null,
            motivoCancelamentoAcao: '',
            openModalMotivoCancelamento: false

        })


    }

    closeModalAcao = () => {

        // const { useValorAcaoControlado, setValorAcaoControlado, useNovoVaucer, setNovoVaucer } = this.context
        // if (useNovoVaucer) return toast.warning('Vaucher Adicionado, valor da ação modificado. Salvar alterações.')

        this.setState({ modalEdicao: false, quantoCusta: 0, isCreditoFaturaPlace: false, buttonAcaoParceiro: false, dataPagamentoAcao: '', como: '', prazoFim: '', prazoInicio: '', observacao: '', comentario: '', influencerId: '', acaoComInfluencer: false, listaLancamentos: [], selectedPlaces: [] })

        //setValorAcaoControlado(0)




    }


    salvarValorRealizado = async () => {

        const { valorRealizado, acaoIdValorRealizado, municipioId, objAcaoFormalizarValorAcao, userAuthCriacao } = this.state

        const valorParserFloat = parseFloat(valorRealizado)

        try {

            if ((userAuthCriacao !== true) && (objAcaoFormalizarValorAcao.status === 'CONCLUIDO')) return toast.warning('Ação concluída, entrar em contato com supervisor.')

            if (valorRealizado > objAcaoFormalizarValorAcao.quantoCusta) return toast.warning('Valor realizado maior que valor previsto no investimento.')

            const result = await Requests.SetPlanoAcaoValorRealizado(acaoIdValorRealizado, valorParserFloat)

        } catch (error) {

        }

        this.setState({ modalValorRealizado: false, objAcaoFormalizarValorAcao: {}, acaoIdValorRealizado: null, valorRealizado: 0 })

        this.getAllPlanoAcaoByMunicipio(municipioId)

    }


    normalizePlaces = async (listaPlaceIds) => {

        const { municipioId } = this.state

        const list = await Requests.getAllPlacesByMunicipio(municipioId)

        const listaObjPlaces = []



        for (let i = 0; i < listaPlaceIds.length; i++) {
            const placeId = listaPlaceIds[i];

            const place = _.find(list.data, { 'placeId': placeId });

            const obj = {
                placeId: place.placeId,
                nomeExibicao: place.nomeExibicao
            }

            listaObjPlaces.push(obj)

        }

        return listaObjPlaces

    }



    mapNomalizado = async (listaPlace, lista, planoAcaoId) => {

        const list = []

        try {



            const result = await Requests.getAllLancamentosByPlanoAcaoId(planoAcaoId)


            for (let i = 0; i < lista.length; i++) {
                const placeIdSelected = lista[i];

                const placeNormalizado = _.find(listaPlace, { placeId: placeIdSelected })
                const placeLancamento = _.find(result.data, { placeId: placeIdSelected })


                if (placeLancamento) {

                    const obj = {
                        placeNomeExibicao: placeNormalizado.nomeExibicao,
                        placeId: placeNormalizado.placeId,
                        valorCredito: placeLancamento.valorCredito
                    }

                    list.push(obj)


                } else {

                    const obj = {
                        placeNomeExibicao: placeNormalizado.nomeExibicao,
                        placeId: placeNormalizado.placeId,
                        valorCredito: 0
                    }

                    list.push(obj)

                }




            }

        } catch (error) {

        }






        return list


    }

    nomalizeNotRequest = (selectedPlaces, listaPlaceRaiz, resultLancamentoLista) => {


        const list = []
        for (let i = 0; i < selectedPlaces.length; i++) {
            const placeIdSelected = selectedPlaces[i];

            const placeNormalizado = _.find(listaPlaceRaiz, { placeId: placeIdSelected })
            const placeLancamento = _.find(resultLancamentoLista, { placeId: placeIdSelected })





            if (placeLancamento) {

                const obj = {
                    placeNomeExibicao: placeNormalizado.nomeExibicao,
                    placeId: placeNormalizado.placeId,
                    valorCredito: placeLancamento.valorCredito
                }

                list.push(obj)


            } else {

                const obj = {
                    placeNomeExibicao: placeNormalizado.nomeExibicao,
                    placeId: placeNormalizado.placeId,
                    valorCredito: 0
                }

                list.push(obj)

            }



        }

        return list
    }





    salvarCreditoParceiro = async (planoAcaoId, placeId, acao, selectedPlaces) => {

        const { municipioId, valorCreditoParceiro } = this.state

        this.setState({ loadLancamento: true })
        const list = []

        try {




            const resultLancamentos = await Requests.getAllLancamentosByPlanoAcaoId(planoAcaoId)

            const listaPlaceRaiz = await Requests.getAllPlacesByMunicipio(municipioId)



            const lancamento = _.find(resultLancamentos.data, { placeId: placeId })



            const valorCreditado = _.sumBy(resultLancamentos.data, 'valorCredito')
            const valorCreditoParaLacanmento = acao.quantoCusta - _.sumBy(resultLancamentos.data, 'valorCredito')




            if (valorCreditoParceiro > valorCreditoParaLacanmento) {

                this.setState({ loadLancamento: false })


                return toast.warning(`Sem saldo para fazer crédito. Saldo livre para lançamento: R$ ${numeral(valorCreditoParaLacanmento).format(0.0)}`)


            }

            if (valorCreditoParceiro === 0) {

                this.setState({ loadLancamento: false })


                return toast.warning('Valor para crédito deve ser maior que 0.')
            }











            if (lancamento === undefined) {

                await Requests.setValorCreditoParceiro(planoAcaoId, placeId, valorCreditoParceiro)


            } else {



                await Requests.updateLancamento(lancamento.lancamentoId, valorCreditoParceiro)
            }


            const resultLancamentoLista = await Requests.getAllLancamentosByPlanoAcaoId(planoAcaoId)

            const r = this.nomalizeNotRequest(selectedPlaces, listaPlaceRaiz.data, resultLancamentoLista.data)




            this.setState({
                valorCreditoParceiro: 0,
                loadLancamento: false,
                lancamentosCreditoPlace: r


            })



            // this.setState({ valorCreditoParceiro: 0, loadLancamento: false })

        } catch (error) {

        }

    }

    getAllPlanoAcaoByConsultorId = async () => {
        try {

            const { user } = this.context

            debugger

            this.setState({ listaPlanoAcoes: [], quadroResumo: [] })

            const { dataFiltro } = this.state
            const consultorId = user.data.consultorLogado.consultorId


            const mes = dataFiltro ? moment(dataFiltro).format('MM') : moment(new Date()).format('MM')
            const ano = dataFiltro ? moment(dataFiltro).format('YYYY') : moment(new Date()).format('YYYY')





            Requests
                .getAllPlanoAcaoByConsultorId(consultorId, ano, mes)
                .then(response => {

                
                    this.setState({ listaPlanoAcoes: response.data })


                }).catch(err => {
                    console.log(err)

                })

        } catch (error) {
            console.log(error)

        }

    }


    componentDidMount() {

        const cookies = Cookies.get('qd-token')

        this.userLogado()

        if (cookies === undefined) window.location.href = `/`

        if (this.props.validation) {

        } else {

            const path = this.props.location.pathname

            const pathInitial = path.substr(7)

            const municipioId = pathInitial.split('/')[0]
            const prazoInicio = pathInitial.split('/')[1]

            this.setState({ municipioId: municipioId })
            this.getAllPlanoAcaoByMunicipio(municipioId, new Date(prazoInicio))

        }
        this.getAllAcoes()
        this.getAllMunicipios()
        this.getAllConsultor()
        this.getAllFornecedor()


    }


    render() {

        const {
            listaPlanoAcoes,
            listaMunicipios,
            dataAtual,
            loadingTable,
            modalEdicao,
            acao,
            municipioModal,
            listaConsultor,
            selectedParticipantes,

            municipioId,
            participantes,
            como,
            prazoFim,
            prazoInicio,
            onde,
            descricao,
            nomeResponsavelPeloPlano,
            selectedConsultorId,
            observacao,
            quantoCusta,
            userAuthCriacao,
            loadingSelectMunicipio,
            status,

            listaPlace,
            selectedPlaces,
            dataPagamentoAcao,
            buttonAcaoParceiro,
            desibleEdicao,

            comentario,
            isCreditoFaturaPlace,
            loadingButtonEditarPlanoAcao,
            quadroResumo,
            acaoComInfluencer,
            listaInfluencers,
            influencerId,
            acaoIdMotivoCancelamento,
            motivoCancelamentoAcao,
            openModalMotivoCancelamento,
            acaoComFornecedor,
            fornecedorId,
            listaFornecedores,
            metaCidade,
            isModalVoucher,
            acaoVaucher,
            listaTipoAcao,
            tipoAcao,
            tpvProgress,
            loadgindTpvProgress,
            modalValorRealizado,
            valorRealizado,
            objAcaoFormalizarValorAcao,
            valorCreditoParceiro,
            lancamentosCreditoPlace,
            loadLancamento,
            tpvRealizado,
            activeIndex,
            selectedInfluecer,



        } = this.state

        const { user } = this.context

        const idUserLogado = user.data.consultorLogado.consultorId


        return (
            <div style={{ margin: '20px 20px 20px 20px' }}>

                <div style={{ float: 'right' }}>
                    <Button basic color='black' onClick={() => this.logout()}>
                        Sair
                    </Button>
                </div>



                {/* Verificadores Acesso Button */}
                {
                    userAuthCriacao ? <div>

                        <Button basic color='blue' >
                            <Link to="/calendario" style={{ color: 'black' }}>Minha Agenda</Link>
                        </Button>
                        <Button basic color='blue' >
                            <Link to="/cadastro/acaoes" style={{ color: 'black' }}>Novo Plano de Ação</Link>
                        </Button>

                        <Button basic color='blue' >
                            <Link to="/cadastro/influencers" style={{ color: 'black' }}>Novo Influencer</Link>
                        </Button>

                        <Button basic color='blue' >
                            <Link to="/cadastro/fornecedor" style={{ color: 'black' }}>Novo Fornecedor</Link>
                        </Button>

                        <Button basic color='blue' >
                            <Link to="/lead/view" style={{ color: 'black' }}>Lead</Link>
                        </Button>

                        {
                            idUserLogado === 2 || idUserLogado === 104 || idUserLogado === 71 || idUserLogado == 62 || idUserLogado === 39 || idUserLogado == 196 || idUserLogado == 198 || idUserLogado == 220 ?


                                <Button basic color='blue' >
                                    <Link to="/novoatacarejo/view" style={{ color: 'black' }}>Novo Atacarejo</Link>
                                </Button> : ''
                        }




                    </div> : ''
                }

                {
                    userAuthCriacao === false ? <div>
                        <Button basic color='blue' >
                            <Link to="/calendario" style={{ color: 'black' }}>Minha Agenda</Link>
                        </Button>
                        <Button basic color='blue' >
                            <Link to="/lead/view" style={{ color: 'black' }}>Lead</Link>
                        </Button>

                        <Button basic color='blue' onClick={() => this.getAllPlanoAcaoByConsultorId()} >
                            <Link style={{ color: 'black' }}>Minhas Ações</Link>
                        </Button>
                    </div> : ''
                }





                {/* Form Filtros */}
                <div style={{ marginTop: '30px' }}>

                    <Form >

                        <Form.Group widths='equal'>
                            <Form.Field
                                control={Select}
                                label='Cidade'
                                options={listaMunicipios}
                                loading={loadingSelectMunicipio}
                                search
                                placeholder='Cidade'
                                value={municipioId}
                                // value={municipioId}
                                onChange={(event, data) => this.setState({ municipioId: data.value }, () => this.getAllPlanoAcaoByMunicipio(data.value), this.getTPV(data.value))}
                            />
                            <Form.Field
                                control={Select}
                                label='Status'
                                options={optionsStatusAcoes}
                                search
                                placeholder='Status'
                                value={status}
                                onChange={(event, data) => this.filterStatusAcoes(data.value)}
                            />

                            <Form.Field
                                control={Select}
                                label='Tipo ação'
                                options={listaTipoAcao}
                                search
                                placeholder='Tipo ação'
                                value={tipoAcao}
                                onChange={(event, data) => this.filterTipoAcao(data.value)}
                            />


                            <Form.Input label='Mês/Ano'>
                                <SemanticDatepicker locale="pt-BR" type="basic" format='MM/YYYY' onChange={(event, data) => this.filterMesAno(data.value)} />
                            </Form.Input>

                            <Form.Field width='2' label='Atualizar' basic color='black' control={Button} onClick={() => this.getAllPlanoAcaoByMunicipio(municipioId)} >
                                <Icon name='sync'></Icon>
                            </Form.Field>

                        </Form.Group>

                    </Form>
                </div>

                <hr />

                <div>

                    {
                        tpvProgress > 0 ?
                            <div>
                                <h3 style={{ textAlign: 'center' }}>Atingimento da Meta 🚀</h3>
                                <Progress percent={tpvProgress} size='medium' progress color='purple' active />
                            </div> : loadgindTpvProgress ? <h3 style={{ textAlign: 'center' }}> Carregando atingimento da meta .....</h3> : ''




                    }



                </div>

                {/* Div Quadro Resumo Acoes */}
                <div style={{ marginTop: '10px' }} className='box'>

                    {/* Div QuadroResumo */}
                    <div style={{ marginRight: '30px' }}>
                        {
                            quadroResumo.length > 0 ? <Table collapsing basic='very' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Status</Table.HeaderCell>
                                        <Table.HeaderCell>QTD</Table.HeaderCell>
                                        <Table.HeaderCell>Valor</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {

                                        _.orderBy(quadroResumo, ['count'], ['desc']).map((qtdAcoes, index) =>
                                            <Table.Row key={index}>
                                                <Table.Cell>{
                                                    qtdAcoes.status === 'EM_ANDAMENTO' ?
                                                        <span style={{ backgroundColor: '#FFFF00' }}> {qtdAcoes.status}</span>
                                                        : qtdAcoes.status === 'NAO_INICIADA' ?
                                                            <span style={{ backgroundColor: '#DCDCDC' }}> {qtdAcoes.status}</span>
                                                            : qtdAcoes.status === 'CONCLUIDO' ? <span style={{ backgroundColor: '#008000' }}>{qtdAcoes.status}</span>
                                                                : qtdAcoes.status === 'ATRASADO' ? <span style={{ backgroundColor: '#FF0000' }}>{qtdAcoes.status}</span>
                                                                    : qtdAcoes.status === 'CANCELADO' ? <span style={{ backgroundColor: '#778899' }}>{qtdAcoes.status}</span> : ''

                                                }</Table.Cell>
                                                <Table.Cell><span> {qtdAcoes.count}</span></Table.Cell>
                                                <Table.Cell><span>R$  {numeral(qtdAcoes.quantoCusta).format('0,0')}</span></Table.Cell>
                                            </Table.Row>)



                                    }

                                </Table.Body>
                            </Table> : ''
                        }

                    </div>

                    {/* Div Resumo Total de Investimento */}

                    <div style={{ width: '40%' }}>

                        {
                            quadroResumo.length > 0 ?
                                <div style={{ textAlign: 'justify', marginTop: '15px' }}>
                                    <h3>Quantidade de Ações: {_.sumBy(quadroResumo, 'count')} </h3>
                                    <h3>Total De Investimento (Previsto): R$ {numeral(_.sumBy(quadroResumo, 'quantoCusta')).format('0,0')} </h3>

                                    <h3>Total Investimento (Realizado): R$ {numeral(_.sumBy(quadroResumo, 'valorRealizado')).format('0,0')} </h3>

                                    <h3>
                                        Valor Disponível: R$ {(numeral(_.sumBy(quadroResumo, 'quantoCusta') - _.sumBy(quadroResumo, 'valorRealizado')).format('0,0'))}
                                    </h3>
                                </div>
                                : ''

                        }


                    </div>

                    {/* Div Metas By Cidade */}

                    <div>

                        <div style={{ marginRight: '30px' }}>
                            {
                                metaCidade.length > 0 ? <div>

                                    <Table collapsing basic='very' selectable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='6'><h3>Indicadores</h3></Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>

                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Meta TPV
                                                </Table.Cell>
                                                <Table.Cell textAlign='right'>R$ {numeral(metaCidade[0].meta).format('0,0')}</Table.Cell>


                                            </Table.Row>

                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    TPV Realizado
                                                </Table.Cell>
                                                <Table.Cell textAlign='right'>R$ {tpvRealizado > 0 ? numeral(tpvRealizado).format('0,0') : ''}</Table.Cell>


                                            </Table.Row>





                                            {/* <Table.Row>
                                                <Table.Cell collapsing>
                                                    Places Ativos
                                                </Table.Cell>
                                                <Table.Cell textAlign='right'>

                                                    <Progress percent={((metaCidade[0].totalPlacesAtivos / metaCidade[0].metaPlacesAtivos) * 100).toFixed(2)} size='small' progress color='purple' active />

                                                    <span>
                                                        Meta (+) : {metaCidade[0].metaPlacesAtivos}
                                                    </span> <br/>

                                                    <span>
                                                        Ativar (+) : {metaCidade[0].metaPlacesAtivos - metaCidade[0].totalPlacesAtivos}
                                                    </span>


                                                </Table.Cell>


                                            </Table.Row>

                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Pedidos Não Entregues
                                                </Table.Cell>
                                                <Table.Cell textAlign='right'>{(metaCidade[0].metaPedidosNaoEntregues * 100).toFixed(1)}%</Table.Cell>

                                            </Table.Row>

                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Parceiros Pag Online
                                                </Table.Cell>
                                                <Table.Cell textAlign='right'>{(metaCidade[0].metaGateway * 100).toFixed(1)}%</Table.Cell>

                                            </Table.Row> */}

                                        </Table.Body>
                                    </Table>

                                    {/* <Accordion fluid styled>


                                        <Accordion.Title
                                            active={activeIndex === 1}
                                            index={1}
                                            onClick={this.handleClick}
                                        >
                                            <Icon name='dropdown' />
                                            Mais Indicadpres
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 1}>
                                            <p>
                                                There are many breeds of dogs. Each breed varies in size and
                                                temperament. Owners often select a breed of dog that they find to be
                                                compatible with their own lifestyle and desires from a companion.
                                            </p>
                                        </Accordion.Content>


                                    </Accordion> */}

                                </div> : ''
                            }

                        </div>

                    </div>



                </div>



                <div style={{ marginTop: '30px' }}>


                    <Loader active={loadingTable} size='big'>Loading</Loader>


                    {
                        listaPlanoAcoes.length > 0 ? <Table celled selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Id</Table.HeaderCell>
                                    <Table.HeaderCell>Criado Por ?</Table.HeaderCell>
                                    <Table.HeaderCell>Execução</Table.HeaderCell>
                                    <Table.HeaderCell>Ação</Table.HeaderCell>
                                    {/* <Table.HeaderCell>Finalidade</Table.HeaderCell> */}
                                    <Table.HeaderCell>Previsto</Table.HeaderCell>
                                    <Table.HeaderCell>Realizado</Table.HeaderCell>
                                    {/* <Table.HeaderCell>Data Criaçao</Table.HeaderCell> */}
                                    <Table.HeaderCell>Inicio</Table.HeaderCell>
                                    <Table.HeaderCell>Fim</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                    <Table.HeaderCell>Ação</Table.HeaderCell>
                                    {/* {userAuthCriacao === true ? <Table.HeaderCell>Duplicar</Table.HeaderCell> : ''} */}

                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    listaPlanoAcoes.length > 0 ? _.sortBy(listaPlanoAcoes, ['status', 'prazoInicio']).map((acao, index) =>

                                        <Table.Row key={index}>
                                            <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.acaoModal(acao)}>{acao.planoAcaoId}</Table.Cell>
                                            <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.acaoModal(acao)}>{acao.nomeCriador.toUpperCase()} - ({acao.timeCriador})</Table.Cell>
                                            <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.acaoModal(acao)}>{acao.nomeExecutor.toUpperCase()} - ({acao.timeExecutor})</Table.Cell>
                                            <Table.Cell style={{ fontSize: '14px' }} onClick={() => this.acaoModal(acao)}>{acao.acaoTipo}</Table.Cell>
                                            <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.acaoModal(acao)}>R$ {numeral(acao.quantoCusta).format('0,0')}</Table.Cell>
                                            <Table.Cell
                                                onClick={() => this.updateValorRealizado(acao.planoAcaoId, acao)}
                                                style={{ fontSize: '12px' }}>R$ {numeral(acao.valorRealizado).format('0,0')}



                                            </Table.Cell>
                                            {/* <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.acaoModal(acao)}>{moment(acao.createdAt).format('L')}</Table.Cell> */}
                                            <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.acaoModal(acao)}>{moment(acao.prazoInicio).format('DD/MM/YYYY')}</Table.Cell>
                                            <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.acaoModal(acao)}>{moment(acao.prazoFim).format('DD/MM/YYYY')}</Table.Cell>
                                            <Table.Cell >
                                                {
                                                    acao.status === 'NAO_INICIADA' ?
                                                        <div>
                                                            <span style={{ backgroundColor: '#DCDCDC', fontSize: '12px' }}>{acao.status} </span>

                                                        </div>
                                                        : acao.status === 'CONCLUIDO' ? <span style={{ backgroundColor: '#008000', fontSize: '12px' }}>{acao.status}</span>
                                                            : acao.status === 'CANCELADO' ? <span style={{ backgroundColor: '#778899', fontSize: '12px' }}>{acao.status}</span> : acao.status === 'EM_ANDAMENTO' ?
                                                                <div >
                                                                    <span style={{ backgroundColor: '#FFFF00', fontSize: '12px' }}>{acao.status}</span>


                                                                </div> : acao.status === 'ATRASADO' ?
                                                                    <div>
                                                                        <span style={{ backgroundColor: '#FF0000', fontSize: '12px' }}>{acao.status}</span>


                                                                    </div> : <span>{acao.status}</span>
                                                }</Table.Cell>
                                            <Table.Cell >
                                                {
                                                    acao.status === 'NAO_INICIADA' ?
                                                        <div>
                                                            <span style={{ backgroundColor: '#DCDCDC', fontSize: '10px' }}> </span>
                                                            <Button size='mini' color='green' onClick={() => this.updateStatusPlanoAcao(acao.planoAcaoId, 'EM_ANDAMENTO', acao.municipioId, acao.comentario, acao)}><Icon name='play' /></Button>

                                                            <Button size='mini' color='grey' onClick={() => this.setState({ openModalMotivoCancelamento: true, acaoIdMotivoCancelamento: acao.planoAcaoId })}><Icon name='cancel' /></Button>


                                                        </div>
                                                        : acao.status === 'CONCLUIDO' ? <span style={{ backgroundColor: '#008000', fontSize: '10px' }}></span>
                                                            : acao.status === 'CANCELADO' ? <span style={{ backgroundColor: '#778899', fontSize: '10px' }}></span> : acao.status === 'EM_ANDAMENTO' ?
                                                                <div >
                                                                    <span style={{ backgroundColor: '#FFFF00', fontSize: '10px' }}></span>
                                                                    <Button size='mini' color='blue' onClick={() => this.updateStatusPlanoAcao(acao.planoAcaoId, 'CONCLUIDO', acao.municipioId, acao.comentario, acao)}><Icon name='check' /></Button>
                                                                    <Button size='mini' color='grey' onClick={() => this.setState({ openModalMotivoCancelamento: true, acaoIdMotivoCancelamento: acao.planoAcaoId })}><Icon name='cancel' /></Button>

                                                                </div> : acao.status === 'ATRASADO' ?
                                                                    <div>
                                                                        <span style={{ backgroundColor: '#FF0000' }}></span>
                                                                        <Button size='mini' color='blue' onClick={() => this.updateStatusPlanoAcao(acao.planoAcaoId, 'CONCLUIDO', acao.municipioId, acao.comentario, acao)}><Icon name='check' /></Button>
                                                                        <Button size='mini' color='grey' onClick={() => this.setState({ openModalMotivoCancelamento: true, acaoIdMotivoCancelamento: acao.planoAcaoId })}><Icon name='cancel' /></Button>


                                                                    </div> : <span>{acao.status}</span>
                                                }</Table.Cell>

                                            {userAuthCriacao === true ? <Table.Cell>
                                                <Button size='mini' color='#DCDCDC' onClick={() => this.duplicarAcao(acao)}><Icon name='copy' /></Button>
                                            </Table.Cell> : ''}


                                        </Table.Row>



                                    ) : ''
                                }


                            </Table.Body>
                        </Table> : <h3 style={{ textAlign: 'center' }}>Cidade Sem Plano de Ação</h3>
                    }

                </div>


                {/* Modal Edicao de Acao */}

                <div  >

                    <Modal
                        open={modalEdicao}
                        size='large'
                        onClose={() => this.closeModalAcao()} >

                        <div>
                            <Form size='small' style={{ margin: '20px 20px 20px 20px' }}>
                                <Form.Group widths='equal' >

                                    <Form.Field
                                        control={Input}
                                        label='id'
                                        value={acao.planoAcaoId}

                                    />
                                    <Form.Field
                                        disabled
                                        control={Input}
                                        label='Cidade'
                                        value={acao.municipio}

                                    />
                                    <Form.Field
                                        style={{ color: 'black' }}
                                        disabled
                                        control={Input}
                                        label='Responsável Pelo Plano'
                                        value={`${acao.nomeCriador} - (${acao.timeCriador})`}

                                    />
                                    <Form.Field

                                        disabled
                                        control={Input}
                                        label='Ação'
                                        value={acao.acaoTipo}

                                    />


                                </Form.Group>

                                <Form.Group>
                                    {
                                        acaoComInfluencer ? <Form.Field

                                            style={{ borderRadius: '20px', backgroundColor: '#008000' }}
                                            control={Button}
                                            label='Ação Com Influencers ?'
                                            onClick={() => this.setState({ acaoComInfluencer: false, influencerId: '' })}

                                        /> : <Form.Field

                                            style={{ borderRadius: '20px' }}
                                            control={Button}
                                            label='Ação Com Influencers ?'
                                            onClick={() => this.setState({ acaoComInfluencer: true }, this.getAllInfluencerByMunicipio(municipioId))}

                                        />
                                    }



                                    {
                                        buttonAcaoParceiro ? <Form.Field
                                            style={{ borderRadius: '20px', backgroundColor: '#008000' }}
                                            control={Button}
                                            label='Ação Com Parceiro ?'
                                            onClick={() => this.setState({ buttonAcaoParceiro: false })}
                                        /> :
                                            <Form.Field
                                                style={{ borderRadius: '20px', }}
                                                control={Button}
                                                label='Ação Com Parceiro ?'
                                                onClick={() => this.setState({ buttonAcaoParceiro: true })}
                                            />
                                    }

                                    {
                                        buttonAcaoParceiro && !isCreditoFaturaPlace ?

                                            <Form.Field
                                                disabled={desibleEdicao}
                                                style={{ borderRadius: '20px', margiLeft: '10px' }}
                                                control={Button}
                                                label='Crédito Em Fatura ?'
                                                onClick={() => this.setState({ isCreditoFaturaPlace: true, dataPagamentoAcao: '' })}


                                            /> : buttonAcaoParceiro && isCreditoFaturaPlace ?

                                                <Form.Field
                                                    disabled={desibleEdicao}
                                                    style={{ borderRadius: '20px', backgroundColor: '#008000', margiLeft: '10px' }}
                                                    control={Button}
                                                    label='Crédito Em Fatura ?'
                                                    onClick={() => this.setState({ isCreditoFaturaPlace: false })}
                                                /> : ''
                                    }

                                    {
                                        acaoVaucher ? <div style={{ marginLeft: '30px' }}> <ModalVoucher municipioId={municipioId} listaMunicipio={listaMunicipios} listaInfluencers={listaInfluencers} listaPlace={listaPlace} /></div> : ''
                                    }
                                </Form.Group>



                                <Form.Group widths='equal'>


                                    {
                                        buttonAcaoParceiro ?

                                            <Form.Field
                                                required
                                                search
                                                control={Select}
                                                label='Places'
                                                fluid multiple selection
                                                defaultValue={this.state.selectedPlaces}
                                                options={listaPlace}
                                                onChange={(event, data) => this.setState({ selectedPlaces: data.value })}
                                            />

                                            : ''


                                    }



                                    {

                                        loadLancamento ? <div style={{ width: '50%' }}>
                                            <Segment inverted>
                                                <Loader active inverted />
                                            </Segment>
                                            {/* <Loader size='big'>Loading</Loader> */}
                                        </div> :

                                            (buttonAcaoParceiro === true) && (this.state.selectedPlaces.length > 0) ?
                                                <Table celled selectable>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell>placeId</Table.HeaderCell>
                                                            <Table.HeaderCell>Parceiro</Table.HeaderCell>
                                                            <Table.HeaderCell>Valor do Crédito</Table.HeaderCell>
                                                            <Table.HeaderCell>Valor lançado: {_.sumBy(lancamentosCreditoPlace, 'valorCredito')}</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>



                                                    <Table.Body>

                                                        {
                                                            (listaPlace.length > 0) && (selectedPlaces.length > 0) ? selectedPlaces.map((place, index) =>

                                                                <Table.Row key={index}>
                                                                    <Table.Cell >{(lancamentosCreditoPlace.length > 0) && (_.find(lancamentosCreditoPlace, { placeId: place }) !== undefined) ? _.find(lancamentosCreditoPlace, { placeId: place }).placeId : ''}</Table.Cell>
                                                                    <Table.Cell >{(lancamentosCreditoPlace.length > 0) && (_.find(lancamentosCreditoPlace, { placeId: place }) !== undefined) ? _.find(lancamentosCreditoPlace, { placeId: place }).placeNomeExibicao : ''}</Table.Cell>
                                                                    <Table.Cell> <Form.Field
                                                                        control={Input}
                                                                        type='Number'
                                                                        defaultValue={(lancamentosCreditoPlace.length > 0) && (_.find(lancamentosCreditoPlace, { placeId: place }) !== undefined) ? _.find(lancamentosCreditoPlace, { placeId: place }).valorCredito : ''}
                                                                        onChange={(event, data) => this.setState({ valorCreditoParceiro: data.value })}
                                                                    /></Table.Cell>

                                                                    <Table.Cell>


                                                                        {

                                                                            (lancamentosCreditoPlace.length === selectedPlaces.length) ? <Button color='green' onClick={() => this.salvarCreditoParceiro(acao.planoAcaoId, place, acao, selectedPlaces)} >Salvar</Button> : ''
                                                                        }



                                                                    </Table.Cell>


                                                                </Table.Row>) : ''



                                                        }


                                                    </Table.Body>
                                                </Table>
                                                : ''






                                    }



                                    {
                                        acaoComFornecedor ?
                                            <Form.Field
                                                style={{ fontSize: '11px' }}
                                                required
                                                search
                                                control={Select}
                                                options={listaFornecedores}
                                                label='Fornecedor'
                                                value={fornecedorId}
                                                onChange={(event, data) => this.setState({ fornecedorId: data.value })}
                                            />

                                            : ''


                                    }
                                </Form.Group>

                                <Form.Group widths='equal'>


                                    {
                                        acaoComInfluencer ?

                                            <Form.Field
                                                required
                                                search
                                                control={Select}
                                                label='Influencer'
                                                fluid multiple selection
                                                defaultValue={this.state.selectedInfluecer}
                                                options={listaInfluencers}
                                                onChange={(event, data) => this.setState({ selectedInfluecer: data.value })}

                                            />

                                            : ''


                                    }


                                </Form.Group>

                                <Form.Group widths='equal'>

                                    <Form.Field
                                        disabled={desibleEdicao}
                                        required
                                        control={Select}
                                        search
                                        label='Participantes'
                                        fluid multiple selection
                                        defaultValue={this.state.selectedParticipantes}
                                        options={listaConsultor}
                                        onChange={(event, data) => this.setState({ selectedParticipantes: data.value })}
                                    />



                                </Form.Group>

                                <Form.Group widths='equal'>

                                    <Form.Field
                                        disabled={desibleEdicao}
                                        control={Input}
                                        label='Finalidade'
                                        defaultValue={acao.causa}
                                        onChange={(event, data) => this.setState({ causa: data.value })}
                                    />
                                    <Form.Field
                                        disabled={desibleEdicao}
                                        control={Select}
                                        label='Responsável Pela Execução'
                                        search
                                        options={listaConsultor}
                                        defaultValue={this.state.selectedConsultorId}
                                        onChange={(event, data) => this.setState({ selectedConsultorId: data.value })}

                                    />

                                    <Form.Field
                                        required
                                        disabled={desibleEdicao}
                                        control={Input}
                                        label='Onde'
                                        placeholder='Ex: Parceiro, Rede Sociais'
                                        defaultValue={acao.onde}
                                        onChange={(event, data) => this.setState({ onde: data.value })}

                                    />



                                </Form.Group>


                                <Form.Group>
                                    <Form.Input label='Data De Criação' >
                                        <SemanticDatepicker disabled value={new Date(acao.createdAt)} type="basic" format='DD/MM/YYYY' />
                                    </Form.Input>
                                    <Form.Input label='Prazo Inicio' >
                                        <SemanticDatepicker disabled={desibleEdicao} value={new Date(acao.prazoInicio)} required type="basic" format='DD/MM/YYYY' onChange={(event, data) => this.setState({ prazoInicio: data.value })} />
                                    </Form.Input>
                                    <Form.Input label='Prazo Fim' >

                                        <SemanticDatepicker disabled={desibleEdicao} value={new Date(acao.prazoFim)} locale="pt-BR" type="basic" format='DD/MM/YYYY' onChange={(event, data) => this.setState({ prazoFim: data.value })} />
                                    </Form.Input>

                                </Form.Group>


                                {
                                    desibleEdicao ? <Form.Field
                                        style={{ minHeight: 120 }}
                                        control={TextArea}
                                        label='Como'
                                        placeholder='Como fará ações'
                                        defaultValue={acao.como}
                                        onClick={() => toast.warning('Campo não editável. Qualquer modificação, não será editada.')}

                                    /> :
                                        <Form.Field
                                            style={{ minHeight: 120 }}
                                            required
                                            control={TextArea}
                                            label='Como'
                                            placeholder='Como fará ações'
                                            defaultValue={acao.como}
                                            onChange={(event, data) => this.setState({ como: data.value })}
                                        />
                                }
                                {
                                    acao.comentario === 'null' ? <Form.Field
                                        control={TextArea}
                                        style={{ minHeight: 150 }}
                                        label='Comentário'
                                        placeholder='Deixe Seu Comentário'
                                        onChange={(event, data) => this.setState({ comentario: data.value })}

                                    /> : <Form.Field
                                        control={TextArea}
                                        style={{ minHeight: 150 }}
                                        label='Comentário'
                                        defaultValue={acao.comentario}
                                        onChange={(event, data) => this.setState({ comentario: data.value })}

                                    />
                                }

                                <Form.Group widths='equal'>
                                    <Form.Field
                                        control={Input}
                                        label='Observações'
                                        defaultValue={acao.observacao}
                                        onChange={(event, data) => this.setState({ observacao: data.value })}

                                    />
                                </Form.Group>

                                <Form.Group >

                                    <Form.Field
                                        disabled={desibleEdicao}
                                        required
                                        control={Input}
                                        type='Number'
                                        width={3}
                                        label='Quanto Custa ?'
                                        defaultValue={acao.quantoCusta}
                                        onChange={(event, data) => this.setState({ quantoCusta: data.value })}

                                    />



                                    {
                                        (quantoCusta > 0 || acao.quantoCusta > 0) && isCreditoFaturaPlace !== true ?
                                            <Form.Input label='Data Pagamento' disabled={desibleEdicao}>
                                                <SemanticDatepicker pointing='top right' value={new Date(acao.dataPagamentoAcao ? acao.dataPagamentoAcao : new Date(acao.prazoFim))} locale="pt-BR" type="basic" format='DD/MM/YYYY' onChange={(event, data) => this.setState({ dataPagamentoAcao: data.value })} />
                                            </Form.Input> : ''
                                    }


                                </Form.Group>

                            </Form>

                        </div>
                        <Modal.Actions>
                            <Button color='red' onClick={() => this.closeModalAcao()} >Cancelar</Button>
                            <Button loading={loadingButtonEditarPlanoAcao} onClick={() => this.updatePlanoAcao(acao)}>Salvar</Button>

                        </Modal.Actions>

                    </Modal>

                </div>


                <div>
                    <Modal

                        onClose={() => this.setState({ openModalMotivoCancelamento: false })}
                        open={openModalMotivoCancelamento}


                    >
                        <Modal.Header>Motivo do Cancalemanto</Modal.Header>
                        <div style={{ margin: '10px 10px 10px 10px' }}>

                            <Form>
                                <Form.Group widths='equal' >
                                    <Form.Field
                                        required
                                        control={Input}
                                        placeholder='Qual motivo do cancelamento ?'
                                        value={motivoCancelamentoAcao}
                                        onChange={(event, data) => this.setState({ motivoCancelamentoAcao: data.value })}

                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({ openModalMotivoCancelamento: false, motivoCancelamentoAcao: '', acaoIdMotivoCancelamento: null })}>Cancelar</Button>
                            <Button onClick={() => this.salvarMotivoCancelamento()} positive>
                                Salvar
                            </Button>
                        </Modal.Actions>

                    </Modal>

                </div>

                <div style={{ margin: '20px 20px 20px 20px' }}>
                    <Modal
                        style={{ margin: '10px 10px 10px 10px', width: '30%' }}
                        // onClose={() => this.setState({ modalValorRealizado: false })}
                        open={modalValorRealizado}

                    >
                        <h3 style={{ margin: '20px 20px 20px 20px' }}>Qual valor realizado na ação?</h3>

                        <Form style={{ margin: '20px 20px 20px 20px' }}>
                            <Form.Group widths='equal'>

                                <Form.Field
                                    //disabled={desibleEdicao}
                                    required
                                    control={Input}
                                    type='Number'
                                    width={3}
                                    // label='Valor Realizado'
                                    defaultValue={objAcaoFormalizarValorAcao.valorRealizado}
                                    onChange={(event, data) => this.setState({ valorRealizado: data.value })}

                                />

                            </Form.Group>

                            <Modal.Actions>

                                <Button onClick={() => this.setState({ modalValorRealizado: false, valorRealizado: 0 })} >
                                    Cancelar
                                </Button>

                                <Button onClick={() => this.salvarValorRealizado()} positive>
                                    Salvar
                                </Button>


                            </Modal.Actions>

                        </Form>
                    </Modal>
                </div>
            </div >
        )
    }



}


export default PlanoAcao

import React from 'react'

import './App.css';


import FormPlanoAcao from "./formulario/formularioPlanoAcao";



import Inicio from "../src/componentes/inicio";



import { BrowserRouter, Switch, Route } from "react-router-dom";

import { AuthProvider } from "../src/context/authContext";

import numeral from 'numeral';
import 'numeral/locales';







import Login from "../src/componentes/login";
import CadastroInfluencer from "../src/componentes/cadastroInfluencer";
import AgendaAcoes from "../src/componentes/agendaAcoes";
import Pedido from "../src/componentes/pedido/pedido";
import Lead from '../src/componentes/lead'
import NovoAtacarejo from './componentes/viewNovoAtacarejo';
import ViewTimeInterno from './componentes/viewTimeInterno';





import CadastroFornecedor from '../src/componentes/formCadastroFornecedor';


import moment from 'moment'
moment.locale('pt-br')
// import 'moment/locale/pt-br'

// //moment.tz.setDefault("America/Bahia");

// moment.locale('pt-br');


numeral.locale('pt-br');







class App extends React.Component {


  render() {


    return (

      <AuthProvider>
        <BrowserRouter>
          <Switch>

            <Route path="/" component={Login} exact />
            <Route path="/plano" component={Inicio} />
            <Route path="/plano/:municipioId/:prazoInicio" component={Inicio} />
            <Route path="/cadastro/acaoes" component={FormPlanoAcao} />
            <Route path="/cadastro/influencers" component={CadastroInfluencer} />
            <Route path="/cadastro/fornecedor" component={CadastroFornecedor} />
            <Route path="/calendario" component={AgendaAcoes} />
            <Route path="/pedido" component={Pedido} />
            <Route path="/lead/view" component={Lead} />
            <Route path="/novoatacarejo/view" component={NovoAtacarejo} />
            {/* <Route path="/plan/view" component={ViewTimeInterno} /> */}

          </Switch>
        </BrowserRouter>
      </AuthProvider>

    )


  }
}


// function App() {



//   return ( <BrowserRouter>
//     <Switch>

//       <Route path="/" component={Login} exact />
//       <Route path="/plano/:municipioId" component={Inicio} />
//       <Route path="/cadastro/acaoes" component={FormPlanoAcao} />

//     </Switch>
//   </BrowserRouter>


// )


//}

export default App;

//export { UserContext }


//className="App-header"
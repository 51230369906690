
import React from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'

import { toast } from 'react-toastify';
import {
    API_URL
} from './constants'

let networkErrorToastId

export const handleResponseSuccess = function (response) {
    if (networkErrorToastId) {
        toast.dismiss(networkErrorToastId)
        networkErrorToastId = null;


    }

    // if (response.headers[HEADER_TOTAL_COUNT])
    //     return {
    //         data: response.data,
    //         totalCount: parseInt(response.headers[HEADER_TOTAL_COUNT])
    //     }

    return response.data;
}
export const handleResponseError = async (error) => {
    console.log(error.message)

    if (error.response) {
        const { response: { status, data } } = error
        switch (status) {
            case 400:
                const { errors } = data
                const msg = (
                    <div>
                        <strong>[ERR400]:</strong>
                        <div>
                            {
                                errors
                                    .map(error => error.messages.join(' - '))
                                    .map((error, i) => <div key={i}>{error}</div>)
                            }
                        </div>
                    </div>
                )
                toast.warn(msg)
                break;
            case 401:

                console.log('401')
                toast.warn(`[403] Sem permissão`)
                break;
            case 403:
                toast.warn(`[403] Sem permissão`)
                console.log('403')

                break;
            case 503:
                toast.info("Sistema em manutenção, tente novamente em instantes", { autoClose: false })
                break;
            case 500:
                toast.warn(`[500] Servidor Fora`)

                break;
                default:
                    console.log(`OK`);
        }

    } else {
        if (error.message === 'Network Error') {
            if (networkErrorToastId) {
                toast.update(networkErrorToastId)

            } else {
                networkErrorToastId = toast('📡❗️ Sem conexão com o servidor', { autoClose: false });
            }
        }
    }


    return Promise.reject(error);
}

let AxiosApi = axios
AxiosApi.defaults.baseURL = API_URL;

AxiosApi.interceptors.response.use(handleResponseSuccess, handleResponseError)




export const setBearerToken = () => {

    const token = Cookies.get('qd-token')
    AxiosApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;

}

export default AxiosApi;
import React, { Component } from 'react';

import { Form, Input, Select, TextArea, Table, Button, Radio, Message, Loader, Dropdown, Checkbox, Modal } from 'semantic-ui-react'


import VMask from 'vanilla-masker'


import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import moment, { relativeTimeThreshold } from "moment";

import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import Requests from '../shared/Requests'

import Cookies from 'js-cookie'




import AuthContext from "../context/authContext";


import _, { parseInt, stubString } from 'lodash'


import '../App.css';

import { validateCpf, validateCnpj } from "../helpers/FnUtil";



const status = {
    naoIniciada: 'NAO_INICIADA',
    cancelada: 'CANCELADO',
    emAndamento: 'EM_ANDAMENTO',
    concluida: 'CONCLUIDO',
    atrasado: 'ATRASADO'
}






class PlanoAcao extends Component {


    static contextType = AuthContext


    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.telaInicial = this.telaInicial.bind(this);
    }




    state = {
        municipios: [],
        listaConsultor: [],
        acoes: [],
        participantes: [],
        listaPlanoAcoes: [],
        listaFinalidadeAcoes: [],
        listaTemporariaAcoes: [],
        listaPlace: [],
        places: [],
        listaInfluencers: [],
        influencerId: [],
        listaFornecedores: [],

        municipioId: '',
        acaoId: '',
        consultorCriadorId: '',
        consultorExecucaoId: '',

        causa: '',

        onde: '',
        prazoInicio: '',
        como: '',
        quantoCusta: 0,
        observacao: '',
        prazoFim: '',
        dataPagamentoAcao: '',
        loadingButtonCadastrarAcoes: false,
        loadingButtonSalvarAcoes: false,
        loadingTable: true,
        buttonAcaoParceiro: false,



        isCreditoFaturaPlace: false,
        comentario: '',
        acaoComInfluencer: false,
        acaoComFornecedor: false,
        fornecedorId: '',

        userLogado: {},
        openModal: false,


    };






    getFinalidadeAcao = (acaoId) => {
        try {
            Requests
                .getAllAcoes()
                .then(response => {

                    const finalidade = _.find(response, { acaoId: acaoId })

                    this.setState({ causa: finalidade.finalidade })
                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }
    }


    getAllFornecedor = () => {

        this.setState({ loadingTable: true, loadingSelectMunicipio: true })
        try {

            Requests
                .getAllFornecedor()
                .then(response => {

                    if (response.r) {

                        const listForncedor = []

                        const listaFornecedor = response.data

                        for (let i = 0; i < listaFornecedor.length; i++) {
                            const fornecedor = listaFornecedor[i];

                            const obj = {
                                key: fornecedor.fornecedorId,
                                text: `${fornecedor.nome}`,
                                value: fornecedor.fornecedorId,

                            }

                            listForncedor.push(obj)



                        }

                        this.setState({ listaFornecedores: listForncedor })



                    }
                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {

            console.log(error)

        }



    }


    getAllAcoes = () => {

        const listaAcoes = []

        try {
            Requests
                .getAllAcoes()
                .then(response => {

                    const lista = response



                    for (let i = 0; i < lista.length; i++) {
                        const acao = lista[i];
                        const obj = {
                            key: acao.acaoId,
                            text: `${acao.tipo}`,
                            value: acao.acaoId,

                        }

                        listaAcoes.push(obj)

                    }

                    this.setState({ acoes: listaAcoes })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }

    }


    getAllPlaceByMunicipio = (municipioId) => {

        const listPlace = []

        try {
            Requests
                .getAllPlacesByMunicipio(municipioId)
                .then(response => {

                    const lista = response.data

                    for (let i = 0; i < lista.length; i++) {
                        const place = lista[i];
                        const obj = {
                            key: place.placeId,
                            text: `${place.nomeExibicao}`,
                            value: place.placeId

                        }

                        listPlace.push(obj)

                    }

                    this.setState({ listaPlace: listPlace })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }

    }



    getAllConsultor = () => {



        const listConsultor = []

        try {
            Requests
                .getAllConsultor()
                .then(response => {

                    const lista = response



                    for (let i = 0; i < lista.length; i++) {
                        const consultor = lista[i];
                        const obj = {
                            key: consultor.consultorId,
                            text: `${consultor.nome} - (${consultor.time})`.toUpperCase(),
                            value: consultor.consultorId

                        }

                        listConsultor.push(obj)

                    }

                    this.setState({ listaConsultor: listConsultor })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }

    }

    getAllMunicipios = async () => {

        const listaMunicipios = []



        try {
            Requests
                .getAllMunicipios()
                .then(response => {

                    const lista = response.data

                    for (let i = 0; i < lista.length; i++) {
                        const municipio = lista[i];
                        const obj = {
                            key: municipio.municipioId,
                            text: `${municipio.nome}/${municipio.uf}`,
                            value: municipio.municipioId,

                        }



                        listaMunicipios.push(obj)

                    }



                    this.setState({ municipios: listaMunicipios })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }




    }

    telaInicial() {
        this.props.history.push(`/plano`)

    }


    goBack(prazoInicio) {
        const { municipioId } = this.state
        
        this.props.history.push(`/plano/${municipioId}/${prazoInicio}`)
        // this.props.history.goBack();
    }




    salvarAcao = () => {


        const { listaPlanoAcoes, listaConsultor, acoes, municipioId, acaoId, consultorCriadorId, participantes, causa, consultorExecucaoId, onde, prazoInicio, como, quantoCusta, observacao, prazoFim, userLogado, places, dataPagamentoAcao,


            isCreditoFaturaPlace,
            comentario,
            influencerId,
            fornecedorId


        } = this.state

        this.setState({ loadingButtonSalvarAcoes: true })





        if (municipioId === '') {
            this.setState({ loadingButtonSalvarAcoes: false })
            return toast.error('Selecionar Cidade')
        }

        if (acaoId === '') {
            this.setState({ loadingButtonSalvarAcoes: false })
            return toast.error('Selecionar Ação')
        }

        if (!userLogado.text.length) {
            this.setState({ loadingButtonSalvarAcoes: false })

            return toast.error('Selecionar Responsável Pelo Plano')

        }
        if (participantes === '') {
            this.setState({ loadingButtonSalvarAcoes: false })
            return toast.error('Informar Participantes')

        }
        if (!consultorExecucaoId) {
            this.setState({ loadingButtonSalvarAcoes: false })
            return toast.error('Informar Quem Vai Executar Ação')

        }

        if (onde === '') {
            this.setState({ loadingButtonSalvarAcoes: false })
            return toast.error('Informar Onde')

        }
        if (como === '') {
            this.setState({ loadingButtonSalvarAcoes: false })
            return toast.error('Como fará Ação')

        }

        if (prazoInicio === '') {
            this.setState({ loadingButtonSalvarAcoes: false })
            return toast.error('Data de Inicio')
        }
        if (prazoFim === '') {
            this.setState({ loadingButtonSalvarAcoes: false })
            return toast.error('Data de Fim')

        }

        if (quantoCusta === '') {
            this.setState({ loadingButtonSalvarAcoes: false })
            return toast.error('Quanto Custa')

        }



        if ((quantoCusta > 0 && dataPagamentoAcao == '') && !isCreditoFaturaPlace) {
            this.setState({ loadingButtonSalvarAcoes: false })
            return toast.error('Informar Data de Pagamento Da Ação')

        }



        if ((moment(prazoInicio).format('YYYY-MM-DD')) < (moment(new Date()).format('YYYY-MM-DD'))) {
            this.setState({ loadingButtonSalvarAcoes: false })
            return toast.error('Data Inicio tem que ser maior ou igual a tada atual')


        }

        if ((moment(prazoFim).format('YYYY-MM-DD')) < (moment(prazoInicio).format('YYYY-MM-DD'))) {
            this.setState({ loadingButtonSalvarAcoes: false })
            return toast.error('Data de Entrega Menor Que Data de Inicio')

        }

        let lista = listaPlanoAcoes




        const obj = {
            municipioId: municipioId,
            acaoId: parseInt(acaoId),
            consultorCriadorId: parseInt(userLogado.key),
            placeIds: places.length > 0 ? places.toString() : null,
            influencerId: influencerId !== '' ? parseInt(influencerId) : null,
            fornecedorId: fornecedorId !== '' ? parseInt(fornecedorId) : null,
            participantes: participantes.toString(),
            causa: causa,
            consultorExecucaoId: parseInt(consultorExecucaoId),
            onde: onde,
            prazoInicio: moment(prazoInicio).format('YYYY-MM-DD hh:mm'),
            prazoFim: moment(prazoFim).format('YYYY-MM-DD hh:mm'),
            como: como,
            quantoCusta: parseFloat(quantoCusta),
            observacao: observacao,
            creadetAt: moment(new Date()).format('YYYY-MM-DD hh:mm'),
            status: status.naoIniciada,
            dataPagamentoAcao: dataPagamentoAcao ? moment(dataPagamentoAcao).format('YYYY-MM-DD hh:mm') : null,
            isCreditoFaturaPlace: isCreditoFaturaPlace,
            comentario: comentario !== '' ? comentario : null
        }

        lista.push(obj)




        const listAcoesTemp = []

        for (let i = 0; i < listaPlanoAcoes.length; i++) {
            const planoAcao = listaPlanoAcoes[i];

            const acao = _.find(acoes, { key: parseInt(planoAcao.acaoId) })
            const consultor = _.find(listaConsultor, { key: parseInt(planoAcao.consultorExecucaoId) })



            const obj = {
                responsavelPelaCriacao: consultor.text,
                tipoAcao: acao.text,
                municipioId: planoAcao.municipioId,
                acaoId: parseInt(planoAcao.acaoId),
                consultorCriadorId: parseInt(userLogado.key),
                participantes: planoAcao.participantes.toString(),
                causa: planoAcao.causa,
                consultorExecucaoId: parseInt(planoAcao.consultorExecucaoId),
                onde: planoAcao.onde,
                prazoInicio: moment(planoAcao.prazoInicio).format('YYYY-MM-DD hh:mm'),
                prazoFim: moment(planoAcao.prazoFim).format('YYYY-MM-DD hh:mm'),
                como: planoAcao.como,
                quantoCusta: parseFloat(planoAcao.quantoCusta),
                observacao: planoAcao.observacao,
                creadetAt: moment(new Date()).format('YYYY-MM-DD hh:mm'),
                status: planoAcao.status,
                dataPagamentoAcao: dataPagamentoAcao ? moment(dataPagamentoAcao).format('YYYY-MM-DD hh:mm') : null,


            }

            listAcoesTemp.push(obj)

        }




        this.setState({ listaPlanoAcoes: lista, listaTemporariaAcoes: listAcoesTemp, acaoId: '', causa: '', onde: '', como: '', quantoCusta: 0, observacao: '', dataPagamentoAcao: '', loadingButtonSalvarAcoes: false, dataPagamentoAcao: '', influencer: [] })





    }


    cadastrarAcoes = async () => {

        const { user, setUser } = this.context



        const { listaPlanoAcoes, municipioId } = this.state

        const prazoInicio = listaPlanoAcoes[0].prazoInicio

        this.setState({ loadingButton: true })




        if (listaPlanoAcoes.length === 0) {

            this.setState({ loadingButton: false })
            return toast.error('Criar Ações')
        }

        try {
            Requests
                .createAcoes(listaPlanoAcoes)
                .then(response => {

                    if (response.r) {

                        this.setState({ loadingButtonCadastrarAcoes: false, listaPlanoAcoes: [], influencers: [] })

                        this.goBack(prazoInicio)

                        return toast.success('Acoes Cadastradas')

                    }


                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {
            console.log(error)

        }

    }

    handleChange = (e, { value }) => {
        this.setState({ consultorExecucaoId: value })
    }




    handleAddition = (e, { value }) => {
        this.setState((prevState) => ({
            listaConsultor: [{ text: value, value }, ...prevState.listaConsultor],
        }))
    }

    getResponsavelPeloPlanoAcao = () => {
        const { listaConsultor } = this.state

        const cookiesUser = Cookies.get('user')

        try {
            Requests
                .getAllConsultor()
                .then(response => {

                    const lista = response



                    for (let i = 0; i < lista.length; i++) {
                        const colaborador = lista[i];

                        if (cookiesUser === colaborador.email) {

                            const obj = {
                                key: colaborador.consultorId,
                                text: `${colaborador.nome} - (${colaborador.time})`,
                                value: colaborador.consultorId
                            }


                            this.setState({ userLogado: obj })



                        }

                    }

                    // this.setState({ listaConsultor: listaAcoes })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }




    }

    getAllInfluencerByMunicipio = (municipioId) => {

        // this.setState({ loadingTable: true, loadingSelectMunicipio: true })

        let listInfluencers = []
        try {

            Requests
                .getAllInfluencerByMunicipioId(municipioId)
                .then(response => {

                    if (response.r) {

                        const listaInfluencers = response.data


                        for (let i = 0; i < listaInfluencers.length; i++) {
                            const influencer = listaInfluencers[i];

                            const obj = {
                                key: influencer.influencerId,
                                text: `${influencer.contaInstagram}`,
                                value: influencer.influencerId

                            }

                            listInfluencers.push(obj)
                        }

                        this.setState({ listaInfluencers: listInfluencers })


                    }
                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {

            console.log(error)

        }



    }


    componentDidMount() {

        const cookies = Cookies.get('qd-token')


        if (cookies === undefined) window.location.href = `/`


        this.getResponsavelPeloPlanoAcao()


        this.getAllMunicipios()
        this.getAllAcoes()
        this.getAllConsultor()
        this.getAllFornecedor()

    }

    render() {

        const {
            municipios,
            municipioId,
            acaoId,
            acoes,
            consultorCriadorId,
            participantes,
            causa,
            consultorExecucaoId,
            onde,
            prazoInicio,
            como,
            quantoCusta,
            observacao,
            listaPlanoAcoes,
            listaTemporariaAcoes,
            prazoFim,
            loadingButtonSalvarAcoes,
            loadingButtonCadastrarAcoes,
            loadingTable,
            listaConsultor,
            userLogado,
            buttonAcaoParceiro,
            listaPlace,
            places,
            modalBanco,
            dataPagamentoAcao,

            isCreditoFaturaPlace,
            acaoComInfluencer,
            listaInfluencers,
            influencerId,
            acaoComFornecedor,
            fornecedorId,
            openModal,
            listaFornecedores



        } = this.state



        return (


            <div style={{ margin: '20px 20px 20px 20px' }}>

                <div style={{ float: 'height' }}>
                    <Button style={{ height: '30px' }} basic color='black' onClick={() => this.telaInicial()}>Voltar</Button>

                </div>
                <div style={{ marginTop: '10px' }}>
                    <Form size='small'>
                        <Form.Group widths='equal' >
                            <Form.Field
                                disabled
                                control={Input}
                                label='Criado Por'
                                //width={20}
                                value={userLogado.text}
                            />
                            <Form.Field
                                required
                                control={Select}
                                label='Cidade'
                                //width={30}
                                options={municipios}
                                search
                                placeholder='Cidade Para Plano de Ação'
                                value={municipioId}
                                onChange={(event, data) => this.setState({ municipioId: data.value }, this.getAllPlaceByMunicipio(data.value))}
                            />

                            <Form.Field
                                required
                                control={Select}
                                label='Ação'
                                options={acoes}
                               // width={30}
                                search
                                placeholder='Escolha Ação'
                                value={acaoId}
                                onChange={(event, data) => this.setState({ acaoId: data.value }, this.getFinalidadeAcao(data.value))}
                            />
                        </Form.Group>


                        <Form.Group>

                            {
                                acaoComInfluencer ? <Form.Field

                                    style={{ borderRadius: '20px', backgroundColor: '#008000' }}
                                    control={Button}
                                    label='Ação Com Influencers ?'
                                    onClick={() => this.setState({ acaoComInfluencer: false })}

                                /> : <Form.Field

                                        style={{ borderRadius: '20px' }}
                                        control={Button}
                                        label='Ação Com Influencers ?'
                                        onClick={() => this.setState({ acaoComInfluencer: true }, this.getAllInfluencerByMunicipio(municipioId))}

                                    />
                            }

                            {
                                acaoComFornecedor ? <Form.Field

                                    style={{ borderRadius: '20px', backgroundColor: '#008000' }}
                                    control={Button}
                                    label='Ação Com Fornecedor ?'
                                    onClick={() => this.setState({ acaoComFornecedor: false })}

                                /> : <Form.Field

                                        style={{ borderRadius: '20px' }}
                                        control={Button}
                                        label='Ação Com Fornecedor ?'
                                        onClick={() => this.setState({ acaoComFornecedor: true }, this.getAllInfluencerByMunicipio(municipioId))}

                                    />
                            }

                            {
                                buttonAcaoParceiro ? <Form.Field
                                    style={{ borderRadius: '20px', backgroundColor: '#008000' }}
                                    control={Button}
                                    label='Ação Com Parceiro ?'
                                    // width={5}
                                    onClick={() => this.setState({ buttonAcaoParceiro: false })}
                                /> :
                                    <Form.Field
                                        style={{ borderRadius: '20px' }}
                                        control={Button}
                                        // width={5}
                                        label='Ação Com Parceiro ?'
                                        onClick={() => this.setState({ buttonAcaoParceiro: true, places: [] })}
                                    />
                            }
                            {
                                buttonAcaoParceiro && !isCreditoFaturaPlace ?

                                    <Form.Field

                                        style={{ borderRadius: '20px' }}
                                        // width={5}
                                        control={Button}
                                        label='Crédito Em Fatura ?'
                                        onClick={() => this.setState({ isCreditoFaturaPlace: true, dataPagamentoAcao: '' })}


                                    /> : buttonAcaoParceiro && isCreditoFaturaPlace ?

                                        <Form.Field

                                            style={{ borderRadius: '20px', backgroundColor: '#008000' }}
                                            // width={5}
                                            control={Button}
                                            label='Crédito Em Fatura ?'
                                            onClick={() => this.setState({ isCreditoFaturaPlace: false })}
                                        /> : ''
                            }




                        </Form.Group>

                        <Form.Group widths='equal'>

                            {
                                buttonAcaoParceiro ?
                                    <Form.Field
                                        style={{ fontSize: '11px' }}
                                        required
                                        search
                                        control={Select}
                                        options={listaPlace}
                                        label='Places'
                                        fluid multiple selection
                                        value={places}
                                        onChange={(event, data) => this.setState({ places: data.value })}
                                    />

                                    : ''


                            }

                            {
                                acaoComInfluencer ?
                                    <Form.Field
                                        style={{ fontSize: '11px' }}
                                        required
                                        search
                                        control={Select}
                                        options={listaInfluencers}
                                        label='Influencers'
                                        value={influencerId}
                                        onChange={(event, data) => this.setState({ influencerId: data.value })}
                                    />

                                    : ''


                            }

                            {
                                acaoComFornecedor ?
                                    <Form.Field
                                        style={{ fontSize: '11px' }}
                                        required
                                        search
                                        control={Select}
                                        options={listaFornecedores}
                                        label='Fornecedor'
                                        value={fornecedorId}
                                        onChange={(event, data) => this.setState({ fornecedorId: data.value })}
                                    />

                                    : ''


                            }

                        </Form.Group>





                        <Form.Group widths='equal'>

                            <Form.Field
                                required
                                style={{ fontSize: '11px' }}
                                control={Select}
                                options={listaConsultor}
                                label='Participantes'
                                search
                                fluid multiple selection
                                placeholder='Ex: Afiliado, Equipe Marketing'
                                value={participantes}
                                onChange={(event, data) => this.setState({ participantes: data.value })}
                            />



                        </Form.Group>

                        <Form.Group widths='equal'>

                            <Form.Field
                                required
                                control={Input}
                                label='Finalidade'
                                placeholder='Ex: Alavancar Vendas'
                                value={causa}
                                onChange={(event, data) => this.setState({ causa: data.value })}
                            />
                            <Form.Field
                                required
                                style={{ fontSize: '12px' }}
                                control={Select}
                                options={listaConsultor}
                                label='Responsável Pela Execução'
                                search
                                placeholder='Cidade Para Plano de Ação'
                                value={consultorExecucaoId}
                                onChange={(event, data) => this.setState({ consultorExecucaoId: data.value })}



                            />

                            <Form.Field
                                required
                                control={Input}
                                label='Onde'
                                placeholder='Ex: Parceiro, Rede Sociais'
                                value={onde}
                                onChange={(event, data) => this.setState({ onde: data.value })}

                            />



                        </Form.Group>


                        <Form.Group>
                            <Form.Input label='Prazo Inicio'>
                                <SemanticDatepicker required type="basic" format='DD/MM/YYYY' onChange={(event, data) => this.setState({ prazoInicio: data.value })} />
                            </Form.Input>
                            <Form.Input label='Prazo Fim'>
                                <SemanticDatepicker required locale="pt-BR" type="basic" format='DD/MM/YYYY' onChange={(event, data) => this.setState({ prazoFim: data.value })} />
                            </Form.Input>

                        </Form.Group>



                        <Form.Field
                            required
                            control={TextArea}
                            label='Como'
                            placeholder='Como fará ações'
                            value={como}
                            onChange={(event, data) => this.setState({ como: data.value })}
                        />
                        <Form.Group widths='equal'>
                            <Form.Field
                                control={Input}
                                label='Observações'
                                value={observacao}
                                onChange={(event, data) => this.setState({ observacao: data.value })}

                            />

                        </Form.Group>

                        <Form.Group  >

                            <Form.Field
                                required
                                control={Input}
                                type='Number'
                                width={4}
                                label='Quanto Custa ?'
                                value={quantoCusta}
                                onChange={(event, data) => this.setState({ quantoCusta: data.value })}



                            />
                            {
                                quantoCusta > 0 && isCreditoFaturaPlace !== '' ?
                                    <Form.Input label='Data Pagamento'>
                                        <SemanticDatepicker locale="pt-BR" type="basic" format='DD/MM/YYYY' onChange={(event, data) => this.setState({ dataPagamentoAcao: data.value })} />
                                    </Form.Input>
                                    : ''
                            }

                        </Form.Group>

                        <Form.Group>
                            <Form.Field loading={loadingButtonSalvarAcoes} control={Button} onClick={() => this.salvarAcao()}>Próxima Ação</Form.Field>


                        </Form.Group>

                    </Form>


                </div>

                <div style={{ float: 'right' }}>
                    <Form.Field positive control={Button} loading={loadingButtonCadastrarAcoes} onClick={() => this.cadastrarAcoes()}>Cadastrar Ações</Form.Field>

                </div>





                <div style={{ width: '100%', height: '500px', overflowY: 'scroll' }}>

                    {
                        listaTemporariaAcoes.length > 0 ? <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Tipo de Ação</Table.HeaderCell>
                                    <Table.HeaderCell>Responsável Pela Execução</Table.HeaderCell>
                                    <Table.HeaderCell>Causa</Table.HeaderCell>
                                    <Table.HeaderCell>Quanto Custa</Table.HeaderCell>
                                    <Table.HeaderCell>Prazo Inicio</Table.HeaderCell>
                                    <Table.HeaderCell>Prazo Fim</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body >
                                {


                                    listaTemporariaAcoes.length > 0 ? listaTemporariaAcoes.map((acao, index) =>
                                        <Table.Row key={index}>
                                            <Table.Cell>{acao.tipoAcao}</Table.Cell>
                                            <Table.Cell>{acao.responsavelPelaCriacao}</Table.Cell>
                                            <Table.Cell>{acao.causa}</Table.Cell>
                                            <Table.Cell>{acao.quantoCusta}</Table.Cell>
                                            <Table.Cell>{moment(acao.prazoInicio).format('DD/MM/YYYY')}</Table.Cell>
                                            <Table.Cell>{moment(acao.prazoFim).format('DD/MM/YYYY')}</Table.Cell>
                                        </Table.Row>

                                    ) : ''
                                }


                            </Table.Body>
                        </Table> : ''
                    }

                </div>



            </div>






        );
    }
}

export default PlanoAcao;

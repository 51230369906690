import React, { Component } from 'react';

import { Loader, Button, Table, Form, Select, Input, Modal, TextArea, Icon, Accordion, Segment } from "semantic-ui-react";

import _, { parseInt, stubString } from 'lodash'

import AuthContext from "../context/authContext"

import { toast } from 'react-toastify';

import { mask, unMask } from "remask";

import Requests from '../shared/Requests'
import Cookies from 'js-cookie'
import moment from "moment";

import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';






export const seguimento = [
    {
        key: '1',
        text: `PIZZARIA`,
        value: 'PIZZARIA',
    },
    {
        key: '2',
        text: `HAMBURGUERIA`,
        value: 'HAMBURGUERIA',
    },
    {
        key: '3',
        text: `ACAITERIA`,
        value: 'ACAITERIA',
    },
    {
        key: '4',
        text: `SUPERMERCADO`,
        value: 'SUPERMERCADO',
    },
    {
        key: '5',
        text: `FARMACIA`,
        value: 'FARMACIA',
    },
    {
        key: '6',
        text: `HORTIFRUTI`,
        value: 'HORTIFRUTI',
    },
    {
        key: '7',
        text: `LANCHONETE`,
        value: 'LANCHONETE',
    },
    {
        key: '8',
        text: `DOCERIA`,
        value: 'DOCERIA',
    },
    {
        key: '9',
        text: `VARIEDADE`,
        value: 'VARIEDADE',
    },
    {
        key: '10',
        text: `GAS E AGUA`,
        value: 'GAS E AGUA',
    },
    {
        key: '11',
        text: `ACOUGUE`,
        value: 'ACOUGUE',
    },
    {
        key: '12',
        text: `RESTAURANTE`,
        value: 'RESTAURANTE',
    }







]

export const listaStatusLead = [
    {
        key: '1',
        text: `TODOS`,
        value: 'TODOS'
    },

    {
        key: '2',
        text: `PARA_PROSPECTAR`,
        value: 'PARA_PROSPECTAR'
    },
    {
        key: '3',
        text: `EM_PROSPECCAO`,
        value: 'EM_PROSPECCAO'
    },
    {
        key: '4',
        text: `CADASTRADO`,
        value: 'CADASTRADO'
    },
    {
        key: '5',
        text: `SEM_INTERESSE`,
        value: 'SEM_INTERESSE'
    },
    {
        key: '6',
        text: `ATRASADO`,
        value: 'ATRASADO'
    }



]


export const listCanal = [
    {
        key: '1',
        text: `INDICACAO`,
        value: 'INDICACAO',
    },
    {
        key: '2',
        text: `PROSPECCAO`,
        value: 'PROSPECCAO',
    },
    {
        key: '3',
        text: `INSTAGRAM`,
        value: 'INSTAGRAM',
    },
    {
        key: '4',
        text: `WHATSAPP`,
        value: 'WHATSAPP',
    },
    {
        key: '5',
        text: `PIPEFY`,
        value: 'PIPEFY',
    }



]


export const comentarioTESTE = [
    {
        leadId: '290',
        comentario: 'O Em Busca do Código é um site em formato de plataforma de ensino a distância (EAD), plataforma esta totalmente aberta e gratuita. Construido de maneira independente e individual, este layout é baseado neste curso.',
        data: '2021-07-28 08:59:00',
        consultor: 'Danilo Souza'

    },
    {
        leadId: '290',
        comentario: 'TESTE TESTE TESTE 2',
        data: '2021-07-29 09:59:00',
        consultor: 'Danilo Souza'

    },
    {
        leadId: '290',
        comentario: 'TESTE TESTE TESTE 3',
        data: '2021-07-30 22:59:00',
        consultor: 'Danilo Souza'

    }
]


class Lead extends Component {

    static contextType = AuthContext


    constructor(props) {
        super(props);

    }

    state = {
        openModalLead: false,
        municipios: [],
        municipioId: '',
        seguimentoLead: '',
        contato: '',
        status: '',
        comentario: '',
        nomeResponsavelNegocio: '',
        nomeDoNegocio: '',
        listaLead: [],
        loadingTable: false,
        userAuthCriacao: false,
        loadingSelectMunicipio: false,
        modalEdicaoLead: false,
        leadObj: {},
        canal: '',
        buttonSalvar: false,
        quadroResumo: {},
        activeIndex: 0,
        activeIndexPorCidade: 0,
        leadPorCidade: {},
        dataFiltro: '',
        activeIndexComentario: 1,
        campoComentario: false,
        listaComentarioLead: [],
        loadingComentario: false,
        consultorLogadoId: null,
        adm: false,
        listaConsultor: [],
        consultorProspctorId: null


    }

    getAllConsultor = () => {



        const listConsultor = []

        try {
            Requests
                .getAllConsultor()
                .then(response => {

                    const lista = response

                    for (let i = 0; i < lista.length; i++) {
                        const consultor = lista[i];

                        const obj = {
                            key: consultor.consultorId,
                            text: `${consultor.nome} - (${consultor.time})`.toUpperCase(),
                            value: `${consultor.consultorId}`,

                        }

                        listConsultor.push(obj)

                    }

                    this.setState({ listaConsultor: listConsultor })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }

    }


    userLogado = async () => {

        const { user } = this.context

        const cookiesUser = Cookies.get('user')

        if (user.data === undefined) window.location.href = `/`


        if (user.data.isAfiliado === true) return this.setState({ userAuthCriacao: true })


        if (user.data.consultorLogado.admin == true) return this.setState({ userAuthCriacao: true })

    }


    modalLead = async (lead) => {

        this.setState({ modalEdicaoLead: true, leadObj: lead })

        this.getAllComentarioLeadByLeadId(lead.leadId)

    }



    atualizar = () => {

        const { userAuthCriacao, municipioId } = this.state

        const data = new Date()

        const mes = moment(data).format('MM')
        const ano = moment(data).format('YYYY')


        try {

            if (userAuthCriacao) {

                this.setState({ consultorProspctorId: null })

                this.getAllLeadByMes()

            } else {

                if (municipioId === '') return toast.warning('Selecionar municipio')

                this.getAllLeadByMunicipioIdMesAno(municipioId, mes, ano)


            }

        } catch (error) {
            console.log(error)
        }

    }


    goBack() {

        this.props.history.push(`/plano`)

    }

    filterMesAno = (data) => {

        const { userAuthCriacao, municipioId } = this.state

        this.setState({ dataFiltro: data })



        if (userAuthCriacao) {

            debugger

            this.getAllLeadByMes(data)

        } else {

            if (municipioId === '') return toast.warning('Selecionar municipio')

            this.getAllLeadByMunicipioIdMesAno(municipioId, data)

        }

    }


    filterLeadByResponsavel = async (consultorId) => {

        const { listaLead } = this.state

        this.setState({ loadingTable: true })


        try {

            const mes = moment(new Date()).format('MM')
            const ano = moment(new Date()).format('YYYY')

            await Requests
                .getAllLeadByConsultorId(mes, ano, consultorId)
                .then(response => {

                    this.setState({ listaLead: response.data, loadingTable: false, consultorProspctorId: consultorId })



                })
                .catch(err => {
                    console.log(err)

                })



        } catch (error) {

        }




        // if (listaLead.length > 0) {

        //     let filtered_array = _.filter(listaLead,
        //         { 'consultorId': parseInt(consutorId) }
        //     );

        //     this.setState({ listaLead: filtered_array, consultorProspctor: consutorId })

        // }



    }

    filterStatusLead = async (status) => {

        const { userAuthCriacao, listaLead, municipioId, dataFiltro } = this.state

        let data = undefined



        if (dataFiltro !== '') {

            data = dataFiltro


        } else {

            data = new Date()

        }

        const mes = moment(data).format('MM')
        const ano = moment(data).format('YYYY')


        try {

            this.setState({ status: status })



            if (userAuthCriacao) {

                let result = undefined



                if (municipioId !== '') {

                    result = await Requests.getAllLeadByMunicipio(municipioId, mes, ano)



                } else {

                    result = await Requests.getAllLeadByMes(mes, ano)

                }




                if (result.r) {
                    const listLead = result.data

                    if (status === 'TODOS') {
                        this.setState({ listaLead: listLead })

                    } else {

                        const filtroLead = _.filter(listLead, { status: status })

                        this.setState({ listaLead: filtroLead })

                    }
                } else {

                    return toast.error('Error em filtrar lead')

                }

            } else {

                if (municipioId === '') return toast.warning('Selecionar municipio')



                const result = await Requests.getAllLeadByMunicipio(municipioId, mes, ano)

                if (result.r) {

                    const listLead = result.data



                    if (status === 'TODOS') {
                        this.setState({ listaLead: listLead })


                    } else {
                        const filtroLead = _.filter(listLead, { status: status })

                        this.setState({ listaLead: filtroLead })

                    }

                } else {

                    return toast.error('Error ao filtar lead')

                }



            }



        } catch (error) {
            console.log(error)

        }

    }


    getAllLeadByMes = async (data = new Date()) => {



        const mes = moment(data).format('MM')
        const ano = moment(data).format('YYYY')

        this.setState({ quadroResumo: {} })

        try {

            this.setState({ loadingTable: true })
            Requests
                .getAllLeadByMes(mes, ano)
                .then(response => {

                    this.quadroResumo(response.data)
                    this.leadPorCidade(response.data)

                    this.setState({ listaLead: response.data, loadingTable: false, municipioId: '' })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {

        }

    }


    getAllLeadByConsultorId = async () => {

        try {

            const { user } = this.context

            const mes = moment(new Date()).format('MM')
            const ano = moment(new Date()).format('YYYY')

            const consultorId = user.data.consultorLogado.consultorId

            Requests
                .getAllLeadByConsultorId(mes, ano, consultorId)
                .then(response => {



                    this.quadroResumo(response.data)
                    this.leadPorCidade(response.data)

                    this.setState({ listaLead: response.data, loadingTable: false })

                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {
            console.log(error)

        }

    }




    getAllLeadByMunicipioIdMesAno = async (municipioId, data = new Date()) => {


        const { dataFiltro } = this.state

        if (dataFiltro !== '') data = dataFiltro


        const mes = moment(data).format('MM')
        const ano = moment(data).format('YYYY')

        debugger


        this.setState({ quadroResumo: {} })


        if (mes !== null && ano !== null) {

            this.setState({ loadingTable: true })

            try {
                Requests
                    .getAllLeadByMunicipio(municipioId, mes, ano)
                    .then(response => {

                        debugger

                        this.quadroResumo(response.data)
                        this.leadPorCidade(response.data)

                        this.setState({ listaLead: response.data, loadingTable: false })

                    })
                    .catch(err => {
                        console.log(err)

                    })

            } catch (error) {

            }

        } else {



            this.setState({ loadingTable: true })



            try {
                Requests
                    .getAllLeadByMunicipio(municipioId, mes, ano)
                    .then(response => {

                        this.quadroResumo(response.data)
                        this.leadPorCidade(response.data)

                        this.setState({ listaLead: response.data, loadingTable: false })

                    })
                    .catch(err => {
                        console.log(err)

                    })

            } catch (error) {

            }

        }


    }

    cadastrarNovoLead = async () => {

        const { municipioId, nomeResponsavelNegocio, nomeDoNegocio, contato, seguimentoLead, canal } = this.state




        this.setState({ buttonSalvar: true })


        try {

            const obj = {
                nomeResponsavel: nomeResponsavelNegocio.toLocaleUpperCase(),
                nomeNegocio: nomeDoNegocio.toLocaleUpperCase(),
                seguimento: seguimentoLead,
                contato: contato,
                municipioId: municipioId,
                status: 'PARA_PROSPECTAR',
                canal: canal

            }

            const arrayLead = []

            arrayLead.push(obj)
            Requests
                .createLead(arrayLead)
                .then(response => {



                    if (response.r) {

                        this.getAllLeadByMunicipioIdMesAno(municipioId)

                        this.setState(
                            {
                                nomeDoNegocio: '',
                                nomeResponsavelNegocio: '',
                                contato: '',
                                seguimentoLead: '',
                                openModalLead: false,
                                canal: '',
                                buttonSalvar: false


                            })


                        return toast.success('Lead Cadastrado')

                    }
                })
                .catch(err => {
                    console.log(err)

                })



        } catch (error) {
            console.log(error)
        }

    }


    updateStatusLead = async (lead, status) => {

        const { userAuthCriacao, municipioId, comentario } = this.state

        const leadId = lead.leadId

        const result = await Requests.getAllComentarioLead(leadId)


        if ((result.data.length) === 0 && (status === 'SEM_INTERESSE' || status === 'CADASTRADO')) return toast.warning('Adicionar comentário')



        try {

            Requests
                .updateStatusLead(leadId, status)
                .then(response => {

                    this.getAllLeadByMunicipioIdMesAno(municipioId)


                })
                .catch(err => {
                    console.log(err)
                })

        } catch (error) {
            console.log(error)

        }

    }


    onChangeMaskTelefone = (ev) => {

        const originalValue = unMask(ev.target.value)
        const maskedValue = mask(originalValue, ['(99) 99999-9999'])
        this.setState({ contato: maskedValue })
    }



    getAllMunicipios = async () => {

        const { user } = this.context



        if (user.initial) return window.location.href = `/`

        const acessoRoout = user.data.isRoot

        const listaMunicipioIds = user.data.municipioIds

        this.setState({ loadingSelectMunicipio: true })

        const listaMunicipios = []

        try {
            Requests
                .getAllMunicipios()
                .then(response => {

                    const lista = response.data

                    if (acessoRoout) {

                        for (let i = 0; i < lista.length; i++) {
                            const municipio = lista[i];

                            const obj = {
                                key: municipio.municipioId,
                                text: `${municipio.nome}/${municipio.uf}`,
                                value: municipio.municipioId,

                            }


                            listaMunicipios.push(obj)

                        }

                    } else {
                        for (let j = 0; j < listaMunicipioIds.length; j++) {
                            const municipioId = listaMunicipioIds[j];

                            const municipio = _.find(lista, { municipioId: municipioId })

                            if (municipio) {

                                const obj = {
                                    key: municipio.municipioId,
                                    text: `${municipio.nome}/${municipio.uf}`,
                                    value: municipio.municipioId,

                                }


                                listaMunicipios.push(obj)

                            }
                        }
                    }


                    this.setState({ municipios: listaMunicipios, loadingSelectMunicipio: false })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }
    }


    getAllComentarioLeadByLeadId = async (leadId) => {

        this.setState({ loadingComentario: true })

        try {

            Requests
                .getAllComentarioLead(leadId)
                .then(response => {

                    this.setState({ listaComentarioLead: response.data, comentario: '', campoComentario: false })

                    this.setState({ loadingComentario: false })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {

        }

    }


    adicionarComentario = async (leadId) => {
        const { comentario, userAuthCriacao, municipioId } = this.state

        const { user } = this.context

        if (comentario === '') return toast.warning('Adicionar comentário')

        const arrayComentario = []

        try {

            const obj = {
                leadId: leadId,
                comentario: comentario,
                consultorId: user.data.consultorLogado.consultorId
            }
            arrayComentario.push(obj)

            Requests
                .addComenmtarioLead(leadId, arrayComentario)
                .then(response => {

                    if (userAuthCriacao) {


                        this.getAllComentarioLeadByLeadId(leadId)

                        if (municipioId !== '') {

                            this.getAllLeadByMunicipioIdMesAno(municipioId)

                        } else {

                            this.getAllLeadByMes()

                        }

                    } else {

                        this.getAllComentarioLeadByLeadId(leadId)

                        this.getAllLeadByMunicipioIdMesAno(municipioId)

                    }



                })
                .catch(err => {
                    console.log(err)
                })

        } catch (error) {
            console.log(error)
        }

    }


    closeModalLeadComentario = () => {
        this.setState({
            modalEdicaoLead: false,
            leadObj: {},
            comentario: '',
            canal: '',
            seguimentoLead: '',
            nomeResponsavelNegocio: '',
            nomeDoNegocio: '',
            campoComentario: false



        })
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }


    handleClickComentario = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndexComentario } = this.state
        const newIndex = activeIndexComentario === index ? -1 : index

        this.setState({ activeIndexComentario: newIndex })
    }

    handleClickPorCidade = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndexPorCidade } = this.state
        const newIndex = activeIndexPorCidade === index ? -1 : index

        this.setState({ activeIndexPorCidade: newIndex })
    }


    quadroResumo = (listaLead) => {

        if (listaLead.length > 0) {

            const quadroResumo = _(listaLead)
                .groupBy('seguimento')
                .map((objs, key) => ({
                    'count': objs.length,
                    'seguimento': key

                }))
                .value()


            this.setState({ quadroResumo: quadroResumo })

        }
    }

    leadPorCidade = (listaLead) => {

        if (listaLead.length > 0) {

            const quadroResumo = _(listaLead)
                .groupBy('nome')
                .map((objs, key) => ({
                    'count': objs.length,
                    'nome': key

                }))
                .value()


            this.setState({ leadPorCidade: quadroResumo })

        }
    }



    componentDidMount() {

        const { user } = this.context


        const cookiesUser = Cookies.get('user')

        const cookies = Cookies.get('qd-token')



        if (cookies === undefined) window.location.href = `/`




        if (user.data.consultorLogado.admin == true) {

            this.setState({ adm: true })


            this.getAllLeadByMes()

        } else {
            this.getAllLeadByConsultorId()
        }



        this.userLogado()


        this.getAllMunicipios()

        this.getAllConsultor()










    }


    render() {

        const { openModalLead, municipios, municipioId, seguimentoLead, contato, statusLead, status, comentario, nomeResponsavelNegocio, nomeDoNegocio, listaLead, loadingTable, loadingSelectMunicipio, userAuthCriacao, modalEdicaoLead, leadObj, canal, buttonSalvar, quadroResumo, activeIndex, activeIndexPorCidade, leadPorCidade, dataFiltro, activeIndexComentario, campoComentario, listaComentarioLead, loadingComentario, adm, listaConsultor, consultorProspctorId } = this.state
        return (

            <div style={{ margin: '20px 20px 20px 20px' }}>
                {adm ? <div>
                    <Form >

                        <Form.Group widths='equal'>
                            <Form.Field
                                control={Select}
                                label='Cidade'
                                options={municipios}
                                loading={loadingSelectMunicipio}
                                search
                                placeholder='Cidade'
                                value={municipioId}

                                onChange={(event, data) => this.setState({ municipioId: data.value }, () => this.getAllLeadByMunicipioIdMesAno(data.value))}
                            />
                            <Form.Field
                                control={Select}
                                label='Status'
                                options={listaStatusLead}
                                search
                                placeholder='Status'
                                value={status}
                                onChange={(event, data) => this.filterStatusLead(data.value)}
                            />

                            <Form.Field
                                control={Select}
                                label='Responsável'
                                options={listaConsultor}
                                search
                                placeholder='Responsável por prospectar'
                                value={consultorProspctorId}
                                onChange={(event, data) => this.filterLeadByResponsavel(data.value)}
                            />




                            <Form.Input label='Mês/Ano'>
                                <SemanticDatepicker locale="pt-BR" type="basic" format='MM/YYYY'
                                    onChange={(event, data) => this.filterMesAno(data.value)}

                                />


                            </Form.Input>

                            <Form.Field width='2' label='Atualizar' basic color='black' control={Button}
                                onClick={() => this.atualizar()}
                            >
                                <Icon name='sync'></Icon>
                            </Form.Field>

                            <Form.Field width='2' label='Voltar' basic color='black' control={Button}
                                onClick={() => this.goBack()}
                            >
                                <Icon name='arrow left'></Icon>
                            </Form.Field>

                            <Button basic color='black' onClick={() => this.setState({ openModalLead: true })}>
                                Novo Lead
                            </Button>


                        </Form.Group>

                    </Form>
                </div> : <div>
                    <Form >

                        <Form.Group widths='equal'>
                            <Form.Field
                                control={Select}
                                label='Cidade'
                                options={municipios}
                                loading={loadingSelectMunicipio}
                                search
                                placeholder='Cidade'
                                value={municipioId}

                                onChange={(event, data) => this.setState({ municipioId: data.value }, () => this.getAllLeadByMunicipioIdMesAno(data.value))}
                            />
                            <Form.Field
                                control={Select}
                                label='Status'
                                options={listaStatusLead}
                                search
                                placeholder='Status'
                                value={status}
                                onChange={(event, data) => this.filterStatusLead(data.value)}
                            />




                            <Form.Input label='Mês/Ano'>
                                <SemanticDatepicker locale="pt-BR" type="basic" format='MM/YYYY'
                                    onChange={(event, data) => this.filterMesAno(data.value)}

                                />


                            </Form.Input>

                            <Form.Field width='2' label='Atualizar' basic color='black' control={Button}
                                onClick={() => this.atualizar()}
                            >
                                <Icon name='sync'></Icon>
                            </Form.Field>

                            <Form.Field width='2' label='Voltar' basic color='black' control={Button}
                                onClick={() => this.goBack()}
                            >
                                <Icon name='arrow left'></Icon>
                            </Form.Field>

                            <Button basic color='black' onClick={() => this.setState({ openModalLead: true })}>
                                Novo Lead
                            </Button>


                        </Form.Group>

                    </Form>
                </div>}
                <hr />
                <div>

                    {/* <div>

                        <Accordion>
                            <Accordion.Title
                                active={activeIndex === 1}
                                index={1}
                                onClick={this.handleClick}
                            >
                                <Icon name='dropdown' />
                                Quantidade De Lead Por Seguimento
                            </Accordion.Title>

                            <Accordion.Content active={activeIndex === 1}>
                                <div>
                                    <h4>Tota de Lead: {listaLead.length}</h4>
                                    {
                                        quadroResumo.length > 0 ? <Table collapsing basic='very' selectable>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Seguimento</Table.HeaderCell>
                                                    <Table.HeaderCell>QTD</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {

                                                    _.orderBy(quadroResumo, ['count'], ['desc']).map((lead, index) =>
                                                        <Table.Row key={index}>

                                                            <Table.Cell><span> {lead.seguimento}</span></Table.Cell>
                                                            <Table.Cell><span>{lead.count}</span></Table.Cell>
                                                        </Table.Row>)


                                                }

                                            </Table.Body>
                                        </Table> : ''
                                    }
                                </div>
                            </Accordion.Content>
                        </Accordion>

                        {
                            userAuthCriacao === true ?
                                <Accordion>
                                    <Accordion.Title
                                        active={activeIndexPorCidade === 1}
                                        index={1}
                                        onClick={this.handleClickPorCidade}
                                    >
                                        <Icon name='dropdown' />
                                        Lead Por cidade
                                    </Accordion.Title>

                                    <Accordion.Content active={activeIndexPorCidade === 1}>

                                        <div>
                                            <h4>Tota de Cidade: {leadPorCidade.length}</h4>
                                            {
                                                leadPorCidade.length > 0 ? <Table collapsing basic='very' selectable>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell>Cidade</Table.HeaderCell>
                                                            <Table.HeaderCell>QTD</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>

                                                    <Table.Body>
                                                        {

                                                            _.orderBy(leadPorCidade, ['count'], ['desc']).map((lead, index) =>
                                                                <Table.Row key={index}>

                                                                    <Table.Cell><span> {lead.nome}</span></Table.Cell>
                                                                    <Table.Cell><span>{lead.count}</span></Table.Cell>
                                                                </Table.Row>)



                                                        }

                                                    </Table.Body>
                                                </Table> : ''
                                            }
                                        </div>


                                    </Accordion.Content>

                                </Accordion> : ''

                        }

                    </div> */}


                    <Loader active={loadingTable} size='big'>Loading</Loader>

                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell>Cidade</Table.HeaderCell>
                                <Table.HeaderCell>UF</Table.HeaderCell>
                                <Table.HeaderCell>Prospector</Table.HeaderCell>
                                <Table.HeaderCell>Nome Do Negócio</Table.HeaderCell>
                                <Table.HeaderCell>Seguimento</Table.HeaderCell>
                                <Table.HeaderCell>Contato</Table.HeaderCell>
                                <Table.HeaderCell>Canal</Table.HeaderCell>
                                <Table.HeaderCell>Data Criação</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Ação</Table.HeaderCell>


                            </Table.Row>
                        </Table.Header>

                        <Table.Body>

                            {listaLead.length > 0 ? _.sortBy(listaLead, ['createdAt', 'status']).map((lead, index) =>
                                <Table.Row key={index}>
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.modalLead(lead)}>{lead.leadId}</Table.Cell>
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.modalLead(lead)}>{lead.nome}</Table.Cell>
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.modalLead(lead)}>{lead.uf}</Table.Cell>
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.modalLead(lead)}>{lead.consultorNome}</Table.Cell>
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.modalLead(lead)}>{lead.nomeNegocio}</Table.Cell>
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.modalLead(lead)}>{lead.seguimento}</Table.Cell>
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.modalLead(lead)}>{lead.contato}</Table.Cell>
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.modalLead(lead)}>{lead.canal}</Table.Cell>
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.modalLead(lead)}>{moment(lead.createdAt).format('DD/MM/YYYY')}</Table.Cell>
                                    {
                                        lead.status === 'PARA_PROSPECTAR' ? <Table.Cell style={{ fontSize: '12px' }}><span style={{ backgroundColor: '#DCDCDC' }}>{lead.status}</span></Table.Cell> :

                                            lead.status === 'EM_PROSPECCAO' ?

                                                <Table.Cell style={{ fontSize: '12px' }}><span style={{ backgroundColor: '#FFFF00' }}>{lead.status}</span></Table.Cell> :

                                                lead.status === 'CADASTRADO' ?

                                                    <Table.Cell style={{ fontSize: '12px' }}><span style={{ backgroundColor: '#00FF00' }}>{lead.status}</span></Table.Cell> :

                                                    lead.status === 'SEM_INTERESSE' ?

                                                        <Table.Cell style={{ fontSize: '12px' }}><span style={{ backgroundColor: '#FF0000' }}>{lead.status}</span></Table.Cell> : ''



                                    }

                                    <Table.Cell>
                                        {
                                            lead.status === 'PARA_PROSPECTAR' ? <Button size='mini' color='green' onClick={() => this.updateStatusLead(lead, 'EM_PROSPECCAO')}><Icon name='play' /></Button> : ''
                                        }

                                        {
                                            lead.status === 'EM_PROSPECCAO' ? <Button size='mini' color='blue' onClick={() => this.updateStatusLead(lead, 'CADASTRADO')}><Icon name='check' /></Button> : ''


                                        }

                                        {

                                            lead.status === 'EM_PROSPECCAO' ? <Button size='mini' color='red' onClick={() => this.updateStatusLead(lead, 'SEM_INTERESSE')}><Icon name='cancel' /></Button> : ''

                                        }




                                    </Table.Cell>




                                </Table.Row>) : ''


                            }

                        </Table.Body>

                    </Table>


                </div>


                <div>
                    <Modal

                        onClose={() => this.setState({ openModalLead: false })}
                        open={openModalLead}


                    >
                        <Modal.Header>Novo Lead</Modal.Header>
                        <div style={{ margin: '10px 10px 10px 10px' }}>

                            <Form>
                                <Form.Group widths='equal' >

                                    <Form.Field
                                        required
                                        control={Select}
                                        label='Cidade'
                                        //width={30}
                                        options={municipios}
                                        search
                                        placeholder='Cidade Para Novo Lead'
                                        value={municipioId}
                                        onChange={(event, data) => this.setState({ municipioId: data.value })}
                                    />
                                    <Form.Field
                                        required
                                        control={Select}
                                        label='Canal Lead'
                                        //width={30}
                                        options={listCanal}
                                        search
                                        placeholder='Canal Lead'
                                        value={canal}
                                        onChange={(event, data) => this.setState({ canal: data.value })}
                                    />
                                    <Form.Field
                                        required
                                        control={Select}
                                        label='Seguimento'
                                        //width={30}
                                        options={seguimento}
                                        search
                                        placeholder='Seguimento de Lead'
                                        value={seguimentoLead}
                                        onChange={(event, data) => this.setState({ seguimentoLead: data.value })}
                                    />

                                </Form.Group>
                                <Form.Group widths='equal' >
                                    <Form.Field
                                        required
                                        control={Input}
                                        label='Nome Responsável Do Négocio'
                                        placeholder='Qual nome de quem entrou em contato ?'
                                        value={nomeResponsavelNegocio}
                                        onChange={(event, data) => this.setState({ nomeResponsavelNegocio: data.value })}
                                    />
                                    <Form.Field
                                        required
                                        control={Input}
                                        label='Nome Do Negócio'
                                        placeholder='Qual nome do estabelecimento ?'
                                        value={nomeDoNegocio}
                                        onChange={(event, data) => this.setState({ nomeDoNegocio: data.value })}
                                    />
                                </Form.Group>
                                <Form.Group >
                                    <Form.Field
                                        disabled={false}
                                        control={Input}
                                        label='Telefone'
                                        placeholder="(00) 00000-0000"
                                        value={contato}
                                        onChange={this.onChangeMaskTelefone}


                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({ openModalLead: false })}>Cancelar</Button>
                            <Button onClick={() => this.cadastrarNovoLead()} loading={buttonSalvar} >
                                Salvar
                            </Button>
                        </Modal.Actions>

                    </Modal>

                </div>


                {/* Modal para adição comentário */}
                <div style={{ margin: '20px 20px 20px 20px' }}>

                    <Modal
                        open={modalEdicaoLead}
                        size='large'
                        onClose={() => this.setState({ modalEdicaoLead: false, leadObj: {}, comentario: '', listaComentarioLead: [] })}


                    >



                        <Modal.Header>Comentários</Modal.Header>
                        <div style={{ margin: '20px 20px 20px 20px' }}>



                            <Table celled selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Id</Table.HeaderCell>
                                        <Table.HeaderCell>Cidade</Table.HeaderCell>
                                        <Table.HeaderCell>UF</Table.HeaderCell>
                                        <Table.HeaderCell>Nome Responsável</Table.HeaderCell>
                                        <Table.HeaderCell>Nome Do Negócio</Table.HeaderCell>
                                        <Table.HeaderCell>Seguimento</Table.HeaderCell>
                                        <Table.HeaderCell>Contato</Table.HeaderCell>
                                        <Table.HeaderCell>Data Criação</Table.HeaderCell>
                                        <Table.HeaderCell>Status</Table.HeaderCell>



                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    <Table.Row key={0}>
                                        <Table.Cell style={{ fontSize: '12px' }}>{leadObj.leadId}</Table.Cell>
                                        <Table.Cell style={{ fontSize: '12px' }}>{leadObj.nome}</Table.Cell>
                                        <Table.Cell style={{ fontSize: '12px' }}>{leadObj.uf}</Table.Cell>
                                        <Table.Cell style={{ fontSize: '12px' }}>{leadObj.nomeResponsavel}</Table.Cell>
                                        <Table.Cell style={{ fontSize: '12px' }}>{leadObj.nomeNegocio}</Table.Cell>
                                        <Table.Cell style={{ fontSize: '12px' }}>{leadObj.seguimento}</Table.Cell>
                                        <Table.Cell style={{ fontSize: '12px' }}>{leadObj.contato}</Table.Cell>
                                        <Table.Cell style={{ fontSize: '12px' }}>{moment(leadObj.createdAt).format('L')}</Table.Cell>
                                        {
                                            leadObj.status === 'PARA_PROSPECTAR' ? <Table.Cell style={{ fontSize: '12px' }}><span style={{ backgroundColor: '#DCDCDC' }}>{leadObj.status}</span></Table.Cell> :

                                                leadObj.status === 'EM_PROSPECCAO' ?

                                                    <Table.Cell style={{ fontSize: '12px' }}><span style={{ backgroundColor: '#FFFF00' }}>{leadObj.status}</span></Table.Cell> :

                                                    leadObj.status === 'CADASTRADO' ?

                                                        <Table.Cell style={{ fontSize: '12px' }}><span style={{ backgroundColor: '#00FF00' }}>{leadObj.status}</span></Table.Cell> :

                                                        leadObj.status === 'SEM_INTERESSE' ?

                                                            <Table.Cell style={{ fontSize: '12px' }}><span style={{ backgroundColor: '#FF0000' }}>{leadObj.status}</span></Table.Cell> : ''



                                        }
                                    </Table.Row>
                                </Table.Body>

                            </Table>


                            <div>

                                {/* <Loader active={loadingComentario} size='big'>Loading</Loader> */}

                                {
                                    listaComentarioLead.length > 0 ? _.orderBy(listaComentarioLead, ['data'], ['asc']).map((c, index) => <Segment color='violet' raised >
                                        <h4> <u>[{c.nomeConsultor.toUpperCase()}]</u>  {moment(c.data).format('DD/MM/YYYY [às] HH:mm')}</h4>

                                        <span style={{ marginLeft: '25px' }} >
                                            - {c.comentario}

                                        </span>
                                    </Segment>) : ''
                                }

                                {campoComentario ? <Form>
                                    <Form.Field
                                        control={TextArea}
                                        style={{ minHeight: 150 }}
                                        label='Comentário'
                                        placeholder='Adicionar comentário'
                                        //defaultValue={leadObj.comentario}
                                        onChange={(event, data) => this.setState({ comentario: data.value })}


                                    />
                                </Form> : ''}
                            </div>



                        </div>

                        <div style={{ margin: '5px 5px 5px 5px' }}>
                            <Button basic color='green' content='Green' onClick={() => this.setState({ campoComentario: true })} >
                                <Icon name='add'></Icon>
                            </Button>
                        </div>

                        <Modal.Actions>
                            <Button onClick={() => this.closeModalLeadComentario()}>Cancelar</Button>
                            <Button onClick={() => this.adicionarComentario(leadObj.leadId)} >
                                Salvar
                            </Button>
                        </Modal.Actions>

                    </Modal>
                </div>

            </div>
        )

    }




}

export default Lead;
import React, { useEffect, useContext, useState } from 'react'
import { Button, Form, Header, Icon, Modal, Select, Input } from 'semantic-ui-react'

import Requests from "../shared/Requests";
import AuthContext from "../context/authContext"
import { toast } from 'react-toastify';

function ModalExampleCloseIcon(props) {
    const [open, setOpen] = React.useState(false)


    const [influencersLista, setInfluencersLista] = useState([])

    const [useValorVaucher, setValorVaucher] = useState(0)

    const [usePlaceId, setPlaceId] = useState('')

    const [userInfluencerId, setInfluencerId] = useState('')

    const { useValorAcaoControlado, setValorAcaoControlado, setNovoVaucer } = useContext(AuthContext)


    //const {} = this.context




    const addValor = (ev) => {

        

        const valor = ev.target.value

        const soma = parseFloat(valor) + parseFloat(useValorAcaoControlado)
        setValorAcaoControlado(soma)

    }

    const lancarCreditoPlace = () => {

        

        if(useValorVaucher === 0) return toast.warning('Informar valor do vaucher.')

        const soma = parseFloat(useValorVaucher) + parseFloat(useValorAcaoControlado)
        setValorAcaoControlado(soma)
        
        const obj = {
            municipioId: props.municipioId,
            placeId: usePlaceId,
            influencerId: userInfluencerId,
            valorVaucher: soma
        }

       
        setNovoVaucer(true)
        setPlaceId('')
        setInfluencerId('')
        setValorVaucher(0)
        setOpen(false)

        console.log(obj)




    }

    useEffect(() => {

        ///getAllInfluencerByMunicipio(props.municipioId)

       /// console.log(props.listaInfluencers)
    })



    return (
        <Modal
            open={open}
            trigger={<Button>Lançar Vaucher</Button>}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >
            <Header icon='gift' content='Lançar Vaucher Para Influencer' />
            <Modal.Content>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field
                            disabled={true}
                            control={Select}
                            label='Cidade'
                            options={props.listaMunicipio}
                            // loading={loadingSelectMunicipio}
                            search
                            value={props.municipioId}
                        // onChange={(event, data) => setPlaceId(data.value)}
                        />


                        <Form.Field
                            control={Select}
                            label='Influencer'
                            options={props.listaInfluencers}
                            // loading={loadingSelectMunicipio}
                            search
                            placeholder='Selecionar Influencer'
                            value={userInfluencerId}
                            onChange={(event, data) => setInfluencerId(data.value)}
                        />
                    </Form.Group>

                    <Form.Group widths='equal'>


                        <Form.Field

                            control={Select}
                            label='Places'
                            options={props.listaPlace}
                            // loading={loadingSelectMunicipio}
                            search
                            placeholder='Selecione Parceiro'
                            value={usePlaceId}
                            onChange={(event, data) => setPlaceId(data.value)}
                        />

                        <Form.Field
                            control={Input}
                            type='Number'
                            width={6}
                            label='Valor Creditar a Parceiro'
                            //defaultValue={acao.quantoCusta}
                            value={useValorVaucher}
                            onChange={(event, data) => setValorVaucher(data.value)}

                        />

                    </Form.Group>
                    <Form.Group>


                    </Form.Group>

                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => setOpen(false)}>
                    <Icon name='remove' /> Cancelar
        </Button>
                <Button color='green' onClick={() => lancarCreditoPlace()}>
                    <Icon name='checkmark' /> Lançar
        </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ModalExampleCloseIcon

import AxiosApi from '../AxiosApi'





export const getAllMunicipios = () => {

    const request = AxiosApi.get(`/getAllMunicipios`);

    return request;
}

export const getAllInfluencerByMunicipioId = (municipioId) => {




    const request = AxiosApi.get(`/getAllInfluencerByMunicipioId?municipioId=${municipioId}`);

    return request;
}


export const getAllConsultor = () => {

    const request = AxiosApi.get(`/getAllConsultor`);

    return request;
}


export const getConsultorByEmail = (email) => {

    const request = AxiosApi.get(`/getConsultorByEmail?email=${email}`);

    return request;

}


export const getAllLeadByMes = (mes, ano) => {


    const request = AxiosApi.get(`/getAllLeadByMesAno?mes=${mes}&ano=${ano}`);

    return request;

}


export const getAllAcoes = () => {

    const request = AxiosApi.get(`/getAllAcoes`);

    return request;
}

export const getAllPlanoAcaoByMunicipio = (municipioId, mes, ano) => {

    const request = AxiosApi.get(`/getAllPlanoAcaoByMunicipioId?municipioId=${municipioId}&ano=${ano}&mes=${mes}`);

    return request;
}

export const getAllPlanoAcaoByConsultorId = (consultorId, ano, mes) => {




    const request = AxiosApi.get(`/getAllPlanoAcaoByConsultorExecucao?consultorExecucaoId=${consultorId}&ano=${ano}&mes=${mes}`);



    return request;
}

export const getAllPlacesByMunicipio = (municipioId) => {



    const request = AxiosApi.get(`/getAllPlacesByMunicipioId?municipioId=${municipioId}`);

    return request;
}


export const getAllLeadByMunicipio = (municipioId, mes, ano) => {



    const request = AxiosApi.get(`/getAllLeadByMunicipioId?municipioId=${municipioId}&mes=${mes}&ano=${ano}`);

    return request;
}

export const getAllLeadByConsultorId = (mes, ano, consultorId) => {



    const request = AxiosApi.get(`/getAllLeadByMesAnoConsultor?mes=${mes}&ano=${ano}&consultorId=${consultorId}`);

    return request;
}


export const getAllLancamentosByPlanoAcaoId = (planoAcaoId) => {



    const request = AxiosApi.get(`/getAllLancamentoByPlanoAcaoId?planoAcaoId=${planoAcaoId}`);

    return request;
}




export const getMetaByMunicipioId = (municipioId, mes, ano) => {

    const request = AxiosApi.get(`/getMetas?municipioId=${municipioId}&mes=${mes}&ano=${ano}`);

    return request;


}





export const addComenmtarioLead = (leadId, comentario) => {



    const request = AxiosApi.post(`/createComentarioLead`,
        comentario

    );

    return request;

}




export const updateStatusLead = (leadId, status) => {

    const request = AxiosApi.put(`/setStatusLead?leadId=${leadId}&status=${status}`);

    return request;

}


export const updateLancamento = (lancamentoId, valorCredito) => {



    const request = AxiosApi.put(`/updateLancamentoFinanceiro?lancamentoId=${lancamentoId}`,
        [{
            valorCredito: parseFloat(valorCredito)

        }]);

    return request;

}


export const SetPlanoAcaoValorRealizado = (planoAcaoId, valorRealizado) => {



    const request = AxiosApi.put(`/setPlanoAcaoValorRealizado?planoAcaoId=${planoAcaoId}`,
        [{
            valorRealizado: parseFloat(valorRealizado)
        }]
    );


    return request;

}


export const setValorCreditoParceiro = (planoAcaoId, placeId, valorCredito) => {



    const request = AxiosApi.post(`/createLancamentoFinanceiro`,
        [{
            planoAcaoId: planoAcaoId,
            placeId: placeId,
            valorCredito: parseFloat(valorCredito),

        }]
    );


    return request;

}



export const updateStatusPlanoAcao = (planoAcaoId, status) => {

    const request = AxiosApi.put(`/setStatus?planoAcaoId=${planoAcaoId}&status=${status}`,);


    return request;

}

export const updatePlanoAcao = (planoAcaoId, acao) => {

    const request = AxiosApi.put(`/alterPlanoAcao?planoAcaoId=${planoAcaoId}`,
        acao
    );

    return request;
}


export const createLead = (lead) => {

    const request = AxiosApi.post(`/createLead`,
        lead
    );

    return request;
}


export const createAcoes = (arrayAcoes) => {

    const request = AxiosApi.post(`/create`,
        arrayAcoes
    );

    return request;
}

export const createInfluencer = (arrayInfluencer) => {

    const request = AxiosApi.post(`/createInfluencer`,
        arrayInfluencer
    );

    return request;
}


export const authLogin = (login, pass, uuid) => {


    const request = AxiosApi.post(`https://api.querodelivery.com/manager/auth`, {
        login: login,
        pass: pass,
        uuid: uuid,
    });

    return request;
}



export const getAllFornecedor = () => {

    const request = AxiosApi.get(`/getAllFornecedor`);

    return request;


}

export const getPedidosNovoAtacarejoHoje = () => {

    const request = AxiosApi.get(`/getPedidosNovoAtacarejoHoje`);

    return request;

}

export const getStatusLojasNovoAracarejo = () => {

    const request = AxiosApi.get(`/getIsAbertoNovoAtacarejo`);

    return request;

}

export const getVendasDiasNovoAtacarejo = () => {

    const request = AxiosApi.get(`/getPedidosNovoAtacarejoMesAtual`);

    return request;

}



export const getAllComentarioLead = (leadId) => {

    const request = AxiosApi.get(`/getAllComentarioByLeadId?leadId=${leadId}`);

    return request;

}



export const getTpvByMuncipioId = (municipioId, mes, ano) => {

    const request = AxiosApi.get(`/getSalesByMunicipioIdMesAno?municipioId=${municipioId}&mes=${mes}&ano=${ano}`);

    return request;
}



export const getAllPlanoAcoesByMes = (mes, ano) => {

    const request = AxiosApi.get(`/getAllPlanoAcaoByMesAno?mes=${mes}&ano=${ano}`);

    return request;
}

export const cancelarPlanoAcao = (planoAcaoId, arrayMotivoCancelamento) => {



    const request = AxiosApi.put(`/cancelarPlanoAcao?planoAcaoId=${planoAcaoId}`,
        arrayMotivoCancelamento
    );

    return request;
}

export const createdFornecedor = (fornecedor) => {

    const request = AxiosApi.post(`/createFornecedor`,
        fornecedor
    );

    return request;

}






export default {
    getAllPlanoAcaoByMunicipio,
    createAcoes,
    getAllMunicipios,
    updateStatusPlanoAcao,
    getAllAcoes,
    getAllConsultor,
    authLogin,
    getAllPlacesByMunicipio,
    updatePlanoAcao,
    createInfluencer,
    getAllInfluencerByMunicipioId,
    getAllFornecedor,
    createdFornecedor,
    cancelarPlanoAcao,
    getMetaByMunicipioId,
    getAllPlanoAcaoByConsultorId,
    getAllPlanoAcoesByMes,
    createLead,
    getAllLeadByMunicipio,
    getAllLeadByMes,
    updateStatusLead,
    addComenmtarioLead,
    getTpvByMuncipioId,
    getAllComentarioLead,
    getAllLeadByConsultorId,
    SetPlanoAcaoValorRealizado,
    setValorCreditoParceiro,
    getAllLancamentosByPlanoAcaoId,
    updateLancamento,
    getPedidosNovoAtacarejoHoje,
    getStatusLojasNovoAracarejo,
    getVendasDiasNovoAtacarejo,
    getConsultorByEmail

}
import React, { Component } from 'react';

import { Loader, Button, Table, Form, Select, Input, Modal, TextArea, Icon, Accordion, Segment, Progress } from "semantic-ui-react";

import _, { parseInt, stubString } from 'lodash'

import AuthContext from "../context/authContext"

import numeral from "numeral";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";


import { toast } from 'react-toastify';

import { mask, unMask } from "remask";

import Requests, { getVendasDiasNovoAtacarejo } from '../shared/Requests'
import Cookies from 'js-cookie'
import moment from "moment";

import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';





// const baseMeta = [
//     {
//         data: "01/08/2023",
//         gmv: "101.003,35",
//         ticket: "233,46",
//         pedido: 433,
//         entregadores: 39
//     }
// ]





class NovoAtacarejo extends Component {

    static contextType = AuthContext


    constructor(props) {
        super(props);

    }

    state = {

        userAuthCriacao: false,
        quadroResumo: [],
        listaPedidos: [],
        loading: false,
        listaLojasIsAberto: [],
        listaVendasDias: [],
        listaVendasDiaSemTratar: [],
        metaDia: {}


    }


    // metaDia = () => {

    //     debugger

    //     const dateDia = new Date()

    //     for (let i = 0; i < baseMeta.length; i++) {
    //         const meta = baseMeta[i];

    //         console.log('Data dia',moment(dateDia).format('DD/MM/YYYY'))
    //         console.log('Data Meta',moment(meta.data).format('DD/MM/YYYY'))

    //         if (moment(dateDia).format('DD/MM/YYYY') == moment(meta.data).format('DD/MM/YYYY')) {

    //             debugger

    //             this.setState({ metaDia: meta })



    //             return meta
    //         } else {
    //             this.setState({ metaDia: {} })
    //         }


    //     }

    // }


    userLogado = async () => {

        const { user } = this.context

        const cookiesUser = Cookies.get('user')

        if (user.data === undefined) window.location.href = `/`

        if (user.data.isAfiliado === true) return this.setState({ userAuthCriacao: true })

        if (user.data.consultorLogado == true) return this.setState({ userAuthCriacao: true })

    }



    goBack() {

        this.props.history.push(`/plano`)

    }


    getPedidosNovoAtacarejoHoje = () => {

        this.setState({ loading: true })



        try {
            Requests
                .getPedidosNovoAtacarejoHoje()
                .then(response => {

                    if (response.length > 0) {

                        this.getStatusLojas()

                        this.setState({ listaPedidos: response, loading: false })

                    }
                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {
            console.log(error)

        }

    }




    getAllVendasDias = () => {

        //this.setState({ loading: true })

        try {
            Requests
                .getVendasDiasNovoAtacarejo()
                .then(response => {



                    if (response.length > 0) {

                        const lista = []
                        const listaOrdenada = _.orderBy(response, ['tpvEntregues'], ['desc'])

                        for (let index = 0; index < listaOrdenada.length; index++) {
                            const vendaDia = listaOrdenada[index];

                            const ano = moment().format('YYYY')
                            const mes = moment().format('MM')
                            const dia = vendaDia.dia

                            const objAtrasado =
                            {
                                title: `QTD Pedido: ${vendaDia.qtdEntregues} --
                                        TPV: R$ ${numeral(vendaDia.tpvEntregues).format('0,0')} \n
                                        `,

                                backgroundColor: index === 0 ? '#32CD32' : (listaOrdenada.length - 2) === index ? '#FF0000' : '#DCDCDC',
                                textColor: '#000000',
                                start: vendaDia.dia < 10 ? `${ano}-${mes}-0${dia}` : `${ano}-${mes}-${dia}`,
                                end: vendaDia.dia < 10 ? `${ano}-${mes}-0${dia}` : `${ano}-${mes}-${dia}`
                            }

                            lista.push(objAtrasado)



                        }

                        this.setState({ listaVendasDias: lista, listaVendasDiaSemTratar: response })

                    }
                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {
            console.log(error)

        }

    }

    getStatusLojas = () => {

        try {
            Requests
                .getStatusLojasNovoAracarejo()
                .then(response => {



                    this.setState({ listaLojasIsAberto: response })


                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {
            console.log(error)

        }

    }



    resumoPorStatus = (listaPedidos) => {

        if (listaPedidos.length > 0) {

            const quadroResumo = _(listaPedidos)
                .groupBy('status')
                .map((objs, key) => ({
                    'count': objs.length,
                    'status': key,
                    'precoTotal': _.sumBy(objs, 'precoTotal') + _.sumBy(objs, 'precoProdutos')
                }))
                .value()



            return quadroResumo

        }
    }


    quadroResumo = (listaPedidos) => {

        if (listaPedidos.length > 0) {

            const quadroResumo = _(listaPedidos)
                .groupBy('placeNomeExibicao')
                .map((objs, key) => ({
                    'count': objs.length,
                    'placeNomeExibicao': key,
                    'valorProduto': _.sumBy(objs, 'precoProdutos'),
                    'precoTotal': _.sumBy(objs, 'precoTotal'),
                    'taxServiceValue': _.sumBy(objs, 'taxServiceValue')
                }))
                .value()

            return quadroResumo
        }
    }

    quadroResumoAguardando_Aprovação = (listaPedidos) => {



        const listaFilterAguardandoAprovacao = _.filter(listaPedidos, { status: 'AGUARDANDO_APROVACAO' })

        if (listaFilterAguardandoAprovacao.length > 0) {

            const quadroResumo = _(listaFilterAguardandoAprovacao)
                .groupBy('placeNomeExibicao')
                .map((objs, key) => ({
                    'count': objs.length,
                    'placeNomeExibicao': key,
                    'valorProduto': _.sumBy(objs, 'precoProdutos'),
                    'precoTotal': _.sumBy(objs, 'precoTotal')
                }))
                .value()

            return quadroResumo
        } else {
            return []
        }
    }


    getAllMunicipios = async () => {

        const { user } = this.context



        if (user.initial) return window.location.href = `/`

        const acessoRoout = user.data.isRoot

        const listaMunicipioIds = user.data.municipioIds

        this.setState({ loadingSelectMunicipio: true })

        const listaMunicipios = []

        try {
            Requests
                .getAllMunicipios()
                .then(response => {

                    const lista = response.data

                    if (acessoRoout) {

                        for (let i = 0; i < lista.length; i++) {
                            const municipio = lista[i];

                            const obj = {
                                key: municipio.municipioId,
                                text: `${municipio.nome}/${municipio.uf}`,
                                value: municipio.municipioId,

                            }


                            listaMunicipios.push(obj)

                        }

                    } else {
                        for (let j = 0; j < listaMunicipioIds.length; j++) {
                            const municipioId = listaMunicipioIds[j];

                            const municipio = _.find(lista, { municipioId: municipioId })

                            if (municipio) {

                                const obj = {
                                    key: municipio.municipioId,
                                    text: `${municipio.nome}/${municipio.uf}`,
                                    value: municipio.municipioId,

                                }


                                listaMunicipios.push(obj)

                            }
                        }
                    }


                    this.setState({ municipios: listaMunicipios, loadingSelectMunicipio: false })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }
    }


    componentDidMount() {

        const cookiesUser = Cookies.get('user')

        const cookies = Cookies.get('qd-token')

        if (cookies === undefined) window.location.href = `/`


        this.userLogado()

        this.getAllVendasDias()

        this.getPedidosNovoAtacarejoHoje()

        this.getStatusLojas()

        //this.metaDia()



    }

    goBack() {

        this.props.history.push(`/plano`)

    }






    render() {



        const { listaPedidos, loading, listaLojasIsAberto, listaVendasDias, listaVendasDiaSemTratar } = this.state


        return (

            <div>

                <div style={{ margin: '20px 20px 20px 20px' }} >

                    <div>
                        <Form >

                            <Form.Group >

                                <Form.Field style={{ margin: '0px 0px 20px 0px' }} basic color='black' control={Button} onClick={() => this.goBack()} >Voltar</Form.Field>
                                <Form.Field style={{ margin: '0px 0px 20px 0px' }} basic color='black' control={Button} onClick={() => this.getPedidosNovoAtacarejoHoje()} >Atualizar</Form.Field>

                            </Form.Group>

                        </Form>


                    </div>

                    {
                        loading ? <Loader active={loading} size='big'>Loading</Loader> : <div>


                            {/* <div>
                                {
                                    listaPedidos.length > 0 ?
                                        <div style={{ textAlign: 'justify', marginTop: '15px' }}>


                                            <h3>Meta GMV Dia:  R$ {parseFloat(metaDia.gmv).toLocaleString('pt-BR', { style: 'decimal' })} </h3>

                                            <h3>GMV Realizado:  R$ {(_.sumBy(listaPedidos, 'precoTotal')).toLocaleString('pt-BR', { style: 'decimal' })} </h3>

                                            <h3>GMV Entregue: {(_.sumBy(_.filter(listaPedidos, { status: 'ENTREGUE' }), 'precoTotal')).toLocaleString('pt-BR', { style: 'decimal' })} </h3>

                                            <h3>GMV Cancelado: {(_.sumBy(_.filter(listaPedidos, { status: 'CANCELADO' }), 'precoTotal')).toLocaleString('pt-BR', { style: 'decimal' })} </h3>

                                            <h3>QTD Pedido Total: {listaPedidos.length} </h3>
                                            <h3>QTD Pedido Entregue: {_.filter(listaPedidos, { status: 'ENTREGUE' }).length} </h3>


                                            <h3>QTD Pedido Cancelado: {_.filter(listaPedidos, { status: 'CANCELADO' }).length} </h3>


                                            <h3>Ticket Médio Geral: R$ {((_.sumBy(listaPedidos, 'precoTotal')) / listaPedidos.length).toLocaleString('pt-BR', { style: 'decimal' })} </h3>


                                        </div>
                                        : ''

                                }
                            </div> */}

                            {/* <div style={{ textAlign: 'center', margin: '50px 20px 20px 20px' }}>

                                <h3 style={{ textAlign: 'center' }}>Atingimento Meta Do Dia 🚀  </h3>
                                <Progress percent={((parseFloat(_.sumBy(listaPedidos, 'precoTotal')) / parseFloat(metaDia.gmv)) * 100).toFixed(2)} size='medium' progress color='purple' active />

                            </div> */}


                            <div>
                                {
                                    this.quadroResumoAguardando_Aprovação(listaPedidos).length > 0 ? <h2 style={{ textAlign: 'center', margin: '20px 20px 20px 20px' }}>Pedidos Aguardando Aprovação</h2> : <h2 style={{ textAlign: 'center', margin: '100px 20px 100px 20px' }}>Sem Pedido Pendente</h2>
                                }

                                {
                                    this.quadroResumoAguardando_Aprovação(listaPedidos).length > 0 ? <Table elled selectable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Loja</Table.HeaderCell>
                                                <Table.HeaderCell>QTD Pedido</Table.HeaderCell>
                                                <Table.HeaderCell>GMV</Table.HeaderCell>

                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>


                                            {

                                                this.quadroResumoAguardando_Aprovação(listaPedidos).length > 0 ? _.orderBy(this.quadroResumoAguardando_Aprovação(listaPedidos)).map((novo1, index) =>
                                                    <Table.Row key={index}>
                                                        <Table.Cell><span> {novo1.placeNomeExibicao}</span> </Table.Cell>
                                                        <Table.Cell><span>{novo1.count}</span></Table.Cell>
                                                        <Table.Cell><span>R$  {numeral(novo1.precoTotal).format('0,0')}</span></Table.Cell>

                                                    </Table.Row>) : ''



                                            }

                                        </Table.Body>
                                    </Table> : ''
                                }

                            </div>



                            <div>

                                <h2 style={{ textAlign: 'center', margin: '20px 20px 20px 20px' }}>Pedidos Por Lojas - TPV</h2>
                                {
                                    listaPedidos.length > 0 ? <Table celled selectable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Lojas</Table.HeaderCell>
                                                <Table.HeaderCell>QTD Pedidos</Table.HeaderCell>
                                                <Table.HeaderCell>Valor Produto</Table.HeaderCell>
                                                <Table.HeaderCell>Taxa Entrega</Table.HeaderCell>
                                                <Table.HeaderCell>Total</Table.HeaderCell>
                                                <Table.HeaderCell>Ticket Médio</Table.HeaderCell>
                                                <Table.HeaderCell>Valor Taxa de Serviço</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {

                                                this.quadroResumo(listaPedidos).length > 0 ? _.orderBy(this.quadroResumo(listaPedidos), ['precoTotal'], ['desc']).map((novo, index) =>
                                                    <Table.Row key={index}>
                                                        <Table.Cell><span> {novo.placeNomeExibicao}</span> </Table.Cell>
                                                        <Table.Cell><span> {novo.count}</span></Table.Cell>
                                                        <Table.Cell><span>R$  {numeral(novo.valorProduto).format('0,0')}</span></Table.Cell>
                                                        <Table.Cell><span>R$  {numeral(novo.precoTotal - novo.valorProduto).format('0,0')}</span></Table.Cell>
                                                        <Table.Cell><span>R$  {numeral(novo.precoTotal).format('0,0')}</span></Table.Cell>
                                                        <Table.Cell><span>R$  {numeral(novo.precoTotal / novo.count).format('0,0')}</span></Table.Cell>
                                                        <Table.Cell><span>R$  {novo.taxServiceValue}</span></Table.Cell>

                                                    </Table.Row>) : ''



                                            }

                                        </Table.Body>
                                    </Table> : ''
                                }


                            </div>


                            <div>

                                <h2 style={{ textAlign: 'center', margin: '20px 20px 20px 20px' }}>Status Lojas Aberta/Fechada</h2>

                                {/* <h3>QTD Lojas Abertas:  {listaLojasIsAberto.length > 0 ? _(listaLojasIsAberto)
                                .groupBy('isAberto')
                                .map((objs, key) => ({
                                    'count': objs.length,
                                    'isAberto': key,

                                }))
                                .value()[0].count : 0}</h3>

                            <h3>QTD Lojas Fechadas:  {listaLojasIsAberto.length > 0 ? _(listaLojasIsAberto)
                                .groupBy('isAberto')
                                .map((objs, key) => ({
                                    'count': objs.length,
                                    'isAberto': key,

                                }))
                                .value()[1].count : 0}</h3> */}
                                {
                                    listaLojasIsAberto.length > 0 ? <Table celled selectable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Lojas</Table.HeaderCell>
                                                <Table.HeaderCell>Status</Table.HeaderCell>

                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {

                                                listaLojasIsAberto.length > 0 ? _.orderBy(listaLojasIsAberto, ['isAberto']).map((loja, index) =>
                                                    <Table.Row key={index}>
                                                        <Table.Cell><span> {loja.nomeExibicao}</span> </Table.Cell>
                                                        <Table.Cell><span> {loja.isAberto ? <span style={{ backgroundColor: 'green' }}> Aberta</span> : <span style={{ backgroundColor: 'red' }}>Fechada</span>}</span></Table.Cell>
                                                    </Table.Row>) : ''

                                            }

                                        </Table.Body>
                                    </Table> : ''
                                }


                            </div>





                        </div>



                    }

                    <div>
                        <h2 style={{ textAlign: 'center', margin: '20px 20px 20px 20px' }}>Vendas Por Dias - Pedidos Entregues</h2>
                        <div style={{ marginTop: '20px', height: '100%' }}>
                            <FullCalendar
                                height='auto'
                                plugins={[dayGridPlugin]}
                                initialView="dayGridMonth"
                                events={listaVendasDias}
                                headerToolbar={{
                                    left: 'title',
                                    right: ''
                                }}
                                locale='pt-br'
                                timezone='America/Bahia'

                            />
                        </div>

                    </div>



                </div>
            </div>
        )

    }




}

export default NovoAtacarejo;
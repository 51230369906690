import React, { Component } from 'react';


class Pedido extends Component {

    state = {
        listaPedido: []
    }



    render() {
        return (
            <div>
                <div className='box'>
                    <div style={{ width: '50%' }}>
                        <h1 style={{textAlign: 'center'}}>FAzer Pedido</h1>
                    </div>

                    <div style={{ width: '50%' }}>

                        <h1 style={{textAlign: 'center'}}>Historico</h1>

                    </div>

                </div>
            </div>
        )
    }
}


export default Pedido

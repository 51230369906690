import React, { Component } from 'react';


import Requests from "../shared/Requests";

import { Loader, Button, Table, Form, Select, Input } from "semantic-ui-react";


import Cookies from 'js-cookie'

import { validateCpf, validateCnpj } from "../helpers/FnUtil";

import { mask, unMask } from "remask";
import numeral from "numeral";



import bancos from "../baseJson/bancos.json";
import tipoDeContas from "../baseJson/tiposDeConta.json";
import { toast } from 'react-toastify';
import _ from "lodash";













class CadastroInfluencs extends Component {

    // static contextType = AuthContext

    constructor(props) {
        super(props);

        this.goBack = this.goBack.bind(this);



    }

    state = {
        municipios: [],
        listaInfluencers: [],
        municipioId: '',
        contaInstagram: '',
        seguidores: '',
        alcance: '',
        nome: '',
        cpf: '',
        banco: '',
        agencia: '',
        conta: '',
        tipoConta: '',
        loadingTable: false,
        loadingSelectMunicipio: false,
        telefone: '',
        listaBancos: []
    }


    getAllBanco = () => {

        const banks = bancos

        let listBanks = []

        for (let i = 0; i < banks.length; i++) {
            const bank = banks[i];

            const obj = {
                key: bank.key,
                text: `${bank.key} - ${bank.text}`,
                value: `${bank.key} - ${bank.text}`
            }

            listBanks.push(obj)

        }

        this.setState({ listaBancos: listBanks })

    }


    getAllMunicipios = async () => {

        const listaMunicipios = []



        try {
            Requests
                .getAllMunicipios()
                .then(response => {

                    const lista = response.data

                    for (let i = 0; i < lista.length; i++) {
                        const municipio = lista[i];
                        const obj = {
                            key: municipio.municipioId,
                            text: `${municipio.nome}/${municipio.uf}`,
                            value: municipio.municipioId,

                        }
                        listaMunicipios.push(obj)

                    }

                    this.setState({ municipios: listaMunicipios })

                })
                .catch(err => {
                    console.log(err)

                })


        } catch (error) {
            console.log(error)

        }
    }



    getAllInfluencerByMunicipio = (municipioId) => {

        this.setState({ loadingTable: true, loadingSelectMunicipio: true })
        try {

            Requests
                .getAllInfluencerByMunicipioId(municipioId)
                .then(response => {

                    if (response.r) {

                        const listaInfluencers = response.data

                        this.setState({ listaInfluencers: listaInfluencers, loadingTable: false, loadingSelectMunicipio: false })



                    }
                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {

            console.log(error)

        }



    }


    cadastrarInfluencer = async () => {

        const { municipioId, contaInstagram, seguidores, alcance, nome, cpf, banco, agencia, conta, tipoConta, telefone } = this.state


        const influencer = []


        const obj = {
            municipioId: municipioId,
            contaInstagram: contaInstagram,
            numeroSeguidores: parseInt(unMask(seguidores)),
            alcance: parseFloat(alcance),
            nome: nome === '' ? null : nome,
            cpf: cpf === '' ? null : cpf,
            banco: banco === '' ? null : banco,
            agencia: agencia === '' ? null : agencia,
            conta: conta === '' ? null : conta,
            tipoConta: tipoConta === '' ? null : tipoConta,
            telefone: telefone === '' ? null : telefone
        }



         if (alcance === '') return toast.warning('Informar alcance')

        if (cpf !== '') {
            if (validateCpf(cpf) === 'INVALIDO' && (validateCnpj(cpf) === 'INVALIDO')) return toast.warning('CPF Ou CNPJ Inválido.')


            if ((conta.indexOf('-') > -1) === false) return toast.warning('Conta Sem Digito Verificador')

        }





       


        const result = await Requests.getAllInfluencerByMunicipioId(municipioId)


        if (result.data.length > 0) {

            const listInfluencers = result.data

            const userInfluencer = _.find(listInfluencers, { contaInstagram: contaInstagram })

            if (userInfluencer) return toast.warning('Influencer já cadastrado.')

        }


        influencer.push(obj)

        try {
            Requests
                .createInfluencer(influencer)
                .then(response => {
                    if (response.r) {

                        this.getAllInfluencerByMunicipio(municipioId)

                        this.setState(
                            {
                                municipioId: '',
                                contaInstagram: '',
                                seguidores: '',
                                alcance: '',
                                nome: '',
                                cpf: '',
                                banco: '',
                                agencia: '',
                                conta: '',
                                tipoConta: '',
                                telefone: ''

                            })
                        return toast.success('Influencer Cadastrado')

                    }
                })
                .catch(err => {
                    console.log(err)

                })

        } catch (error) {
            console.log(error)

        }





    }




    goBack() {

        this.props.history.push(`/plano`)

    }






    componentDidMount() {

        const cookies = Cookies.get('qd-token')

        if (cookies === undefined) window.location.href = `/`

        this.getAllBanco()

        this.getAllMunicipios()


    }

    onChangeMaskCpf = (ev) => {

        const originalValue = unMask(ev.target.value)
        const maskedValue = mask(originalValue, ['999.999.999-99', '99.999.999/9999-99'])
        this.setState({ cpf: maskedValue })
    }

    onChangeMaskTelefone = (ev) => {

        const originalValue = unMask(ev.target.value)
        const maskedValue = mask(originalValue, ['(99) 99999-9999'])
        this.setState({ telefone: maskedValue })
    }

    onChangeMaskAgencia = (ev) => {

        const originalValue = unMask(ev.target.value)
        const maskedValue = mask(originalValue, ['9999-9'])
        this.setState({ agencia: maskedValue })

    }

    onChangeMaskConta = (ev) => {

        const originalValue = unMask(ev.target.value)
        const maskedValue = mask(originalValue, ['9999-9', '99999-9', '999999-9', '9999999-9', '99999999-9', '999999999-9', '9999999999-9'])
        this.setState({ conta: maskedValue })

    }

    onChangeMaskSeguidores = (ev) => {

        const originalValue = unMask(ev.target.value)
        const maskedValue = mask(originalValue, ['999', '9.999', '99.999', '999.9999'])
        this.setState({ seguidores: maskedValue })

    }

    onChangeMaskAlcance = (ev) => {

        const originalValue = unMask(ev.target.value)
        const maskedValue = mask(originalValue, ['99.99'])
        this.setState({ alcance: maskedValue })

    }

    onChangeMaskContaInstagram = (ev) => {

        const originalValue = ev.target.value

        const string = originalValue.padStart(2, '@')

        this.setState({ contaInstagram: string })
    }






    render() {

        const {
            municipios,
            municipioId,
            contaInstagram,
            seguidores,
            alcance,
            nome,
            cpf,
            banco,
            agencia,
            conta,
            tipoConta,
            listaInfluencers,
            loadingTable,
            loadingSelectMunicipio,
            telefone,
            listaBancos


        } = this.state




        return (

            <div style={{ margin: '20px 20px 20px 20px' }}>

                <div style={{ width: '100%' }}>
                    <Form.Field style={{ margin: '0px 0px 20px 0px' }} basic color='black' control={Button} onClick={() => this.goBack()} >Voltar</Form.Field>
                </div>

                <Form size='small'>

                    <Form.Group widths='equal'>

                        <Form.Field
                            // style={{ backgroundColor: 'plano6f42c1', color: 'white' }}

                            control={Select}
                            label='Cidade'
                            options={municipios}
                            loading={loadingSelectMunicipio}
                            search
                            placeholder='Cidade Do Influencer'
                            value={municipioId}
                            onChange={(event, data) => this.setState({ municipioId: data.value }, this.getAllInfluencerByMunicipio(data.value))}
                        />
                        <Form.Field

                            control={Input}
                            label='@Instagran'
                            //placeholder='Ex: Alavancar Vendas'    
                            value={contaInstagram}
                            onChange={this.onChangeMaskContaInstagram}
                        />

                        <Form.Field

                            control={Input}

                            label='Quantidade Seguidores'
                            //placeholder='Ex: Alavancar Vendas'    
                            value={seguidores}
                            onChange={this.onChangeMaskSeguidores}
                        />
                        <Form.Field
                            control={Input}
                            label='% Alcance Na Cidade'
                            value={alcance}
                            onChange={this.onChangeMaskAlcance}
                        />



                    </Form.Group>



                    <Form.Group widths='equal'>
                        <Form.Field

                            control={Input}
                            label='Nome'
                            value={nome}
                            onChange={(event, data) => this.setState({ nome: data.value })}
                        />
                        <Form.Input
                            disabled={false}
                            label='CPF/CNPJ'
                            placeholder="000.000.000-00 ou 00.000.000/0000-00"
                            value={cpf}
                            onChange={this.onChangeMaskCpf}


                        />

                        <Form.Input
                            disabled={false}
                            label='Telefone'
                            placeholder="(00) 00000-0000"
                            value={telefone}
                            onChange={this.onChangeMaskTelefone}


                        />


                        <Form.Field

                            control={Select}
                            label='Banco'
                            options={listaBancos}
                            search
                            value={banco}
                            onChange={(event, data) => this.setState({ banco: data.value })}
                        />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field

                            control={Input}
                            label='Agência'
                            value={agencia}
                            onChange={this.onChangeMaskAgencia}
                        />

                        <Form.Field

                            control={Input}
                            label='Conta - Se o Digito for X, use 0'
                            value={conta}
                            onChange={this.onChangeMaskConta}
                        />


                        <Form.Field

                            control={Select}
                            label='Tipo De Conta'
                            options={tipoDeContas}
                            search
                            value={tipoConta}
                            onChange={(event, data) => this.setState({ tipoConta: data.value })}
                        />

                        <Form.Field positive label='.' control={Button} onClick={() => this.cadastrarInfluencer()}>Cadastrar</Form.Field>

                    </Form.Group>

                </Form>

                <div style={{ width: '100%', height: '500px', overflowY: 'scroll' }}>

                    <Loader active={loadingTable} size='big'>Loading</Loader>

                    {
                        listaInfluencers.length > 0 ? <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>id</Table.HeaderCell>
                                    <Table.HeaderCell>@Instagran</Table.HeaderCell>
                                    <Table.HeaderCell>QTD Seguidores</Table.HeaderCell>
                                    <Table.HeaderCell>Alcance</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body >
                                {


                                    listaInfluencers.length > 0 ? listaInfluencers.map((influencer, index) =>
                                        <Table.Row key={index}>
                                            <Table.Cell>{influencer.influencerId}</Table.Cell>
                                            <Table.Cell>{influencer.contaInstagram}</Table.Cell>
                                            <Table.Cell>{numeral(influencer.numeroSeguidores).format('0,0')}</Table.Cell>
                                            <Table.Cell>{`${influencer.alcance} %`}</Table.Cell>
                                        </Table.Row>

                                    ) : ''
                                }


                            </Table.Body>
                        </Table> : ''
                    }

                </div>

            </div>
        )
    }
}


export default CadastroInfluencs
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css';
import { ToastContainer } from 'react-toastify';


ReactDOM.render(


  <div>
    <ToastContainer />

    <App />

  </div>,
  document.getElementById('root')
);


reportWebVitals();

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Loader, Button, Table, Form, Select, Input, Modal, TextArea, Icon, Progress, Dimmer, Segment, Accordion } from "semantic-ui-react";
import numeral from "numeral";
import Cookies from 'js-cookie'
import { toast } from 'react-toastify';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import moment from "moment";
import Requests, { getAllAcoes } from '../shared/Requests'
import _, { findLastKey, forIn } from "lodash";
import AuthContext from "../context/authContext"
import ModalVoucher from "./formVaucher";




class ViewTimeInterno extends Component {

    static contextType = AuthContext

    constructor(props) {
        super(props);
    }

    state = { listaPlanoAcoes: [] }





  

    componentDidMount() {

        this.getAllPlanoAcaoByConsultorId()

    }



    render() {

        const { listaPlanoAcoes } = this.state
        return (<div>
            {
                listaPlanoAcoes.length > 0 ? <Table celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.HeaderCell>Criado Por ?</Table.HeaderCell>
                            <Table.HeaderCell>Execução</Table.HeaderCell>
                            <Table.HeaderCell>Ação</Table.HeaderCell>
                            {/* <Table.HeaderCell>Finalidade</Table.HeaderCell> */}
                            <Table.HeaderCell>Previsto</Table.HeaderCell>
                            <Table.HeaderCell>Realizado</Table.HeaderCell>
                            {/* <Table.HeaderCell>Data Criaçao</Table.HeaderCell> */}
                            <Table.HeaderCell>Inicio</Table.HeaderCell>
                            <Table.HeaderCell>Fim</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Ação</Table.HeaderCell>
                            {/* {userAuthCriacao === true ? <Table.HeaderCell>Duplicar</Table.HeaderCell> : ''} */}

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            listaPlanoAcoes.length > 0 ? _.sortBy(listaPlanoAcoes, ['status', 'prazoInicio']).map((acao, index) =>

                                <Table.Row key={index}>
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.acaoModal(acao)}>{acao.planoAcaoId}</Table.Cell>
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.acaoModal(acao)}>{acao.nomeCriador.toUpperCase()} - ({acao.timeCriador})</Table.Cell>
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.acaoModal(acao)}>{acao.nomeExecutor.toUpperCase()} - ({acao.timeExecutor})</Table.Cell>
                                    <Table.Cell style={{ fontSize: '14px' }} onClick={() => this.acaoModal(acao)}>{acao.acaoTipo}</Table.Cell>
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.acaoModal(acao)}>R$ {numeral(acao.quantoCusta).format('0,0')}</Table.Cell>
                                    <Table.Cell
                                        onClick={() => this.updateValorRealizado(acao.planoAcaoId, acao)}
                                        style={{ fontSize: '12px' }}>R$ {numeral(acao.valorRealizado).format('0,0')}



                                    </Table.Cell>
                                    {/* <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.acaoModal(acao)}>{moment(acao.createdAt).format('L')}</Table.Cell> */}
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.acaoModal(acao)}>{moment(acao.prazoInicio).format('DD/MM/YYYY')}</Table.Cell>
                                    <Table.Cell style={{ fontSize: '12px' }} onClick={() => this.acaoModal(acao)}>{moment(acao.prazoFim).format('DD/MM/YYYY')}</Table.Cell>
                                    <Table.Cell >
                                        {
                                            acao.status === 'NAO_INICIADA' ?
                                                <div>
                                                    <span style={{ backgroundColor: '#DCDCDC', fontSize: '12px' }}>{acao.status} </span>

                                                </div>
                                                : acao.status === 'CONCLUIDO' ? <span style={{ backgroundColor: '#008000', fontSize: '12px' }}>{acao.status}</span>
                                                    : acao.status === 'CANCELADO' ? <span style={{ backgroundColor: '#778899', fontSize: '12px' }}>{acao.status}</span> : acao.status === 'EM_ANDAMENTO' ?
                                                        <div >
                                                            <span style={{ backgroundColor: '#FFFF00', fontSize: '12px' }}>{acao.status}</span>


                                                        </div> : acao.status === 'ATRASADO' ?
                                                            <div>
                                                                <span style={{ backgroundColor: '#FF0000', fontSize: '12px' }}>{acao.status}</span>


                                                            </div> : <span>{acao.status}</span>
                                        }</Table.Cell>
                                    <Table.Cell >
                                        {
                                            acao.status === 'NAO_INICIADA' ?
                                                <div>
                                                    <span style={{ backgroundColor: '#DCDCDC', fontSize: '10px' }}> </span>
                                                    <Button size='mini' color='green' onClick={() => this.updateStatusPlanoAcao(acao.planoAcaoId, 'EM_ANDAMENTO', acao.municipioId, acao.comentario, acao)}><Icon name='play' /></Button>

                                                    <Button size='mini' color='grey' onClick={() => this.setState({ openModalMotivoCancelamento: true, acaoIdMotivoCancelamento: acao.planoAcaoId })}><Icon name='cancel' /></Button>


                                                </div>
                                                : acao.status === 'CONCLUIDO' ? <span style={{ backgroundColor: '#008000', fontSize: '10px' }}></span>
                                                    : acao.status === 'CANCELADO' ? <span style={{ backgroundColor: '#778899', fontSize: '10px' }}></span> : acao.status === 'EM_ANDAMENTO' ?
                                                        <div >
                                                            <span style={{ backgroundColor: '#FFFF00', fontSize: '10px' }}></span>
                                                            <Button size='mini' color='blue' onClick={() => this.updateStatusPlanoAcao(acao.planoAcaoId, 'CONCLUIDO', acao.municipioId, acao.comentario, acao)}><Icon name='check' /></Button>
                                                            <Button size='mini' color='grey' onClick={() => this.setState({ openModalMotivoCancelamento: true, acaoIdMotivoCancelamento: acao.planoAcaoId })}><Icon name='cancel' /></Button>

                                                        </div> : acao.status === 'ATRASADO' ?
                                                            <div>
                                                                <span style={{ backgroundColor: '#FF0000' }}></span>
                                                                <Button size='mini' color='blue' onClick={() => this.updateStatusPlanoAcao(acao.planoAcaoId, 'CONCLUIDO', acao.municipioId, acao.comentario, acao)}><Icon name='check' /></Button>
                                                                <Button size='mini' color='grey' onClick={() => this.setState({ openModalMotivoCancelamento: true, acaoIdMotivoCancelamento: acao.planoAcaoId })}><Icon name='cancel' /></Button>


                                                            </div> : <span>{acao.status}</span>
                                        }</Table.Cell>

                                    {/* {userAuthCriacao === true ? <Table.Cell>
                                        <Button size='mini' color='#DCDCDC' onClick={() => this.duplicarAcao(acao)}><Icon name='copy' /></Button>
                                    </Table.Cell> : ''} */}


                                </Table.Row>




                            ) : ''
                        }


                    </Table.Body>
                </Table> : <h3 style={{ textAlign: 'center' }}>Cidade Sem Plano de Ação</h3>
            }


        </div>)
    }

}

export default ViewTimeInterno


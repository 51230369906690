import React, { Component } from 'react'

const AuthContext = React.createContext()

export class AuthProvider extends React.Component {

    state = {
        user: { initial: true },
        useValorAcaoControlado: 0,
        useNovoVaucer: false
    }

    setValorAcaoControlado = (useValorAcaoControlado) => {

        this.setState((prevState) => ({ useValorAcaoControlado }))

    }

    setNovoVaucer = (useNovoVaucer) => {

        this.setState((prevState) => ({ useNovoVaucer }))

    }

    setUser = (user) => {
        this.setState((prevState) => ({ user }))
    }

    render() {

        const { children } = this.props
        const { user } = this.state
        const { useValorAcaoControlado } = this.state
        const { useNovoVaucer } = this.state

        const { setUser } = this
        const { setValorAcaoControlado } = this
        const { setNovoVaucer } = this


        return (
            <AuthContext.Provider value={{ user, setUser, useValorAcaoControlado, setValorAcaoControlado, useNovoVaucer, setNovoVaucer }}>
                {this.props.children}

            </AuthContext.Provider>
        )
    }

}


export default AuthContext
